import { useState } from 'react';
// @mui
import Avatar from '@mui/material/Avatar';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import Chip from '@mui/material/Chip';
import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import TableBody from '@mui/material/TableBody';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
// hooks
// components
import Iconify from 'src/components/iconify';
import { formatDuration, intervalToDuration } from 'date-fns';
// types
//
import { useLocales } from '../../locales';
import { fDateTime } from '../../utils/format-time';
import { SalarySettlement } from '../../types/salary-settlement';

// ----------------------------------------------------------------------

type Props = {
  row: SalarySettlement;
  selected: boolean;
  onSelectRow: VoidFunction;
};

export default function SalarySettlementItemTableRow({ row, selected, onSelectRow }: Props) {
  const {
    employeeName,
    employeeProfileImageUrl,
    employeeWageRateId,
    isApproved,
    shifts,
    totalHours,
    clazzHours,
    personalTrainingHours,
    unspecifiedHours,
  } = row;

  const { t } = useLocales();

  const [open, setOpen] = useState(false);

  const sorted = shifts.sort((a, b) => a.begins!.toMillis() - b.begins!.toMillis());

  return (
    <>
      <TableRow hover selected={selected} sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell padding="checkbox">
          <Checkbox checked={selected} onClick={onSelectRow} />
        </TableCell>

        <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Avatar alt={employeeName} src={employeeProfileImageUrl} />

            <Typography variant="subtitle2" noWrap>
              {employeeName}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell>
          {(clazzHours.hours > 0 || clazzHours.minutes > 0 || clazzHours.seconds > 0) &&
            formatDuration(clazzHours)}

          {clazzHours.hours <= 0 && clazzHours.minutes <= 0 && clazzHours.seconds <= 0 && '-'}
        </TableCell>
        <TableCell>
          {(personalTrainingHours.hours > 0 ||
            personalTrainingHours.minutes > 0 ||
            personalTrainingHours.seconds > 0) &&
            formatDuration(personalTrainingHours)}
          {personalTrainingHours.hours <= 0 &&
            personalTrainingHours.minutes <= 0 &&
            personalTrainingHours.seconds <= 0 &&
            '-'}
        </TableCell>
        <TableCell>
          {(unspecifiedHours.hours > 0 ||
            unspecifiedHours.minutes > 0 ||
            unspecifiedHours.seconds > 0) &&
            formatDuration(unspecifiedHours)}
          {unspecifiedHours.hours <= 0 &&
            unspecifiedHours.minutes <= 0 &&
            unspecifiedHours.seconds <= 0 &&
            '-'}
        </TableCell>
        <TableCell>
          {(totalHours.hours > 0 || totalHours.minutes > 0 || totalHours.seconds > 0) &&
            formatDuration(totalHours)}
          {totalHours.hours <= 0 && totalHours.minutes <= 0 && totalHours.seconds <= 0 && '-'}
        </TableCell>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <Iconify icon="mdi:chevron-up" /> : <Iconify icon="mdi:chevron-down" />}
          </IconButton>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                {t('History')}
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>{t('Class')}</TableCell>
                    <TableCell>{t('Time')}</TableCell>
                    <TableCell>{t('Type')}</TableCell>
                    <TableCell align="right">{t('Status')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sorted.map((s) => (
                    <TableRow key={s.id}>
                      <TableCell align="left">
                        <ListItemText
                          primary={s.description}
                          secondary={fDateTime(s.begins)}
                          primaryTypographyProps={{
                            noWrap: true,
                            typography: 'subtitle2',
                            mb: 0.5,
                          }}
                          secondaryTypographyProps={{
                            noWrap: true,
                            typography: 'caption',
                            component: 'span',
                          }}
                        />
                      </TableCell>
                      <TableCell sx={{ textTransform: 'capitalize' }}>
                        {formatDuration(
                          intervalToDuration({
                            start: s.begins!.toJSDate(),
                            end: s.ends!.toJSDate(),
                          })
                        )}
                      </TableCell>
                      <TableCell sx={{ textTransform: 'capitalize' }}>{t(s.type ?? '')}</TableCell>
                      <TableCell align="right">
                        {isApproved && (
                          <Chip
                            color="success"
                            size="small"
                            variant="outlined"
                            label={t('Approved')}
                          />
                        )}
                        {isApproved !== true && (
                          <Chip
                            color="warning"
                            size="small"
                            variant="outlined"
                            label={t('Not approved')}
                          />
                        )}
                        {employeeWageRateId === undefined && (
                          <Chip
                            color="error"
                            size="small"
                            label={t('Missing wage rate')}
                            sx={{
                              ml: 1,
                            }}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
