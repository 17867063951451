// @mui
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
// config
import { MAPBOX_API } from 'src/config-global';
// components
import Iconify from 'src/components/iconify';
// types
import { GymDto, PlaceDto } from '../../api';
import GymMapMarkersPopups from './gym-map-markers-popups';

// ----------------------------------------------------------------------

type Props = {
  gym: GymDto;
  places: PlaceDto[];
};

export default function GymDetailsContent({ gym, places }: Props) {
  const { name, address } = gym;

  const StyledMapContainer = styled('div')(({ theme }) => ({
    zIndex: 0,
    height: 560,
    overflow: 'hidden',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    '& .mapboxgl-ctrl-logo, .mapboxgl-ctrl-bottom-right': {
      display: 'none',
    },
  }));

  const baseSettings = {
    mapboxAccessToken: MAPBOX_API,
    minZoom: 1,
  };

  const renderHead = (
    <>
      <Stack direction="row" sx={{ mb: 3 }}>
        <Typography variant="h4" sx={{ flexGrow: 1 }}>
          {name}
        </Typography>
      </Stack>

      <Stack spacing={3} direction="row" flexWrap="wrap" alignItems="center">
        <Stack direction="row" alignItems="center" spacing={0.5} sx={{ typography: 'body2' }}>
          <Iconify icon="mingcute:location-fill" sx={{ color: 'error.main' }} />
          {`${address?.addressLine}, ${address?.postalNumber} ${address?.city}, ${address?.country}`}
        </Stack>
      </Stack>
    </>
  );

  const renderMap =
    address != null ? (
      <StyledMapContainer>
        <GymMapMarkersPopups
          {...baseSettings}
          data={[address!]}
          places={places}
          mapStyle="mapbox://styles/mapbox/light-v10"
        />
      </StyledMapContainer>
    ) : null;

  return (
    <Stack>
      {renderHead}

      <Divider sx={{ borderStyle: 'dashed', my: 5 }} />

      {renderMap}
    </Stack>
  );
}
