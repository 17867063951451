import { useEffect } from 'react';
// @mui
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import { alpha, useTheme } from '@mui/material/styles';
import ListItemText from '@mui/material/ListItemText';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
//
import { AccountDto, MembershipDto } from '../../api';
import { useLocales } from '../../locales';
import { useDispatch, useSelector } from '../../redux/store';
import { getPersonalTrainers } from '../../redux/slices/members';
import { AvatarShape } from '../../assets/illustrations';
import Image from '../../components/image';
import Iconify from '../../components/iconify';
import AddPersonalTrainerForm from './personal/add-personal-trainer-form';

// ----------------------------------------------------------------------

type Props = {
  member: AccountDto;
  membership: MembershipDto;
  coverUrl: string;
};

export default function PersonalTrainer({ member, coverUrl, membership }: Props) {
  const { t } = useLocales();

  const addPersonalTrainer = useBoolean();

  const trainers = useSelector((state) => state.member.currentMemberPersonalTrainers);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPersonalTrainers(member.id!));
  }, [dispatch, member]);

  const theme = useTheme();

  const hasPersonalTrainer = trainers.length > 0;

  const personalTrainer = trainers.length > 0 ? trainers[0] : null;

  const handleAddPersonalTrainer = () => {
    dispatch(getPersonalTrainers(member.id!));
  };

  return (
    <>
      <Card sx={{ textAlign: 'center' }}>
        <Box sx={{ position: 'relative' }}>
          <AvatarShape
            sx={{
              left: 0,
              right: 0,
              zIndex: 10,
              mx: 'auto',
              bottom: -26,
              position: 'absolute',
            }}
          />

          {hasPersonalTrainer && (
            <Avatar
              alt={personalTrainer?.name}
              src={personalTrainer?.profileImageUrl}
              onClick={addPersonalTrainer.onTrue}
              sx={{
                width: 64,
                height: 64,
                zIndex: 11,
                left: 0,
                right: 0,
                bottom: -32,
                mx: 'auto',
                position: 'absolute',
              }}
            />
          )}

          {!hasPersonalTrainer && (
            <IconButton
              size="small"
              color="primary"
              onClick={addPersonalTrainer.onTrue}
              sx={{
                width: 64,
                height: 64,
                zIndex: 11,
                left: 0,
                right: 0,
                bottom: -32,
                mx: 'auto',
                position: 'absolute',
                bgcolor: 'primary.main',
                color: 'primary.contrastText',
                '&:hover': {
                  bgcolor: 'primary.dark',
                },
              }}
            >
              <Iconify icon="mingcute:add-line" />
            </IconButton>
          )}
          <Image
            src={personalTrainer?.profileImageUrl ?? coverUrl}
            alt={personalTrainer?.name ?? ''}
            ratio="16/9"
            overlay={alpha(theme.palette.grey[900], 0.48)}
          />
        </Box>

        <ListItemText
          sx={{ mt: 7, mb: 1 }}
          primary={personalTrainer?.name ?? t('Add personal trainer')}
          secondary={
            hasPersonalTrainer
              ? t('Personal trainer')
              : t("Member doesn't have a personal trainer yet")
          }
          primaryTypographyProps={{ typography: 'subtitle1' }}
          secondaryTypographyProps={{ component: 'span', mt: 0.5 }}
        />

        <Divider sx={{ borderStyle: 'dashed' }} />
      </Card>

      <AddPersonalTrainerForm
        personalTrainer={personalTrainer}
        open={addPersonalTrainer.value}
        onClose={addPersonalTrainer.onFalse}
        onCreate={handleAddPersonalTrainer}
        member={member}
        membership={membership}
      />
    </>
  );
}
