import Calendar from '@fullcalendar/react'; // => request placed at the top
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import timelinePlugin from '@fullcalendar/timeline';
import { setError } from 'src/redux/slices/error';

//
import { useEffect, useMemo } from 'react';
// @mui
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// components
import { useSettingsContext } from 'src/components/settings';
import { DateTime } from 'luxon';
import { useSnackbar } from 'notistack';
// types
import { ICalendarDate, ICalendarEvent } from 'src/types/calendar';
//
import { EventContentArg } from '@fullcalendar/core';
import { StyledCalendar } from '../styles';
import CalendarToolbar from '../calendar-toolbar';
import { useLocales } from '../../../locales';
import { paths } from '../../../routes/paths';
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
import { useDispatch, useSelector } from '../../../redux/store';
import { useCalendar, useEvent } from '../../calendar/hooks';
import { getPrograms } from '../../../redux/slices/program';
import ProgramNewEditFormDialog from '../program-new-edit-form-dialog';
import { getExercises } from '../../../redux/slices/exercise';
import { getWorkoutTypes } from '../../../redux/slices/program-template';
import { ClazzDto, ProgramDto, ProgramsService, WorkoutDto } from '../../../api';
import { getClasses } from '../../../redux/slices/classes';

// ----------------------------------------------------------------------

export default function ProgramListView() {
  const settings = useSettingsContext();

  const { t } = useLocales();

  const smUp = useResponsive('up', 'sm');

  const dispatch = useDispatch();

  const programs = useSelector((state) => state.program.scheduledPrograms);

  const classes = useSelector((state) => state.clazz.classes);

  const workoutTypes = useSelector((state) => state.programTemplate.workoutTypes);

  const exercises = useSelector((state) => state.exercise.exercises);

  const eventsLoading = useSelector((state) => state.program.isLoading);

  const events = useMemo(
    () =>
      programs
        .map(
          (x) =>
            ({
              id: `${x.id}`,
              color: x.classes?.length ? x.classes![0].tint : '',
              title: [...new Set(x.classes?.map((y) => y.title ?? '') ?? [])].join(',\n'),
              allDay: true,
              description: x.workouts?.map((z, i) => `${i}. ${z.title}`).join('\n') ?? '',
              extendedProps: {
                classes: x.classes ?? [],
                workouts: x.workouts ?? [],
              },
              end: `${x.date}`,
              start: `${x.date}`,
            } as ICalendarEvent)
        )
        .map((e) => ({
          ...e,
          textColor: e.color,
        })),
    [programs]
  );

  const storedDate = useMemo(() => {
    const queryParams = new URLSearchParams(window.location.search);
    return queryParams.get('date') ? decodeURIComponent(queryParams.get('date')!) : null;
  }, []);

  const {
    calendarRef,
    //
    view,
    date,
    startDate,
    endDate,
    //
    openForm,
    onCloseForm,
    //
    selectEventId,
    selectedRange,
    //
    onDatePrev,
    onDateNext,
    onDateToday,
    onDropEvent,
    onChangeView,
    onClickEvent,
    onSelectRange,
  } = useCalendar({
    defaultDate:
      storedDate !== null
        ? DateTime.fromISO(storedDate, {
            zone: 'local',
          })
        : undefined,
    defaultView: 'dayGridWeek',
  });

  const currentEvent = useEvent(events, selectEventId, selectedRange, openForm);

  useEffect(() => {
    dispatch(
      getPrograms({
        from: startDate,
        to: endDate,
      })
    );
    dispatch(
      getClasses({
        from: startDate,
        to: endDate,
        pageNumber: 0,
        pageSize: 2 ** 31 - 1,
      })
    );
  }, [dispatch, startDate, endDate]);

  useEffect(() => {
    dispatch(getExercises());
    dispatch(getWorkoutTypes());
  }, [dispatch]);

  useEffect(() => {
    const newurl =
      `${window.location.protocol}//${window.location.host}${window.location.pathname}` +
      `?date=${date ? encodeURIComponent(date.toISO({ includeOffset: true })!) : ''}`;
    window.history.replaceState({ path: newurl }, '', newurl);
  }, [date]);

  const onCreate = () => {
    dispatch(
      getPrograms({
        from: startDate,
        to: endDate,
      })
    );
  };

  const { currentLang } = useLocales();

  const { enqueueSnackbar } = useSnackbar();

  const getProgramFromEvent = (event: ICalendarEvent | Partial<ICalendarEvent> | undefined) =>
    programs.find((x) => x.id!.toString() === event?.id);

  const updateEvent = (eventData: Partial<ICalendarEvent>) => {
    const movedProgram = getProgramFromEvent(eventData);

    const handleSubmit = async (currentTemplate: ProgramDto) => {
      try {
        const newDate = DateTime.fromJSDate(new Date(eventData.start as ICalendarDate));
        // Filter classes on description as it is assumed that the moved program should be valid for
        // the classes of same description.
        const descriptions = [
          ...new Set(currentTemplate.classes?.map((x: ClazzDto) => x.description?.id ?? -1)),
        ];

        const classIds = classes
          .filter(
            (x) =>
              newDate.startOf('day') <= x.begins! &&
              x.begins! < newDate.endOf('day') &&
              descriptions.filter((y) => y === x.description?.id).length > 0
          )
          .map((x) => x.id!);

        await ProgramsService.update({
          programId: currentTemplate!.id!,
          body: {
            id: currentTemplate!.id!,
            ...currentTemplate,
            classIds,
            date: newDate,
          } as any,
        });

        enqueueSnackbar(t('Update success!'));
        dispatch(
          getPrograms({
            from: startDate,
            to: endDate,
          })
        );
      } catch (error) {
        dispatch(setError(error));
      }
    };

    handleSubmit(movedProgram!);
  };

  const renderEventContent = (arg: EventContentArg) => {
    const p = arg.event.extendedProps as any;
    const titles = p.classes
      .map((x: ClazzDto) => `${x.title ?? x.description?.name} - ${x.begins?.toFormat('HH:mm')}`)
      .sort();
    const workoutTitles = (p.workouts as WorkoutDto[])
      .map((x: WorkoutDto) => x.title ?? '')
      .filter((x) => x);
    return {
      html: `
                    <div class="fc-event-main-frame" style="flex-direction: column">
                    <div class="fc-event-time" style="max-height: 100%; overflow: hidden; text-overflow: ellipsis">${titles.join(
                      ',<br/>'
                    )}</div>
                    <div class="fc-event-title-container">
                        <div class="fc-event-title fc-sticky">
                            <h3 style="margin-bottom: 4px">${t('Workouts')}</h3>
                            <ol style="padding-left: 6px; margin-top: 0">
                                ${workoutTitles
                                  .map(
                                    (x: string) =>
                                      `<li style="list-style-position:inside; white-space: nowrap;  overflow: hidden;  text-overflow: ellipsis;">${x}</li>`
                                  )
                                  .join('')}
                            </ol>
                        </div>
                    </div>
                </div>`,
    };
  };

  return (
    <>
      <Container maxWidth={settings.themeStretch ? false : 'xl'}>
        <CustomBreadcrumbs
          heading={t('Scheduled programs')}
          links={[
            { name: t('Dashboard'), href: paths.dashboard.root },
            {
              name: t('Program'),
              href: paths.programs.root,
            },
            { name: t('Calendar') },
          ]}
          sx={{ mb: { xs: 1, md: 3 } }}
        />
        <Typography variant="body2" sx={{ mb: { xs: 1, md: 3 } }}>
          {t(
            'Here you can move around programs by dragging them to the next day, and you can edit existing programs by selecting them, or create new programs by clicking on a empty area on a given date.'
          )}
        </Typography>
        <Card>
          <StyledCalendar>
            <CalendarToolbar
              date={date.toJSDate()}
              view={view}
              loading={eventsLoading}
              onNextDate={onDateNext}
              onPrevDate={onDatePrev}
              onToday={onDateToday}
              onChangeView={onChangeView}
            />

            <Calendar
              weekends
              editable
              droppable
              selectable
              rerenderDelay={10}
              allDayMaintainDuration
              eventResizableFromStart
              ref={calendarRef}
              initialDate={date.toJSDate()}
              initialView={view}
              dayMaxEventRows={100}
              eventDisplay="block"
              events={events}
              locale={currentLang.value}
              headerToolbar={false}
              select={onSelectRange}
              eventClick={onClickEvent}
              eventContent={renderEventContent}
              height={smUp ? 720 : 'auto'}
              eventDrop={(arg) => {
                onDropEvent(arg, updateEvent);
              }}
              plugins={[
                listPlugin,
                dayGridPlugin,
                timelinePlugin,
                timeGridPlugin,
                interactionPlugin,
              ]}
            />
          </StyledCalendar>
        </Card>
      </Container>

      <ProgramNewEditFormDialog
        currentEvent={currentEvent}
        currentTemplate={getProgramFromEvent(currentEvent)}
        classes={classes}
        open={openForm}
        onClose={onCloseForm}
        onCreate={onCreate}
        workoutTypes={workoutTypes}
        exercises={exercises}
      />
    </>
  );
}
