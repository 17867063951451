import * as Yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo } from 'react';
import { setError } from 'src/redux/slices/error';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import InputAdornment from '@mui/material/InputAdornment';
// types
// assets
// components
import FormProvider, { RHFSwitch, RHFTextField } from 'src/components/hook-form';
import { useLocales } from '../../../locales';
import { useDispatch, useSelector } from '../../../redux/store';
import { fCurrencySymbol } from '../../../utils/format-number';
import {
  CampaignService,
  ContingentService,
  DropInsService,
  MembershipDto,
  MembershipStatuses,
  MembershipUpdateTypes,
  PersonalTrainingService,
  VouchersService,
} from '../../../api';
import { getMemberships } from '../../../redux/slices/members';

// ----------------------------------------------------------------------

type Props = {
  open: boolean;
  onClose: VoidFunction;
  onComplete: VoidFunction;
  membership?: MembershipDto;
};

export default function MembershipEditForm({ open, onClose, onComplete, membership }: Props) {
  const { t } = useLocales();

  const settings = useSelector((state) => state.chain.currentSettings);

  const newSchema = Yup.object().shape({
    membershipId: Yup.lazy((value) => (value === '' ? Yup.string().default('') : Yup.number())),
    membershipTypeId: Yup.lazy((value) => (value === '' ? Yup.string().default('') : Yup.number())),
    validFrom: Yup.date().required(t('Valid from date is required')),
    validTo: Yup.date().nullable(),
    onHoldPeriodEnds: Yup.date().nullable(),
    renewalDate: Yup.date().nullable(),
    discountAmount: Yup.lazy((value) => (value === '' ? Yup.string().default('') : Yup.number())),
    discountPercentage: Yup.lazy((value) =>
      value === '' ? Yup.string().default('') : Yup.number()
    ),
    numberOfClips: Yup.lazy((value) => (value === '' ? Yup.string().default('') : Yup.number())),
    priceAmount: Yup.number().nullable(),
  });

  const defaultValues = useMemo(
    () =>
      ({
        membershipId: membership?.id || '',
        membershipTypeId: membership?.membershipTypeId || '',
        validFrom: membership?.validFrom,
        validTo: membership?.validTo || null,
        onHoldPeriodEnds: membership?.onHoldUntil || null,
        renewalDate: membership?.renewalDate || null,
        discountAmount: membership?.discountAmount || '',
        discountPercentage: membership?.discountPercentage || '',
        discountCurrency: settings?.defaultCurrency || 'EUR',
        numberOfClips: membership?.numberOfClips || '',
        hasAutomaticRenewal: membership?.hasAutomaticRenewal ?? false,
        priceAmount: membership?.priceWithoutDiscountAmount || null,
        priceCurrency: settings?.defaultCurrency || 'EUR',
      } as any),
    [settings, membership]
  );

  const methods = useForm({
    resolver: yupResolver(newSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    control,
    reset,
  } = methods;

  const isDropIn = membership?.typeOfMembership === 'DropIn';
  const isPunchCard = membership?.typeOfMembership === 'Voucher';
  const isContingent = membership?.typeOfMembership === 'Contingent';
  const isCampaign = membership?.typeOfMembership === 'Campaign';
  const isPersonalTraining = membership?.typeOfMembership === 'PersonalTrainingMembership';
  const dispatch = useDispatch();

  const onSubmit = handleSubmit(async (data) => {
    try {
      if (isContingent) {
        await ContingentService.update({
          id: membership!.id!,
          body: {
            ...data,
            id: membership!.id!,
            type: MembershipUpdateTypes.Edit,
          } as any,
        });
      } else if (isCampaign) {
        await CampaignService.update({
          id: membership!.id!,
          body: {
            ...data,
            id: membership!.id!,
            type: MembershipUpdateTypes.Edit,
          } as any,
        });
      } else if (isDropIn) {
        await DropInsService.update({
          id: membership!.id!,
          body: {
            ...data,
            id: membership!.id!,
            type: MembershipUpdateTypes.Edit,
          } as any,
        });
      } else if (isPunchCard) {
        await VouchersService.update({
          id: membership!.id!,
          body: {
            ...data,
            id: membership!.id!,
            type: MembershipUpdateTypes.Edit,
          } as any,
        });
      } else if (isPersonalTraining) {
        await PersonalTrainingService.update({
          id: membership!.id!,
          body: {
            ...data,
            id: membership!.id!,
            type: MembershipUpdateTypes.Edit,
          } as any,
        });
      }
      reset();
      onComplete();
      onClose();

      dispatch(
        getMemberships({
          id: membership!.ownerId!,
          statuses: [
            MembershipStatuses.Active,
            MembershipStatuses.InActive,
            MembershipStatuses.OnHold,
            MembershipStatuses.Cancelled,
            MembershipStatuses.MissingPayment,
          ],
        })
      );
    } catch (error) {
      dispatch(setError(error));
    }
  });

  useEffect(() => {
    if (membership) {
      reset(defaultValues);
    }
  }, [membership, reset, defaultValues]);

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle>{t('Edit membership')}</DialogTitle>
        <DialogContent dividers>
          <Typography
            variant="body2"
            sx={{
              mb: 3,
            }}
          >
            {t(
              'Here you can edit the different parameters of the membership, but do it at your own risk.'
            )}
          </Typography>

          <Stack spacing={3}>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >
              <Stack spacing={1.5}>
                <Typography variant="subtitle2">{t('Valid from')}</Typography>
                <Controller
                  name="validFrom"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <DatePicker
                      {...field}
                      value={field.value}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!error,
                          helperText: error?.message,
                        },
                      }}
                    />
                  )}
                />
              </Stack>
              <Stack spacing={1.5}>
                <Typography variant="subtitle2">{t('Valid until')}</Typography>
                <Controller
                  name="validTo"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <DatePicker
                      {...field}
                      value={field.value}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!error,
                          helperText: error?.message,
                        },
                      }}
                    />
                  )}
                />
              </Stack>
              {!(isPunchCard || isPersonalTraining) && (
                <Stack spacing={1.5}>
                  <Typography variant="subtitle2">{t('On hold until')}</Typography>
                  <Controller
                    name="onHoldPeriodEnds"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <DatePicker
                        {...field}
                        value={field.value}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            error: !!error,
                            helperText: error?.message,
                          },
                        }}
                      />
                    )}
                  />
                </Stack>
              )}
              {!isPunchCard && (
                <Stack spacing={1.5}>
                  <Typography variant="subtitle2">{t('Renewal date')}</Typography>
                  <Controller
                    name="renewalDate"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <DatePicker
                        {...field}
                        value={field.value}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            error: !!error,
                            helperText: error?.message,
                          },
                        }}
                      />
                    )}
                  />
                </Stack>
              )}
              {!isPunchCard && (
                <Stack spacing={1.5}>
                  <Typography variant="subtitle2">{t('Price')}</Typography>
                  <RHFTextField
                    name="priceAmount"
                    placeholder="0.00"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Box component="span" sx={{ color: 'text.disabled' }}>
                            {fCurrencySymbol(defaultValues.priceCurrency)}
                          </Box>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Stack>
              )}
              {!isPunchCard && (
                <Stack spacing={1.5}>
                  <Typography variant="subtitle2">{t('Discount amount')}</Typography>
                  <RHFTextField
                    name="discountAmount"
                    placeholder="0.00"
                    type="number"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Box component="span" sx={{ color: 'text.disabled' }}>
                            {fCurrencySymbol(defaultValues.discountCurrency)}
                          </Box>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Stack>
              )}

              {!isPunchCard && (
                <Stack spacing={1.5}>
                  <Typography variant="subtitle2">{t('Discount percentage')}</Typography>
                  <RHFTextField name="discountPercentage" placeholder="0.00" type="number" />
                </Stack>
              )}
              {(isPunchCard || isPersonalTraining) && (
                <Stack spacing={1.5}>
                  <Typography variant="subtitle2">{t('Number of clips')}</Typography>
                  <RHFTextField name="numberOfClips" placeholder="0.00" type="number" />
                </Stack>
              )}
              {isPunchCard && (
                <Stack spacing={1.5}>
                  <Typography variant="subtitle2">{t('Has automatic renewal')}</Typography>
                  <RHFSwitch name="hasAutomaticRenewal" label="" />
                </Stack>
              )}
            </Box>
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button color="inherit" variant="outlined" onClick={onClose}>
            {t('Close')}
          </Button>
          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            {t('Save Changes')}
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
