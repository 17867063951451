import { useCallback } from 'react';
// @mui
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// components
import { DateTime } from 'luxon';
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { useLocales } from '../../locales';
import {
  CampaignMembershipTypeDto,
  ContingentTypeDto,
  PersonalTrainingTypeDto,
  VoucherTypeDto,
} from '../../api';
import { IMembershipTableFilters, IMembershipTableFilterValue } from '../../types/membership';

// ----------------------------------------------------------------------

type Props = {
  filters: IMembershipTableFilters;
  onFilters: (name: string, value: IMembershipTableFilterValue) => void;
  campaigns: CampaignMembershipTypeDto[];
  contingents: ContingentTypeDto[];
  punchCards: VoucherTypeDto[];
  personalTrainings: PersonalTrainingTypeDto[];
  onExport: VoidFunction;
};

export default function MemberTableToolbar({
  filters,
  onFilters,
  campaigns,
  contingents,
  punchCards,
  personalTrainings,
  onExport,
}: Props) {
  const popover = usePopover();

  const { t } = useLocales();

  const handleFilterName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onFilters('name', event.target.value);
    },
    [onFilters]
  );

  const handleFilterType = useCallback(
    (event: SelectChangeEvent<number[]>) => {
      onFilters('membershipTypeIds', event.target.value);
    },
    [onFilters]
  );

  const membershipTypes = [
    ...contingents.map((x) => ({
      name: x.name!,
      id: x.id!,
    })),
    ...campaigns.map((x) => ({
      name: x.name!,
      id: x.id!,
    })),
    ...punchCards.map((x) => ({
      name: x.name!,
      id: x.id!,
    })),
    ...personalTrainings.map((x) => ({
      name: x.name!,
      id: x.id!,
    })),
  ];

  const handleFilterValidOn = useCallback(
    (newValue: DateTime | null) => {
      onFilters('validOn', newValue);
    },
    [onFilters]
  );

  return (
    <>
      <Stack
        spacing={2}
        alignItems={{ xs: 'flex-end', md: 'center' }}
        direction={{
          xs: 'column',
          md: 'row',
        }}
        sx={{
          p: 2.5,
          pr: { xs: 2.5, md: 1 },
        }}
      >
        <Stack direction="row" alignItems="center" spacing={2} flexGrow={1} sx={{ width: 1 }}>
          <TextField
            fullWidth
            value={filters.name}
            onChange={handleFilterName}
            placeholder={t('Search...')}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                </InputAdornment>
              ),
            }}
          />

          <FormControl
            sx={{
              flexShrink: 1,
              width: { xs: 1, md: 1 },
            }}
          >
            <DatePicker
              label={t('On date')}
              value={filters.validOn}
              slotProps={{
                textField: {
                  fullWidth: true,
                },
              }}
              onChange={handleFilterValidOn}
            />
          </FormControl>

          <FormControl
            sx={{
              flexShrink: 1,
              width: { xs: 1, md: 1 },
            }}
          >
            <InputLabel>{t('Membership types')}</InputLabel>

            <Select
              multiple
              value={filters.membershipTypeIds}
              onChange={handleFilterType}
              input={<OutlinedInput label={t('Membership types')} />}
              renderValue={(selected) =>
                membershipTypes
                  .filter((x) => selected.includes(x.id))
                  .map((value) => value.name)
                  .join(', ')
              }
              sx={{ textTransform: 'capitalize' }}
            >
              {campaigns.map((option) => (
                <MenuItem
                  key={`membership-type-${option.id}`}
                  value={option.id}
                  sx={{
                    justifyContent: 'space-between',
                  }}
                >
                  <Checkbox
                    disableRipple
                    size="small"
                    checked={filters.membershipTypeIds.includes(option.id!)}
                  />
                  <Typography
                    sx={{
                      width: '100%',
                    }}
                  >
                    {option.name}
                  </Typography>
                  <Chip
                    size="small"
                    variant="outlined"
                    label={t('Campaign')}
                    color="success"
                    sx={{ ml: 1 }}
                  />
                </MenuItem>
              ))}
              {contingents.map((option) => (
                <MenuItem
                  key={`membership-type-${option.id}`}
                  value={option.id}
                  sx={{
                    justifyContent: 'space-between',
                  }}
                >
                  <Checkbox
                    disableRipple
                    size="small"
                    checked={filters.membershipTypeIds.includes(option.id!)}
                  />
                  <Typography
                    sx={{
                      width: '100%',
                    }}
                  >
                    {option.name}
                  </Typography>
                  <Chip
                    size="small"
                    variant="outlined"
                    label={t('Contingent')}
                    color="info"
                    sx={{ ml: 1 }}
                  />
                </MenuItem>
              ))}
              {personalTrainings.map((option) => (
                <MenuItem
                  key={`membership-type-${option.id}`}
                  value={option.id}
                  sx={{
                    justifyContent: 'space-between',
                  }}
                >
                  <Checkbox
                    disableRipple
                    size="small"
                    checked={filters.membershipTypeIds.includes(option.id!)}
                  />
                  <Typography
                    sx={{
                      width: '100%',
                    }}
                  >
                    {option.name}
                  </Typography>
                  <Chip
                    size="small"
                    variant="outlined"
                    label={t('Personal training')}
                    color="secondary"
                    sx={{ ml: 1 }}
                  />
                </MenuItem>
              ))}
              {punchCards.map((option) => (
                <MenuItem
                  key={`membership-type-${option.id}`}
                  value={option.id}
                  sx={{
                    justifyContent: 'space-between',
                  }}
                >
                  <Checkbox
                    disableRipple
                    size="small"
                    checked={filters.membershipTypeIds.includes(option.id!)}
                  />
                  <Typography
                    sx={{
                      width: '100%',
                    }}
                  >
                    {option.name}
                  </Typography>
                  <Chip
                    size="small"
                    variant="outlined"
                    label={t('Punch card')}
                    color="warning"
                    sx={{ ml: 1 }}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <IconButton onClick={popover.onOpen}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </Stack>
      </Stack>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            popover.onClose();
            onExport();
          }}
        >
          <Iconify icon="solar:export-bold" />
          {t('Export')}
        </MenuItem>
      </CustomPopover>
    </>
  );
}
