import * as Yup from 'yup';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { setError } from 'src/redux/slices/error';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
// routes
import { useParams, useRouter } from 'src/routes/hook';
// components
import { DateTime } from 'luxon';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFMultiSelect, RHFTextField, RHFUpload } from 'src/components/hook-form';
import EmailEditor, { EditorRef, EmailEditorProps } from 'react-email-editor';
//
import { useDispatch, useSelector } from '../../redux/store';
import ArticleDetailsPreview from './article-details-preview';
import { ImageService, NewsletterDto, NewslettersService } from '../../api';
import { useLocales } from '../../locales';

// ----------------------------------------------------------------------

type Props = {
  currentLetter?: NewsletterDto;
};

export default function NewsletterNewEditForm({ currentLetter }: Props) {
  const router = useRouter();

  const { t } = useLocales();

  const mdUp = useResponsive('up', 'md');

  const { enqueueSnackbar } = useSnackbar();

  const emailEditorRef = useRef<EditorRef>(null);

  const preview = useBoolean();

  const gyms = useSelector((state) => state.gym.gyms);

  const globalGym = useSelector((state) => state.gym.globalGym);

  const newSchema = Yup.object().shape({
    subject: Yup.string().required('Title is required'),
    html: Yup.string(),
    json: Yup.mixed().nullable(),
    sendingTime: Yup.date().required('Sending time is required'),
    availableInGyms: Yup.array().min(1, t('Please select at least one gym')),
    attachments: Yup.array().min(0, t('Attachments is required')).nullable(),
  });

  const defaultValues = useMemo(
    () =>
      ({
        subject: currentLetter?.subject || '',
        html: currentLetter?.html || '',
        json: currentLetter?.json || null,
        teaser: currentLetter?.teaser || '',
        tags: currentLetter?.tags || [],
        metaKeywords: currentLetter?.metaKeywords || [],
        metaTitle: currentLetter?.metaTitle || '',
        metaDescription: currentLetter?.metaDescription || '',
        sendingTime: currentLetter?.sendingTime || DateTime.now(),
        availableInGyms: currentLetter?.availableInGyms?.length
          ? currentLetter?.availableInGyms?.map((e) => `${e}`)
          : [`${globalGym.id}`],
        attachments: currentLetter?.attachments?.map((e) => e.url) || null,
      } as any),
    [currentLetter, globalGym]
  );

  const methods = useForm({
    resolver: yupResolver(newSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = methods;

  const values = watch();

  useEffect(() => {
    if (currentLetter) {
      reset(defaultValues);
    }
    if (globalGym) {
      reset(defaultValues);
    }
  }, [currentLetter, defaultValues, reset, globalGym]);

  const params = useParams();

  const { id } = params;

  const onReady: EmailEditorProps['onReady'] = (unlayer) => {
    unlayer.setBodyValues({
      backgroundColor: '#fff',
    });
    unlayer.registerCallback('image', (d: any, x: any) => {
      ImageService.create({
        files: d.attachments,
      }).then((resp) => x({ url: resp.url }));
    });

    NewslettersService.getById({ id: parseInt(id!, 10) }).then((resp) => {
      if (resp?.json !== undefined && resp?.json !== null && resp?.json !== '') {
        unlayer.loadDesign(resp!.json! as any);
      } else if (resp?.html !== undefined && resp?.html !== null && resp?.html !== '') {
        unlayer.loadDesign({
          classic: true,
          html: resp?.html,
        } as any);
      }
    });
  };

  const dispatch = useDispatch();

  const onSubmit = handleSubmit(
    async (data) =>
      new Promise((resolve) => {
        const unlayer = emailEditorRef.current?.editor;
        unlayer?.exportHtml(async (d) => {
          try {
            const { design, html } = d;
            // Check if is edit mode
            if (currentLetter) {
              await NewslettersService.edit({
                id: currentLetter!.id!,
                body: {
                  id: currentLetter!.id!,
                  ...data,
                  html,
                  json: JSON.stringify(design),
                } as any,
              });
              await NewslettersService.uploadAttachment({
                id: currentLetter!.id!,
                files: data.attachments ?? [],
              });
            } else {
              const queryParams = new URLSearchParams(window.location.search);

              const eventId = queryParams.get('eventId');
              const membershipTypeId = queryParams.get('membershipTypeId');
              const newsletter = await NewslettersService.create({
                body: {
                  ...data,
                  eventId,
                  membershipTypeId,
                  html,
                  json: JSON.stringify(design),
                } as any,
              });
              await NewslettersService.uploadAttachment({
                id: newsletter!.id!,
                files: data.attachments ?? [],
              });
            }

            reset();
            preview.onFalse();
            enqueueSnackbar(currentLetter ? t('Update success!') : t('Create success!'));
            router.back();
          } catch (error) {
            dispatch(setError(error));
          }
          resolve(null);
        });
      })
  );

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const files = acceptedFiles.map((file) =>
        Object.assign(file, { preview: URL.createObjectURL(file) })
      );

      setValue('attachments', files, { shouldValidate: true });
    },
    [setValue]
  );

  const handleRemoveFile = useCallback(() => {
    setValue('attachments', null);
  }, [setValue]);

  const renderDetails = (
    <>
      {mdUp && (
        <Grid md={12}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            {t('Details')}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {t('Title, description, image...')}
          </Typography>
        </Grid>
      )}

      <Grid xs={12}>
        <Card>
          {!mdUp && <CardHeader title={t('Details')} />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <RHFTextField name="subject" label={t('Subject')} />

            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {t('List of variables that can be used as placeholders in an email template.')}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              <table>
                <tbody>
                  <tr>
                    <th align="left">{'{{FULL_NAME}}'}</th>
                    <td>{t('Full name of the member')}</td>
                  </tr>
                  <tr>
                    <th align="left">{'{{FIRST_NAME}}'}</th>
                    <td>{t('First name of the member')}</td>
                  </tr>
                  <tr>
                    <th align="left">{'{{LAST_NAME}}'}</th>
                    <td>{t('Surname of the member')}</td>
                  </tr>
                  <tr>
                    <th align="left">{'{{CHAIN_NAME}}'}</th>
                    <td>{t('The name of your box')}</td>
                  </tr>
                </tbody>
              </table>
            </Typography>

            <Stack spacing={1.5}>
              <Typography variant="subtitle2">{t('Content')}</Typography>
              <EmailEditor ref={emailEditorRef} projectId={223858} onReady={onReady} />
            </Stack>

            <Stack spacing={1.5}>
              <Typography variant="subtitle2">{t('Attachment')}</Typography>
              <RHFUpload
                multiple
                name="attachments"
                maxSize={1024 * 1024 * 5}
                onDrop={handleDrop}
                onDelete={handleRemoveFile}
                accept={{
                  'text/*': [],
                  'application/pdf': [],
                  'application/vnd.oasis.opendocument.text': [],
                  'application/msword': [],
                  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
                }}
              />
            </Stack>

            <RHFMultiSelect
              name="availableInGyms"
              label={t('Available in gyms')}
              options={gyms.map((e) => ({ value: `${e.id}`, label: e.name! }))}
              checkbox
            />

            <Controller
              name="sendingTime"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <DateTimePicker
                  ampm={false}
                  label={t('Release time')}
                  {...field}
                  value={field.value}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error: !!error,
                      helperText: error?.message,
                    },
                  }}
                />
              )}
            />
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderActions = (
    <>
      {mdUp && <Grid md={12} />}
      <Grid xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ flexGrow: 1, pl: 3 }} />

        <LoadingButton
          type="submit"
          variant="contained"
          size="large"
          loading={isSubmitting}
          sx={{ ml: 2 }}
        >
          {!currentLetter ? t('Create') : t('Save Changes')}
        </LoadingButton>
      </Grid>
    </>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        {renderDetails}

        {renderActions}
      </Grid>

      <ArticleDetailsPreview
        title={values.subject}
        content={values.html}
        description=""
        coverUrl=""
        //
        open={preview.value}
        isValid={isValid}
        isSubmitting={isSubmitting}
        onClose={preview.onFalse}
        onSubmit={onSubmit}
      />
    </FormProvider>
  );
}
