import { useEffect, useMemo, useState } from 'react';
import { setError } from 'src/redux/slices/error';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import Autocomplete from '@mui/material/Autocomplete';
// utils
// api
// components
import { useSnackbar } from 'src/components/snackbar';
import FormProvider from 'src/components/hook-form';
// types
import { useLocales } from '../../locales';
import { useDispatch, useSelector } from '../../redux/store';
import { ClazzDto, MemberDto, MembersService, SignUpsService } from '../../api';

// ----------------------------------------------------------------------

type Props = {
  onClose: VoidFunction;
  currentClazz: ClazzDto;
};

export default function AddParticipantForm({ currentClazz, onClose }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useLocales();

  const dispatch = useDispatch();

  const gyms = useSelector((state) => state.gym.gyms);

  const EventSchema = Yup.object().shape({
    accountId: Yup.lazy((value) =>
      value === '' ? Yup.string() : Yup.number().min(1, t('Member is required'))
    ),
    classId: Yup.lazy((value) =>
      value === '' ? Yup.string() : Yup.number().min(1, t('Class is required'))
    ),
  });

  const defaultValues = useMemo(
    () => ({
      classId: currentClazz.id || '',
      accountId: '',
    }),
    [currentClazz]
  );

  const methods = useForm({
    resolver: yupResolver(EventSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (currentClazz) {
      reset(defaultValues);
    }
  }, [currentClazz, gyms, defaultValues, reset]);

  const onSubmit = handleSubmit(async () => {
    try {
      await SignUpsService.create({
        body: {
          classId: currentClazz.id,
          accountId: member?.id,
        },
      });

      enqueueSnackbar(t('Member signed up!'));

      onClose();
      reset();
    } catch (error) {
      dispatch(setError(error));
    }
  });

  const [member, setMember] = useState<MemberDto | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<readonly MemberDto[]>([]);

  useEffect(() => {
    if (inputValue === '') {
      setOptions([]);
    } else {
      MembersService.findAll({
        pageNumber: 0,
        pageSize: 10,
        sortBy: ['firstName'],
        search: inputValue,
      }).then((resp) => {
        let newOptions: readonly MemberDto[] = [];

        if (member) {
          newOptions = [member];
        }

        if (resp) {
          newOptions = [...newOptions, ...resp];
        }

        setOptions(newOptions);
      });
    }
  }, [member, inputValue]);

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Stack spacing={3} sx={{ px: 3 }}>
        <Autocomplete
          value={member}
          filterOptions={(x) => x}
          onChange={(event: any, newValue: MemberDto | null) => {
            setMember(newValue);
          }}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          options={options}
          autoComplete
          includeInputInList
          filterSelectedOptions
          noOptionsText={t('No members')}
          getOptionLabel={(option) => option.fullName!}
          renderInput={(params) => <TextField {...params} label={t('Member')} />}
          renderOption={(props, option) => (
            <Box component="li" sx={{ display: 'flex', alignItems: 'center' }} {...props}>
              <Avatar alt={option.fullName} src={option.profileImageUrl} sx={{ mr: 2 }} />

              <ListItemText
                primary={option.fullName}
                secondary={option.emailAddress}
                primaryTypographyProps={{ typography: 'body2' }}
                secondaryTypographyProps={{
                  component: 'span',
                  color: 'text.disabled',
                }}
              />
            </Box>
          )}
        />
      </Stack>

      <DialogActions>
        <Box sx={{ flexGrow: 1 }} />

        <Button variant="outlined" color="inherit" onClick={onClose}>
          {t('Cancel')}
        </Button>

        <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
          {t('Add')}
        </LoadingButton>
      </DialogActions>
    </FormProvider>
  );
}
