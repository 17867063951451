// @mui
import Container from '@mui/material/Container';
// routes
import { paths } from 'src/routes/paths';
// _mock
// components
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
//
import ContractNewEditForm from '../contract-new-edit-form';
import { useLocales } from '../../../locales';

// ----------------------------------------------------------------------

export default function ContractCreateView() {
  const settings = useSettingsContext();

  const { t } = useLocales();

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <CustomBreadcrumbs
        heading={t('Edit')}
        links={[
          {
            name: `${t('Dashboard')}`,
            href: paths.dashboard.root,
          },
          {
            name: `${t('Contract')}`,
            href: paths.contracts.root,
          },
          { name: t('New contract') },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <ContractNewEditForm />
    </Container>
  );
}
