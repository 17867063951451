import { MemberChangeDto } from 'src/api';
import { useCallback, useMemo } from 'react';
// @mui
import TableContainer from '@mui/material/TableContainer';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import CardHeader from '@mui/material/CardHeader';
//
// components
//
import {
  emptyRows,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  useTable,
} from '../../../components/table';
import { useLocales } from '../../../locales';
import { paths } from '../../../routes/paths';
import Scrollbar from '../../../components/scrollbar';
import MemberChangeTableRow from './member-change-table-row';
import { useRouter } from '../../../routes/hook';

// ----------------------------------------------------------------------

type Props = {
  rows: MemberChangeDto[];
};

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof MemberChangeDto>(
  order: Order,
  orderBy: Key
): (a: MemberChangeDto, b: MemberChangeDto) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// ----------------------------------------------------------------------

export default function MemberChangeReportView({ rows }: Props) {
  const { t } = useLocales();

  const router = useRouter();

  const tableHeadNewMembers = [
    { id: 'ownerName', label: t('Member'), align: 'left' },
    { id: 'name', label: t('Membership'), align: 'left' },
    { id: 'ownerEmail', label: t('Email'), align: 'left' },
    { id: 'ownerPhoneNumber', label: t('Phone number'), align: 'left' },
    { id: 'validFrom', label: t('Valid from'), align: 'left' },
    { id: '' },
  ];

  const tableNewMembers = useTable({
    defaultOrderBy: tableHeadNewMembers[0].id,
    defaultRowsPerPage: 10,
  });

  const newNotFound = !rows.length;

  const handleViewRow = useCallback(
    (id: number) => {
      router.push(paths.members.view(id));
    },
    [router]
  );

  const visibleRows = useMemo(
    () =>
      [...rows]
        .sort(getComparator(tableNewMembers.order, tableNewMembers.orderBy as any))
        .slice(
          tableNewMembers.page * tableNewMembers.rowsPerPage,
          tableNewMembers.page * tableNewMembers.rowsPerPage + tableNewMembers.rowsPerPage
        ),
    [tableNewMembers, rows]
  );

  const denseHeight = tableNewMembers.dense ? 52 : 72;

  return (
    <Card>
      <CardHeader title={t('New members')} />
      <TableContainer
        sx={{
          position: 'relative',
          overflow: 'unset',
          pt: 2.5,
        }}
      >
        <Scrollbar>
          <Table size={tableNewMembers.dense ? 'small' : 'medium'}>
            <TableHeadCustom
              order={tableNewMembers.order}
              orderBy={tableNewMembers.orderBy}
              headLabel={tableHeadNewMembers}
              rowCount={rows.length}
              numSelected={tableNewMembers.selected.length}
              onSort={tableNewMembers.onSort}
            />

            <TableBody>
              {visibleRows?.map((row) => (
                <MemberChangeTableRow
                  key={row.id}
                  row={row}
                  onViewRow={() => handleViewRow(row.ownerId!)}
                  onSelectRow={() => handleViewRow(row.ownerId!)}
                />
              ))}

              <TableEmptyRows
                height={denseHeight}
                emptyRows={emptyRows(
                  tableNewMembers.page,
                  tableNewMembers.rowsPerPage,
                  rows.length
                )}
              />

              <TableNoData notFound={newNotFound} />
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>

      <TablePaginationCustom
        count={rows.length}
        page={tableNewMembers.page}
        rowsPerPage={tableNewMembers.rowsPerPage}
        onPageChange={tableNewMembers.onChangePage}
        onRowsPerPageChange={tableNewMembers.onChangeRowsPerPage}
      />
    </Card>
  );
}
