import { createSlice, Dispatch } from '@reduxjs/toolkit';
// utils
import { Direction, getConfigs, QuickLinkDto, QuickLinksService, serviceOptions } from '../../api';
import { setError } from './error';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  quickLinks: [] as QuickLinkDto[],
  currentQuickLink: {} as QuickLinkDto,
  totalNumberOfQuickLinks: 0,
};

const slice = createSlice({
  name: 'quick-links',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    stoppedLoading(state) {
      state.isLoading = false;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getQuickLinksSuccess(state, action) {
      state.quickLinks = action.payload.quickLinks;
      state.totalNumberOfQuickLinks = action.payload.totalNumberOfQuickLinks;
    },
    getQuickLinkSuccess(state, action) {
      state.currentQuickLink = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getQuickLinks(
  params: {
    pageNumber?: number;
    pageSize?: number;
    sortBy?: any | null[];
    direction?: Direction;
    searchString?: string | null;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = '/api/quick-links';

      const configs = getConfigs('get', 'application/json', url, {});

      configs.params = params;
      const resp = await serviceOptions.axios!.request(configs);

      dispatch(
        slice.actions.getQuickLinksSuccess({
          totalNumberOfQuickLinks: parseInt(resp.headers['x-total-count'], 10),
          quickLinks: resp.data,
        })
      );
      dispatch(slice.actions.stoppedLoading());
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}

export function getQuickLink(id: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await QuickLinksService.getProduct({
        id,
      });
      dispatch(slice.actions.getQuickLinkSuccess(response));
      dispatch(slice.actions.stoppedLoading());
    } catch (error) {
      dispatch(setError(error));
    }
  };
}
