import React, { useCallback, useEffect, useState } from 'react';
// @mui
import Card from '@mui/material/Card';
import Tab from '@mui/material/Tab';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
// routes
import { paths } from 'src/routes/paths';
// components
import { useParams } from 'src/routes/hook';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
//
import { enqueueSnackbar } from 'notistack';
import { useDispatch, useSelector } from '../../../redux/store';
import { getEmployee } from '../../../redux/slices/employees';
import ProfileCover from '../../member/profile-cover';
import { useLocales } from '../../../locales';
import Iconify from '../../../components/iconify';
import { MembersService } from '../../../api';
import ProfileHome from '../profile-home';
import ProfileClazz from '../profile-clazz';
import { getGyms } from '../../../redux/slices/gym';
import ProfileSalary from '../profile-salary';
import { RouterLink } from '../../../routes/components';
import { useBoolean } from '../../../hooks/use-boolean';

export default function EmployeeDetailsView() {
  const settings = useSettingsContext();

  const { t } = useLocales();

  const TABS = [
    {
      value: 'profile',
      label: t('Profile'),
      icon: <Iconify icon="solar:user-id-bold" width={24} />,
    },
    {
      value: 'salary',
      label: t('Salary'),
      icon: <Iconify icon="solar:wallet-money-bold-duotone" width={24} />,
    },
    {
      value: 'classes',
      label: t('Classes'),
      icon: <Iconify icon="solar:dumbbell-large-bold-duotone" width={24} />,
    },
  ];

  const dispatch = useDispatch();

  const params = useParams();

  const { id } = params;

  const employee = useSelector((state) => state.employee.currentEmployee);

  const gyms = useSelector((state) => state.gym.gyms);

  const coverImages = gyms.flatMap((e) => e.images ?? []);

  const calendarLinkDialog = useBoolean();

  const copyToClipHolder = () => {
    navigator.clipboard.writeText(`${employee.calendarLink}`);
    calendarLinkDialog.onFalse();

    enqueueSnackbar(t('Link copied to clipboard!'));
  };

  const [currentTab, setCurrentTab] = useState('profile');

  const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue);
  }, []);

  useEffect(() => {
    dispatch(getEmployee(parseInt(id!, 10)));
    dispatch(getGyms());
  }, [dispatch, id]);

  const handleResetPassword = useCallback(() => {
    const request = async () => {
      await MembersService.updatePassword({
        id: employee.accountId!,
      });
      enqueueSnackbar(t('Password has been reset and sent to member!'));
    };

    request();
  }, [employee, t]);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <CustomBreadcrumbs
        heading={t('Employee Profile')}
        links={[
          { name: t('Dashboard'), href: paths.dashboard.root },
          { name: t('Employee'), href: paths.employee.root },
          { name: employee?.name },
        ]}
        action={
          <>
            <Tooltip title={t('Calendar link')}>
              <IconButton onClick={() => calendarLinkDialog.onTrue()}>
                <Iconify icon="solar:calendar-outline" />
              </IconButton>
            </Tooltip>
            <Tooltip title={t('Reset password')}>
              <IconButton onClick={handleResetPassword}>
                <Iconify icon="mdi:password-reset" />
              </IconButton>
            </Tooltip>
            <IconButton component={RouterLink} href={paths.employee.edit(employee?.id ?? -1)}>
              <Iconify icon="solar:pen-bold" />
            </IconButton>
          </>
        }
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <Card
        sx={{
          mb: 3,
          height: 290,
        }}
      >
        <ProfileCover
          role={employee?.roles?.map((s) => t(s)).join(', ') ?? ''}
          name={employee?.name!}
          avatarUrl={employee?.profileImageUrl ?? ''}
          coverUrl={coverImages[Math.floor(Math.random() * coverImages.length)]?.url ?? ''}
        />
        <Tabs
          value={currentTab}
          onChange={handleChangeTab}
          sx={{
            width: 1,
            bottom: 0,
            zIndex: 9,
            position: 'absolute',
            bgcolor: 'background.paper',
            [`& .${tabsClasses.flexContainer}`]: {
              pr: { md: 3 },
              justifyContent: {
                sm: 'center',
                md: 'flex-end',
              },
            },
          }}
        >
          {TABS.map((tab) => (
            <Tab key={tab.value} value={tab.value} icon={tab.icon} label={tab.label} />
          ))}
        </Tabs>
      </Card>

      {currentTab === 'profile' && <ProfileHome employee={employee} />}

      {currentTab === 'salary' && <ProfileSalary member={employee} />}

      {currentTab === 'classes' && <ProfileClazz employee={employee} />}

      <Dialog
        fullWidth
        maxWidth="sm"
        open={calendarLinkDialog.value}
        onClose={calendarLinkDialog.onFalse}
      >
        <DialogTitle>{t('Calendar link')}</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              paddingTop: '6px',
            }}
          >
            <TextField label={t('Calendar link')} value={employee.calendarLink} fullWidth />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={calendarLinkDialog.onFalse}>{t('Cancel')}</Button>
          <Button onClick={copyToClipHolder}>{t('Copy')}</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
