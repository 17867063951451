/* eslint-disable react/no-danger */
import { useCallback } from 'react';
// @mui
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import { Tooltip } from '@mui/material';
import { blue, green, grey, purple } from '@mui/material/colors';
import Chip from '@mui/material/Chip';
// utils
import { fDate } from 'src/utils/format-time';
// components
import Iconify from 'src/components/iconify';
import { enqueueSnackbar } from 'notistack';
import {
  AccountDto,
  CommentService,
  EmailStatus,
  MemberEventDto,
  MemberEventType,
} from '../../api';
import { getMemberComments, getMemberEvents } from '../../redux/slices/members';
import { useDispatch } from '../../redux/store';
import { useLocales } from '../../locales';

// ----------------------------------------------------------------------

interface Props {
  member: AccountDto;
  event: MemberEventDto;
}

export default function EventItem({ event, member }: Props) {
  const { t } = useLocales();

  const dispatch = useDispatch();

  const handleDeleteComment = useCallback(async () => {
    await CommentService.remove({
      id: event.id!,
    });
    dispatch(getMemberEvents(member.id!));
    dispatch(getMemberComments(member.id!));

    enqueueSnackbar(t('Comment removed!'));
  }, [dispatch, event, member, t]);

  const renderAvatar = () => {
    if (event?.type === MemberEventType.DoorAccess) {
      return (
        <Avatar sx={{ bgcolor: green[500] }}>
          <Iconify icon="mingcute:unlock-line" width={24} />
        </Avatar>
      );
    }
    if (event?.type === MemberEventType.Email) {
      return (
        <Avatar sx={{ bgcolor: purple[500] }}>
          {' '}
          <Iconify icon="ic:outline-email" width={24} />
        </Avatar>
      );
    }
    if (event?.type === MemberEventType.Notification) {
      return (
        <Avatar sx={{ bgcolor: blue[500] }}>
          {' '}
          <Iconify icon="mdi:bell-ring" width={24} />
        </Avatar>
      );
    }
    if (event?.type === MemberEventType.ClassRegistration) {
      return (
        <Avatar sx={{ bgcolor: grey[900] }}>
          {' '}
          <Iconify icon="fluent:dumbbell-20-filled" width={24} />
        </Avatar>
      );
    }
    return <Avatar src={event?.createdByProfileImage} alt={event?.createdBy} />;
  };

  const renderActions = () => {
    if (event?.type === MemberEventType.Email) {
      if (event!.status === EmailStatus.Failed) {
        return (
          <Tooltip title={event!.meta?.deliveryMessage ?? ''}>
            <Chip label={t(`${event?.status}`)} variant="outlined" size="small" color="error" />
          </Tooltip>
        );
      }
      if (event!.status === EmailStatus.Delivered) {
        return (
          <Tooltip title={event!.meta?.deliveryMessage ?? ''}>
            <Chip label={t(`${event?.status}`)} variant="outlined" size="small" color="success" />
          </Tooltip>
        );
      }
      if (event!.status === EmailStatus.Rejected) {
        return (
          <Tooltip title={event!.meta?.deliveryMessage ?? ''}>
            <Chip label={t(`${event?.status}`)} variant="outlined" size="small" color="warning" />
          </Tooltip>
        );
      }
      return (
        <Tooltip title={event!.meta?.deliveryMessage ?? ''}>
          <Chip label={t(`${event?.status}`)} variant="outlined" size="small" color="info" />
        </Tooltip>
      );
    }

    if (event.type === MemberEventType.Comment) {
      return (
        <IconButton color="error" onClick={handleDeleteComment}>
          <Iconify icon="eva:trash-2-outline" />
        </IconButton>
      );
    }

    return <Box />;
  };

  const renderHead = (
    <CardHeader
      disableTypography
      avatar={renderAvatar()}
      title={event?.title ?? event?.createdBy}
      subheader={
        <Box sx={{ color: 'text.disabled', typography: 'caption', mt: 0.5 }}>
          {fDate(event.timestamp)}
        </Box>
      }
      action={renderActions()}
    />
  );

  return (
    <Card>
      {renderHead}

      <Typography
        variant="body2"
        sx={{
          p: (theme) => theme.spacing(3, 3, 2, 3),
        }}
      >
        <div dangerouslySetInnerHTML={{ __html: event.content! }} />
      </Typography>
    </Card>
  );
}
