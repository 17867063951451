// @mui
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack, { StackProps } from '@mui/material/Stack';
// types
import {
  IClassTemplateTableFilters,
  IClassTemplateTableFilterValue,
} from 'src/types/class-templates';
// components
import Iconify from 'src/components/iconify';
import { useLocales } from '../../locales';

// ----------------------------------------------------------------------

type Props = StackProps & {
  filters: IClassTemplateTableFilters;
  onFilters: (name: string, value: IClassTemplateTableFilterValue) => void;
  //
  canReset: boolean;
  onResetFilters: VoidFunction;
  //
  results: number;
};

export default function ClassTemplateTableFiltersResult({
  filters,
  onFilters,
  //
  onResetFilters,
  //
  results,
  ...other
}: Props) {
  const { t } = useLocales();

  return (
    <Stack spacing={1.5} {...other}>
      <Box sx={{ typography: 'body2' }}>
        <strong>{results}</strong>
        <Box component="span" sx={{ color: 'text.secondary', ml: 0.25 }}>
          results found
        </Box>
      </Box>

      <Stack flexGrow={1} spacing={1} direction="row" flexWrap="wrap" alignItems="center">
        <Button
          color="error"
          onClick={onResetFilters}
          startIcon={<Iconify icon="solar:trash-bin-trash-bold" />}
        >
          {t('Clear')}
        </Button>
      </Stack>
    </Stack>
  );
}
