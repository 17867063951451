import * as Yup from 'yup';
import { useEffect, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { setError } from 'src/redux/slices/error';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import DialogActions from '@mui/material/DialogActions';
import Tooltip from '@mui/material/Tooltip';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// routes
// components
import { DateTime } from 'luxon';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, {
  RHFAutocomplete,
  RHFEditor,
  RHFSelect,
  RHFTextField,
} from 'src/components/hook-form';
import Iconify from '../../components/iconify';
// types
import { useDispatch, useSelector } from '../../redux/store';
import {
  ClazzDto,
  ExerciseDto,
  ExerciseTypeDto,
  ProgramDto,
  ProgramsService,
  WorkoutDto,
  WorkoutTypeDto,
} from '../../api';
import { useLocales } from '../../locales';
import { fDateTime } from '../../utils/format-time';
import { possibleReps, units } from '../../types/exercise';
import { ICalendarEvent } from '../../types/calendar';

// ----------------------------------------------------------------------

type Props = {
  currentTemplate?: ProgramDto;
  workoutTypes: WorkoutTypeDto[];
  exercises: ExerciseTypeDto[];
  classes: ClazzDto[];
  currentEvent?: ICalendarEvent;
  open: boolean;
  onClose: VoidFunction;
  onCreate: VoidFunction;
};

export default function ProgramNewEditFormDialog({
  currentTemplate,
  workoutTypes,
  exercises,
  classes,
  currentEvent,
  open,
  onClose,
  onCreate,
}: Props) {
  const { t } = useLocales();

  const mdUp = useResponsive('up', 'md');

  const { enqueueSnackbar } = useSnackbar();

  const [searchParams] = useSearchParams();

  const chainSettings = useSelector((state) => state.chain.currentSettings);

  const sortedExercises = [...exercises];
  sortedExercises?.sort((a, b) => a.name!.localeCompare(b.name!));

  const newSchema = Yup.object().shape({
    coachesNotes: Yup.string().nullable(),
    date: Yup.date().nullable(),
    classIds: Yup.array(),
    workouts: Yup.array().min(1).required(t('Add at least one workout')),
    externalProgrammingTrack: Yup.string(),
  });

  const defaultValues = useMemo(
    () =>
      ({
        coachesNotes: currentTemplate?.coachesNotes || '',
        date:
          (currentTemplate?.date ? currentTemplate?.date : null) ||
          (currentEvent?.start ? DateTime.fromJSDate(new Date(currentEvent?.start)) : null) ||
          (searchParams.get('date') ? DateTime.fromISO(searchParams.get('date')!) : DateTime.now()),
        classIds: currentTemplate?.classes?.map((x) => x.id!) || [],
        workouts: currentTemplate?.workouts?.map((x, i) => ({
          ...x,
          workoutTypeId: x.workoutTypeId ?? '',
          title: x.title ?? t('Workout #{{number}}', { number: i + 1 }),
          text: x.text ?? '',
          timecapInMinutes: x.timecapInMinutes ?? '',
          timecapInSeconds: x.timecapInSeconds ?? '',
          exercises:
            x.exercises?.map((y) => ({
              ...y,
              count: y.count ?? '',
              numberOfSets: y.numberOfSets ?? '',
              unit: y.unit ?? '',
              exerciseTypeId: y.exerciseTypeId ?? '',
              womensWeight: y.womensWeight ?? '',
              mensWeight: y.mensWeight ?? '',
              percentage: y.percentage ?? '',
              relationTo: y.relationTo ?? '',
            })) ?? [],
        })) || [
          {
            workoutTypeId: '',
            title: t('Workout #{{number}}', { number: 1 }),
            text: '',
            timecapInMinutes: '',
            timecapInSeconds: '',
            exercises: [],
          },
        ],
        externalProgrammingTrack: currentTemplate?.externalProgrammingTrack,
      } as any),
    [currentTemplate, searchParams, currentEvent, t]
  );

  const methods = useForm({
    resolver: yupResolver(newSchema),
    defaultValues,
  });

  const {
    watch,
    reset,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  useEffect(() => {
    if (currentTemplate) {
      reset(defaultValues);
    }

    if (currentEvent) {
      reset(defaultValues);
    }
  }, [currentTemplate, currentEvent, defaultValues, reset]);

  const addWorkout = () => {
    const workouts = [
      ...values.workouts,
      {
        workoutTypeId: '',
        title: t('Workout #{{number}}', { number: values.workouts.length + 1 }),
        text: '',
        timecapInMinutes: '',
        timecapInSeconds: '',
      },
    ];
    setValue('workouts', workouts, { shouldValidate: true });
  };

  const removeWorkout = (w: WorkoutDto) => {
    const workouts = [...values.workouts.filter((z: WorkoutDto) => z.id !== w.id)];
    setValue('workouts', workouts, { shouldValidate: true });
  };

  const addExercise = (w: WorkoutDto) => {
    if (w.exercises) {
      w.exercises = [
        ...w.exercises!,
        {
          count: '',
          numberOfSets: '',
          unit: '',
          exerciseTypeId: '',
          womensWeight: '',
          mensWeight: '',
          percentage: '',
          relationTo: '',
        } as any,
      ];
    } else {
      w.exercises = [
        {
          count: '',
          numberOfSets: '',
          unit: '',
          exerciseTypeId: '',
          womensWeight: '',
          mensWeight: '',
          percentage: '',
          relationTo: '',
        } as any,
      ];
    }
    setValue('workouts', values.workouts, { shouldValidate: true });
  };

  const removeExercise = (w: WorkoutDto, e: ExerciseDto) => {
    w.exercises = [...w.exercises!.filter((z) => z !== e)];
    setValue('workouts', values.workouts, { shouldValidate: true });
  };
  const dispatch = useDispatch();

  const onSubmit = handleSubmit(async (data) => {
    try {
      // Check if is edit mode
      if (currentTemplate) {
        await ProgramsService.update({
          programId: currentTemplate!.id!,
          body: { id: currentTemplate!.id!, ...data, date: defaultValues.date } as any,
        });
      } else {
        await ProgramsService.create({ body: { ...data, date: defaultValues.date } as any });
      }

      reset();
      enqueueSnackbar(currentTemplate ? t('Update success!') : t('Create success!'));
      onCreate();
      onClose();
    } catch (error) {
      dispatch(setError(error));
    }
  });

  const handleClassChange = (event: SelectChangeEvent<any[]>) => {
    const result =
      typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value;

    setValue('classIds', result, { shouldValidate: true });
  };

  const onCloseForm = () => {
    reset();
    onClose();
  };

  const selectedDate = defaultValues.date;
  const startOfDay = selectedDate.startOf('day');
  const endOfDay = selectedDate.endOf('day');

  const getCurrentClasses = (x: ClazzDto) => startOfDay <= x.begins! && x.begins! <= endOfDay;

  const renderDetails = (
    <Grid xs={12}>
      {!mdUp && <CardHeader title={t('Details')} />}

      <Stack spacing={3}>
        <Stack spacing={1.5}>
          <Typography variant="subtitle2">{t('Coaches notes')}</Typography>
          <RHFEditor simple value={values.coachesNotes} name="coachesNotes" />
        </Stack>

        <Stack spacing={1.5}>
          <Typography variant="subtitle2">{t('Classes')}</Typography>
          <Controller
            name="classIds"
            control={control}
            render={({ fieldState: { error } }) => (
              <FormControl fullWidth error={!!error}>
                <Select
                  multiple
                  value={values.classIds}
                  onChange={(e) => {
                    handleClassChange(e);
                  }}
                  renderValue={(selected) =>
                    classes
                      .filter(getCurrentClasses)
                      .filter((x) => selected.includes(x.id))
                      .map((x) => x.title)
                      .join(', ')
                  }
                  autoWidth={false}
                >
                  {classes.filter(getCurrentClasses).map((option) => (
                    <MenuItem key={`desc-${option.id}`} value={option.id}>
                      <Checkbox
                        disableRipple
                        size="small"
                        checked={values.classIds!.includes(option.id)}
                      />
                      {`${option.title} (${fDateTime(option.begins)})`}
                    </MenuItem>
                  ))}
                </Select>
                {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
              </FormControl>
            )}
          />
        </Stack>
        {chainSettings.programAccessKey && (
          <Stack spacing={1.5}>
            <Typography variant="subtitle2">{t('External programming track')}</Typography>
            <RHFTextField name="externalProgrammingTrack" />
          </Stack>
        )}
      </Stack>
    </Grid>
  );

  const renderPrograms = (
    <Grid xs={12}>
      <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
        {t('Sections')}
      </Typography>
      <Typography variant="body2" sx={{ color: 'text.secondary', mb: 1.5 }}>
        {t(
          'Here you can split your program into sections. Usually there would be sections for warm-up, skills, WOD, and cool down.'
        )}
      </Typography>
      {values?.workouts?.map((w: WorkoutDto, j: number) => (
        <Accordion key={`workout-${j}`}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`workout-content-${j}`}
            id={`workout-${j}`}
          >
            <Typography>{w.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stack spacing={1.5}>
              <Grid container spacing={1.5}>
                <Grid xs={12} md={4}>
                  <Stack direction="row" alignItems="center">
                    <Stack spacing={1.5} sx={{ flexGrow: 1 }}>
                      <Typography variant="subtitle2">{t('Name')}</Typography>
                      <RHFTextField name={`workouts[${j}].title`} value={w.title} />
                    </Stack>
                  </Stack>
                </Grid>
                <Grid xs={12} md={4}>
                  <Stack spacing={1.5}>
                    <Typography variant="subtitle2">{t('Workout type')}</Typography>
                    <RHFSelect name={`workouts[${j}].workoutTypeId`} defaultValue={w.workoutTypeId}>
                      <MenuItem key="work-t-1" value={-1}>
                        {t('None')}
                      </MenuItem>
                      {workoutTypes.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.abbreviation}
                        </MenuItem>
                      ))}
                    </RHFSelect>
                  </Stack>
                </Grid>
                <Grid xs={12} md={4}>
                  <Stack spacing={1.5}>
                    <Typography variant="subtitle2">{t('Time cap')}</Typography>
                    <Stack direction="row" spacing={1.5}>
                      <RHFTextField
                        name={`workouts[${j}].timecapInMinutes`}
                        value={w.timecapInMinutes}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">Min</InputAdornment>,
                        }}
                      />
                      <RHFTextField
                        name={`workouts[${j}].timecapInSeconds`}
                        value={w.timecapInSeconds}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">Sec</InputAdornment>,
                        }}
                      />
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
              <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                {t('Description')}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary', mb: 0.5 }}>
                {t(
                  'If you want to write a free text section and/or you have comments to the exercises, then you can write them here.'
                )}
              </Typography>
              <RHFEditor
                simple
                value={w.text}
                name={`workouts[${j}].text`}
                key={`workout-desc-${w.id ?? j}`}
                id={`workout-desc-${w.id ?? j}`}
                sx={{
                  mb: 1,
                }}
              />
              <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                {t('Exercises')}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary', mb: 1.5 }}>
                {t(
                  'Here you can add exercises to the section. Exercises makes up a workout and is used for tracking member progression and exercise suggestions.'
                )}
              </Typography>
              {w.exercises?.map((e, k) => (
                <Stack spacing={3} key={`workout-${j}-exer-${k}`}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{
                      pb: 2,
                    }}
                  >
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography variant="subtitle1">
                        {t('Exercise #{{number}}', { number: k + 1 })}
                      </Typography>
                    </Box>

                    <Box sx={{ flexShrink: 0 }}>
                      <IconButton color="error" onClick={() => removeExercise(w, e)}>
                        <Iconify icon="eva:trash-2-fill" />
                      </IconButton>
                    </Box>
                  </Stack>
                  <Box
                    gap={3}
                    display="grid"
                    gridTemplateColumns={{ xs: 'repeat(2, 1fr)', md: 'repeat(4, 1fr)' }}
                  >
                    <RHFAutocomplete
                      name={`workouts[${j}].exercises[${k}].exerciseTypeName`}
                      label={t('Exercise')}
                      options={sortedExercises.map((x) => x.name)}
                    />

                    <RHFTextField
                      label={t('Sets')}
                      name={`workouts[${j}].exercises[${k}].numberOfSets`}
                      value={e.numberOfSets}
                    />
                    <RHFTextField
                      label={t('Count')}
                      name={`workouts[${j}].exercises[${k}].count`}
                      value={e.count}
                    />
                    <RHFSelect
                      label={t('Unit')}
                      name={`workouts[${j}].exercises[${k}].unit`}
                      value={e.unit}
                    >
                      {units.map((x) => (
                        <MenuItem key={`workout-${j}-exer-${k}-${x}`} value={x}>
                          {t(x)}
                        </MenuItem>
                      ))}
                    </RHFSelect>

                    {e.unit === 'REPS' && (
                      <>
                        {!(e.percentage || e.relationTo) && (
                          <RHFTextField
                            label={t('Womens weight')}
                            name={`workouts[${j}].exercises[${k}].womensWeight`}
                            value={e.womensWeight}
                          />
                        )}
                        {!(e.percentage || e.relationTo) && (
                          <RHFTextField
                            label={t('Mens weight')}
                            name={`workouts[${j}].exercises[${k}].mensWeight`}
                            value={e.mensWeight}
                          />
                        )}
                        {!(e.mensWeight || e.womensWeight) && (
                          <RHFTextField
                            label={t('Percentage')}
                            name={`workouts[${j}].exercises[${k}].percentage`}
                            value={e.percentage}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                              startAdornment: <InputAdornment position="start">%</InputAdornment>,
                            }}
                          />
                        )}
                        {!(e.mensWeight || e.womensWeight) && (
                          <RHFSelect
                            label={t('Relation to')}
                            name={`workouts[${j}].exercises[${k}].relationTo`}
                            value={e.relationTo}
                          >
                            {possibleReps.map((x) => (
                              <MenuItem key={`workout-${j}-exer-${k}-${x}`} value={x}>
                                {t(x)}
                              </MenuItem>
                            ))}
                          </RHFSelect>
                        )}
                      </>
                    )}
                  </Box>
                </Stack>
              ))}
              <Box display="flex" justifyContent="space-evenly">
                <Tooltip title={t('Remove workout')}>
                  <IconButton color="error" onClick={() => removeWorkout(w)}>
                    <Iconify icon="eva:trash-2-fill" width={32} height={32} />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t('Add exercise')}>
                  <IconButton color="primary" onClick={() => addExercise(w)}>
                    <Iconify icon="mdi:human-barbell" width={32} height={32} />
                  </IconButton>
                </Tooltip>
              </Box>
            </Stack>
          </AccordionDetails>
        </Accordion>
      ))}
    </Grid>
  );

  const renderActions = (
    <Box>
      <Button onClick={addWorkout} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
        {t('Add section')}
      </Button>

      <LoadingButton
        type="submit"
        variant="contained"
        loading={isSubmitting}
        sx={{
          ml: 1,
        }}
      >
        {!currentTemplate ? `${t('Create')}` : `${t('Save Changes')}`}
      </LoadingButton>
    </Box>
  );

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onCloseForm}>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle>{currentEvent?.id ? t('Edit program') : t('Create program')}</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            {renderDetails}

            {renderPrograms}
          </Grid>
        </DialogContent>
        <DialogActions>{renderActions}</DialogActions>
      </FormProvider>
    </Dialog>
  );
}
