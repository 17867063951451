import * as Yup from 'yup';
import { useEffect, useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import InputAdornment from '@mui/material/InputAdornment';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hook';
// components
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
// types
import { HourlyWageRateDto, PayrollSystem, WageRatesService } from '../../api';
import { useLocales } from '../../locales';
import { fCurrencySymbol } from '../../utils/format-number';
import { useSelector } from '../../redux/store';

// ----------------------------------------------------------------------

type Props = {
  currentWageRate?: HourlyWageRateDto;
};

export default function WageRateNewEditForm({ currentWageRate }: Props) {
  const router = useRouter();

  const { t } = useLocales();

  const mdUp = useResponsive('up', 'md');

  const { enqueueSnackbar } = useSnackbar();

  const [alertMessage, setAlertMessage] = useState('');

  const settings = useSelector((state) => state.chain.currentSettings);

  const newSchema = Yup.object().shape({
    name: Yup.string().required(t('Name is required')),
    externalId: Yup.string().required(t('External ID is required')),
    payrollSystem: Yup.string().required(t('Payroll system is required')),
    amount: Yup.number().required(t('Hourly rate is required')),
  });

  const defaultValues = useMemo(
    () => ({
      name: currentWageRate?.name || '',
      externalId: currentWageRate?.externalId || '',
      payrollSystem: currentWageRate?.payrollSystem || PayrollSystem.Danloen,
      amount: currentWageRate?.amount || 0,
    }),
    [currentWageRate]
  );

  const methods = useForm({
    resolver: yupResolver(newSchema),
    defaultValues,
  });

  const {
    watch,
    reset,
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  useEffect(() => {
    if (currentWageRate) {
      reset(defaultValues);
    }
  }, [currentWageRate, defaultValues, reset]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      // Check if is edit mode
      if (currentWageRate) {
        await WageRatesService.edit({
          id: currentWageRate!.id!,
          body: { id: currentWageRate!.id!, ...data } as any,
        });
      } else {
        await WageRatesService.create({ body: { ...data } as any });
      }

      reset();
      enqueueSnackbar(currentWageRate ? t('Update success!') : t('Create success!'));
      router.push(paths.wageRates.root);
    } catch (error) {
      setAlertMessage(`${error.response?.data?.detail}`);
    }
  });

  const renderAlert = (
    <>
      {alertMessage && (
        <Grid xs={12}>
          <Alert severity="error">{alertMessage}</Alert>
        </Grid>
      )}
    </>
  );

  const renderDetails = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            {t('Details')}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {t(
              'Here you give details about the wage rate. External ID should match that in the payroll system.'
            )}
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title={t('Details')} />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <Stack spacing={1.5}>
              <Typography variant="subtitle2">{t('Name')}</Typography>
              <RHFTextField name="name" />
            </Stack>

            <Stack spacing={1.5}>
              <Typography variant="subtitle2">{t('External ID')}</Typography>
              <RHFTextField name="externalId" />
            </Stack>

            <Stack spacing={1.5}>
              <Typography variant="subtitle2">{t('Payroll System')}</Typography>
              <Controller
                name="payrollSystem"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormControl fullWidth error={!!error}>
                    <Select value={values.payrollSystem} autoWidth={false}>
                      <MenuItem key="Danloen" value={PayrollSystem.Danloen}>
                        {t('Danloen')}
                      </MenuItem>
                    </Select>
                    {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
                  </FormControl>
                )}
              />
            </Stack>

            <Stack spacing={1.5}>
              <Typography variant="subtitle2">{t('Hourly rate')}</Typography>
              <RHFTextField
                name="amount"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Box component="span" sx={{ color: 'text.disabled' }}>
                        {fCurrencySymbol(settings.defaultCurrency)}
                      </Box>
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderActions = (
    <>
      {mdUp && <Grid md={4} />}
      <Grid xs={12} md={8} sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ flexGrow: 1, pl: 3 }} />

        <LoadingButton
          type="submit"
          variant="contained"
          size="large"
          loading={isSubmitting}
          sx={{ ml: 2 }}
        >
          {!currentWageRate ? t('Create') : t('Save Changes')}
        </LoadingButton>
      </Grid>
    </>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        {renderAlert}

        {renderDetails}

        {renderActions}
      </Grid>
    </FormProvider>
  );
}
