import * as Yup from 'yup';
import { useCallback, useEffect, useMemo } from 'react';
import { setError } from 'src/redux/slices/error';
import { useDispatch } from 'src/redux/store';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
// routes
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// components
import { DateTime } from 'luxon';
import { useSnackbar } from 'src/components/snackbar';
import { useRouter } from 'src/routes/hook';
import FormProvider, { RHFSwitch, RHFTextField } from 'src/components/hook-form';
// types
import { LockDto, LocksService } from '../../api';
import { useLocales } from '../../locales';

// ----------------------------------------------------------------------

type Props = {
  currentLock?: LockDto;
};

export default function LockNewEditForm({ currentLock }: Props) {
  const router = useRouter();

  const mdUp = useResponsive('up', 'md');

  const { t } = useLocales();

  const { enqueueSnackbar } = useSnackbar();

  const newSchema = Yup.object().shape({
    name: Yup.string().required(t('Name is required')),
    address: Yup.string(),
    externalId: Yup.string().required(t('External ID is required')),
    openingHours: Yup.array().required(),
    useAutoLock: Yup.boolean(),
    automaticallyLockDoorsOutsideOpeningHours: Yup.boolean(),
    automaticallyUnlockDoorsInsideOpeningHours: Yup.boolean(),
  });

  const defaultValues = useMemo(
    () => ({
      name: currentLock?.name || '',
      address: currentLock?.address || '',
      externalId: currentLock?.externalId || '',
      useAutoLock: currentLock?.useAutoLock ?? false,
      automaticallyLockDoorsOutsideOpeningHours:
        currentLock?.automaticallyLockDoorsOutsideOpeningHours ?? false,
      automaticallyUnlockDoorsInsideOpeningHours:
        currentLock?.automaticallyUnlockDoorsInsideOpeningHours ?? false,
      openingHours: currentLock?.openingHours?.length
        ? currentLock?.openingHours
        : [
            {
              from: '00:00:00',
              to: '23:59:00',
              dayOfWeek: 'Monday',
            },
            {
              from: '00:00:00',
              to: '23:59:00',
              dayOfWeek: 'Tuesday',
            },
            {
              from: '00:00:00',
              to: '23:59:00',
              dayOfWeek: 'Wednesday',
            },
            {
              from: '00:00:00',
              to: '23:59:00',
              dayOfWeek: 'Thursday',
            },
            {
              from: '00:00:00',
              to: '23:59:00',
              dayOfWeek: 'Friday',
            },
            {
              from: '00:00:00',
              to: '23:59:00',
              dayOfWeek: 'Saturday',
            },
            {
              from: '00:00:00',
              to: '23:59:00',
              dayOfWeek: 'Sunday',
            },
          ].map((x) => ({
            ...x,
            from: x.from ? DateTime.fromISO(`2000-01-01T${x.from}`) : null,
            to: x.to ? DateTime.fromISO(`2000-01-01T${x.to}`) : null,
          })),
    }),
    [currentLock]
  );

  const methods = useForm({
    resolver: yupResolver(newSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  useEffect(() => {
    if (currentLock) {
      reset(defaultValues);
    }
  }, [currentLock, defaultValues, reset]);

  const dispatch = useDispatch();

  const onSubmit = handleSubmit(async (data) => {
    try {
      // Check if is edit mode
      const body = {
        ...data,
        id: currentLock?.id,
        openingHours: data.openingHours.map((x) => ({
          ...x,
          from: x.from ? x.from.toFormat('HH:mm:ss') : null,
          to: x.to ? x.to.toFormat('HH:mm:ss') : null,
        })),
      };

      await LocksService.edit({
        id: currentLock!.id!,
        body,
      });

      reset();
      enqueueSnackbar(currentLock ? t('Update success!') : t('Create success!'));
      router.back();
    } catch (error) {
      dispatch(setError(error));
    }
  });

  const handleOpeningHourFromChange = useCallback(
    (newValue: Date | null, index: number) => {
      values.openingHours[index].from = newValue;

      setValue(
        'openingHours',
        values.openingHours.map((x) => ({ ...x }))
      );
    },
    [setValue, values]
  );

  const handleOpeningHourToChange = useCallback(
    (newValue: Date | null, index: number) => {
      values.openingHours[index].to = newValue;

      setValue(
        'openingHours',
        values.openingHours.map((x) => ({ ...x }))
      );
    },
    [setValue, values]
  );

  const renderDetails = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            {t('Settings')}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {t('Settings for the door lock')}
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title={t('Settings')} />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <RHFTextField name="name" label={t('Name')} disabled />
            <RHFTextField name="externalId" label={t('External ID')} disabled />
            <RHFSwitch name="useAutoLock" label={t('Use auto lock')} />
            <RHFSwitch
              name="automaticallyLockDoorsOutsideOpeningHours"
              label={t('Automatically lock doors outside opening hours')}
            />
            <RHFSwitch
              name="automaticallyUnlockDoorsInsideOpeningHours"
              label={t('Automatically unlock doors inside opening hours')}
            />
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderOpeningHours = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            {t('Opening hours')}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {t('The opening hours for the door.')}
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title={t('Opening hours')} />}

          <Stack spacing={3} sx={{ p: 3 }}>
            {values.openingHours?.map((item, index) => (
              <Stack spacing={2} key={`day-of-week-${item.dayOfWeek}`}>
                <Typography
                  variant="subtitle2"
                  sx={{
                    textTransform: 'capitalize',
                  }}
                >
                  {t(item.dayOfWeek)}
                </Typography>
                <Stack direction="row" spacing={1.5}>
                  <TimePicker
                    label={t('Open')}
                    value={item.from}
                    ampm={false}
                    onChange={(newValue) => handleOpeningHourFromChange(newValue, index)}
                  />

                  <TimePicker
                    label={t('Closed')}
                    value={item.to}
                    ampm={false}
                    onChange={(newValue) => handleOpeningHourToChange(newValue, index)}
                  />
                </Stack>
              </Stack>
            ))}
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderActions = (
    <>
      {mdUp && <Grid md={4} />}
      <Grid xs={12} md={8} sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ flexGrow: 1, pl: 3 }} />

        <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
          {!currentLock ? t('Create') : t('Save Changes')}
        </LoadingButton>
      </Grid>
    </>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        {renderDetails}

        {renderOpeningHours}

        {renderActions}
      </Grid>
    </FormProvider>
  );
}
