// @mui
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import ListItemText from '@mui/material/ListItemText';
// hooks
// components
import { formatDuration } from 'date-fns';
// types
import { HoursDto } from '../../api';
//

// ----------------------------------------------------------------------

type Props = {
  row: HoursDto;
};

export default function HourBankItemTableRow({ row }: Props) {
  const {
    employeeName,
    earnedHours,
    remainingHours,
    spentHours,
    earnedMinutes,
    remainingMinutes,
    spentMinutes,
  } = row;

  return (
    <TableRow hover>
      <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
        <ListItemText
          primary={employeeName}
          primaryTypographyProps={{ typography: 'body2' }}
          secondaryTypographyProps={{ component: 'span', color: 'text.disabled' }}
        />
      </TableCell>

      <TableCell align="left">
        {formatDuration({
          hours: earnedHours,
          minutes: earnedMinutes,
        })}
      </TableCell>

      <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
        {formatDuration({
          hours: spentHours,
          minutes: spentMinutes,
        })}
      </TableCell>

      <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
        {formatDuration({
          hours: remainingHours,
          minutes: remainingMinutes,
        })}
      </TableCell>

      <TableCell align="right" sx={{ textTransform: 'capitalize' }} />
    </TableRow>
  );
}
