// @mui
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import Rating from '@mui/material/Rating';
// utils
import { fDateTime } from 'src/utils/format-time';
import { paths } from 'src/routes/paths';
// components
import { ClassFeedbackDto } from '../../api';
import { useLocales } from '../../locales';

// ----------------------------------------------------------------------

interface Props {
  feedback: ClassFeedbackDto;
}

export default function FeedbackItem({ feedback }: Props) {
  const { t } = useLocales();

  const renderHead = (
    <CardHeader
      disableTypography
      avatar={<Avatar src={feedback.memberProfileImageUrl} alt={feedback.memberName} />}
      title={
        <Link color="inherit" variant="subtitle1" href={paths.members.view(feedback.memberId!)}>
          {feedback.memberName}
        </Link>
      }
      subheader={
        <Box sx={{ color: 'text.disabled', typography: 'caption', mt: 0.5 }}>
          <Link color="inherit" href={paths.classes.view(feedback.classId!)}>
            {feedback.classTitle}
          </Link>
        </Box>
      }
    />
  );

  return (
    <Card>
      {renderHead}
      <Stack
        spacing={2}
        sx={{
          p: 3,
          position: 'relative',
        }}
      >
        <Box>
          <Typography
            variant="subtitle2"
            sx={{
              paddingBottom: 1,
            }}
          >
            {t('Overall')}
          </Typography>
          <Rating value={feedback.overallRating ?? 0} size="small" readOnly precision={0.5} />
        </Box>{' '}
        <Box>
          <Typography
            variant="subtitle2"
            sx={{
              paddingBottom: 1,
            }}
          >
            {t('Coach preparation')}
          </Typography>
          <Rating
            value={feedback.coachPreparationRating ?? 0}
            size="small"
            readOnly
            precision={0.5}
          />{' '}
        </Box>{' '}
        <Box>
          <Typography
            variant="subtitle2"
            sx={{
              paddingBottom: 1,
            }}
          >
            {t('Coach observation')}
          </Typography>
          <Rating
            value={feedback.coachObservationRating ?? 0}
            size="small"
            readOnly
            precision={0.5}
          />{' '}
        </Box>{' '}
        <Box>
          <Typography
            variant="subtitle2"
            sx={{
              paddingBottom: 1,
            }}
          >
            {t('Clarity of instructions')}
          </Typography>
          <Rating
            value={feedback.clarityOfInstructions ?? 0}
            size="small"
            readOnly
            precision={0.5}
          />{' '}
        </Box>{' '}
        <Box>
          <Typography
            variant="subtitle2"
            sx={{
              paddingBottom: 1,
            }}
          >
            {t('Logistical')}
          </Typography>
          <Rating value={feedback.logisticalRating ?? 0} size="small" readOnly precision={0.5} />{' '}
        </Box>{' '}
        <Box>
          <Typography
            variant="subtitle2"
            sx={{
              paddingBottom: 1,
            }}
          >
            {t('Feedback helpfulness')}
          </Typography>
          <Rating
            value={feedback.feedbackHelpfulnessRating ?? 0}
            size="small"
            readOnly
            precision={0.5}
          />{' '}
        </Box>{' '}
        <Box>
          <Typography
            variant="subtitle2"
            sx={{
              paddingBottom: 1,
            }}
          >
            {t('Varm-up effectiveness')}
          </Typography>
          <Rating
            value={feedback.warmUpEffectivenessRating ?? 0}
            size="small"
            readOnly
            precision={0.5}
          />
        </Box>
        {feedback.body && <Typography variant="body2">{feedback.body}</Typography>}
        <Box sx={{ color: 'text.disabled', typography: 'caption', mt: 0.5 }}>
          {t('Posted {{timestamp}}', { timestamp: fDateTime(feedback.createdDate) })}
        </Box>
      </Stack>
    </Card>
  );
}
