import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import {
  Button,
  Card,
  Container,
  IconButton,
  Stack,
  Tab,
  Table,
  TableBody,
  TableContainer,
  Tabs,
  Tooltip,
} from '@mui/material';
// routes
import { paths } from '../../../routes/paths';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import ConfirmDialog from '../../../components/custom-dialog/confirm-dialog';
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
import { useSettingsContext } from '../../../components/settings';
import {
  emptyRows,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  useTable,
} from '../../../components/table';
// sections
import { useDispatch, useSelector } from '../../../redux/store';
import { getStaff } from '../../../redux/slices/employees';
import { Direction, EmployeesService } from '../../../api';
import { useBoolean } from '../../../hooks/use-boolean';
import { useRouter } from '../../../routes/hook';
import { IUserTableFilterValue } from '../../../types/user';
import EmployeeTableRow from '../employee-table-row';
import { useLocales } from '../../../locales';
import EmployeeTableToolbar from '../employee-table-toolbar';
import Label from '../../../components/label';

// ----------------------------------------------------------------------

export default function EmployeeListView() {
  const { t } = useLocales();

  const TABLE_HEAD = [
    { id: 'name', label: t('Name'), align: 'left' },
    { id: 'phoneNumber', label: t('Phone number'), align: 'left' },
    { id: 'role', label: t('Role'), align: 'left' },
    { id: '' },
  ];

  const { themeStretch } = useSettingsContext();

  const dispatch = useDispatch();

  const tableData = useSelector((state) => state.employee.employees);

  const totalNumberOfEmployees = useSelector((state) => state.employee.totalNumberOfEmployees);

  const tableStats = useSelector((state) => state.employee.stats);

  const STATUS_OPTIONS = [
    { value: 'active', label: t('Active'), color: 'success', count: tableStats.totalActive },
    { value: 'inactive', label: t('Inactive'), color: 'warning', count: tableStats.totalInactive },
    { value: 'all', label: t('All'), color: 'default', count: tableStats.total },
  ] as const;

  const tableSettings = useMemo(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const storedRowsPerPage = queryParams.get('rowsPerPage');
    const storedPage = queryParams.get('page');
    const storedOrderBy = queryParams.get('orderBy');
    const storedOrder = queryParams.get('order');

    return {
      rowsPerPage: parseInt(storedRowsPerPage ?? '10', 10),
      page: parseInt(storedPage ?? '0', 10),
      orderBy: storedOrderBy ?? 'name',
      order: storedOrder === 'desc' ? 'desc' : ('asc' as any),
    };
  }, []);

  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    onResetPage,
    //
    selected,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({
    defaultOrderBy: tableSettings.orderBy,
    defaultOrder: tableSettings.order,
    defaultRowsPerPage: tableSettings.rowsPerPage,
    defaultCurrentPage: tableSettings.page,
  });

  const defaultFilters = useMemo(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const storedStatus = queryParams.get('status');
    const storedSearch = queryParams.get('search');

    return {
      status: storedStatus ?? '',
      name: storedSearch ?? '',
      role: [],
    };
  }, []);

  const [filters, setFilters] = useState(defaultFilters);

  useEffect(() => {
    let isActive = null as null | boolean;
    if (filters.status === 'active') {
      isActive = true;
    }

    if (filters.status === 'inactive') {
      isActive = false;
    }

    dispatch(
      getStaff({
        pageSize: rowsPerPage,
        pageNumber: page,
        sortBy: [orderBy],
        direction: order === 'asc' ? Direction.Asc : Direction.Desc,
        isActive,
        searchString: filters.name,
      })
    );
  }, [dispatch, filters, order, orderBy, page, rowsPerPage]);

  useEffect(() => {
    const newurl = `${window.location.protocol}//${window.location.host}${
      window.location.pathname
    }?rowsPerPage=${rowsPerPage}&page=${page}&orderBy=${orderBy}&order=${order}&status=${
      filters.status
    }&search=${filters.name ?? ''}`;
    window.history.replaceState({ path: newurl }, '', newurl);
  }, [rowsPerPage, page, filters, orderBy, order]);

  const router = useRouter();

  const notFound = (!tableData.length && !!filters.name) || (!tableData.length && !!filters.status);

  const denseHeight = dense ? 56 : 76;

  const confirm = useBoolean();

  const handleFilters = useCallback(
    (name: string, value: IUserTableFilterValue) => {
      onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [onResetPage]
  );

  const handleDeleteRow = useCallback(
    (id: number) => {
      const action = async () => {
        await EmployeesService.delete({
          id,
        });

        let isActive = null as null | boolean;
        if (filters.status === 'active') {
          isActive = true;
        }

        if (filters.status === 'inactive') {
          isActive = false;
        }

        dispatch(
          getStaff({
            pageSize: rowsPerPage,
            pageNumber: page,
            sortBy: [orderBy],
            direction: order === 'asc' ? Direction.Asc : Direction.Desc,
            isActive,
            searchString: filters.name,
          })
        );
      };
      action();
    },
    [dispatch, filters, order, orderBy, page, rowsPerPage]
  );

  const handleEditRow = useCallback(
    (id: number) => {
      router.push(paths.employee.edit(id));
    },
    [router]
  );

  const handleViewRow = useCallback(
    (id: number) => {
      router.push(paths.employee.details(id));
    },
    [router]
  );

  const handleFilterStatus = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      handleFilters('status', newValue);
    },
    [handleFilters]
  );

  return (
    <>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading={`${t('Employee List')}`}
          links={[
            { name: `${t('Dashboard')}`, href: paths.dashboard.root },
            { name: `${t('Employee')}`, href: paths.employee.root },
            { name: `${t('List')}` },
          ]}
          action={
            <Button
              component={RouterLink}
              to={paths.employee.new}
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              {t('New Employee')}
            </Button>
          }
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />

        <Card>
          <Tabs
            value={filters.status}
            onChange={handleFilterStatus}
            sx={{
              px: 2.5,
              boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
            }}
          >
            {STATUS_OPTIONS.map((tab) => (
              <Tab
                key={tab.value}
                iconPosition="end"
                value={tab.value}
                label={tab.label}
                icon={
                  <Label
                    variant={
                      ((tab.value === 'all' || tab.value === filters.status) && 'filled') || 'soft'
                    }
                    color={tab.color}
                  >
                    {tab.count}
                  </Label>
                }
              />
            ))}
          </Tabs>

          <EmployeeTableToolbar filters={filters} onFilters={handleFilters} />

          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <TableSelectedAction
              dense={dense}
              numSelected={selected.length}
              rowCount={tableData.length}
              onSelectAllRows={() => {}}
              action={
                <Stack direction="row">
                  <Tooltip title="Sent">
                    <IconButton color="primary">
                      <Iconify icon="iconamoon:send-fill" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Download">
                    <IconButton color="primary">
                      <Iconify icon="eva:download-outline" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Print">
                    <IconButton color="primary">
                      <Iconify icon="solar:printer-minimalistic-bold" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title={t('Delete')}>
                    <IconButton color="primary" onClick={confirm.onTrue}>
                      <Iconify icon="solar:trash-bin-trash-bold" />
                    </IconButton>
                  </Tooltip>
                </Stack>
              }
            />

            <Scrollbar>
              <Table size={dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                  onSort={onSort}
                />

                <TableBody>
                  {tableData?.map((row) => (
                    <EmployeeTableRow
                      key={row.id}
                      row={row}
                      onSelectRow={() => handleViewRow(row.id!)}
                      onEditRow={() => handleEditRow(row.id!)}
                      onDeleteRow={() => handleDeleteRow(row.id!)}
                    />
                  ))}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(page, rowsPerPage, totalNumberOfEmployees)}
                  />

                  <TableNoData notFound={notFound} />
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={totalNumberOfEmployees}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            //
            dense={dense}
            onChangeDense={onChangeDense}
          />
        </Card>
      </Container>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title={t('Delete')}
        content={
          <>
            Are you sure want to delete <strong> {selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              confirm.onFalse();
            }}
          >
            {t('Delete')}
          </Button>
        }
      />
    </>
  );
}

// ----------------------------------------------------------------------
