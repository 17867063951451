import { useCallback, useEffect, useState } from 'react';
// @mui
import { alpha, useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import IconButton from '@mui/material/IconButton';
import CardHeader from '@mui/material/CardHeader';
import Card, { CardProps } from '@mui/material/Card';
import ListItemText from '@mui/material/ListItemText';
import TableContainer from '@mui/material/TableContainer';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Tooltip from '@mui/material/Tooltip';
// utils
import isEqual from 'lodash/isEqual';
// components
import { enqueueSnackbar } from 'notistack';
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import {
  emptyRows,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  useTable,
} from 'src/components/table';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { AccountDto, Direction, ParticipationStatus, SignUpDto, SignUpsService } from '../../api';
import { fDateTime } from '../../utils/format-time';
import { useLocales } from '../../locales';
import { useRouter } from '../../routes/hook';
import { useBoolean } from '../../hooks/use-boolean';
import { useDispatch, useSelector } from '../../redux/store';
import { IUserTableFilterValue } from '../../types/user';
import { paths } from '../../routes/paths';
import { getMemberSignUps } from '../../redux/slices/members';
import { IMemberRegistrationFilters } from '../../types/member';
import BookingTableToolbar from './booking/membership-table-toolbar';
import BookingTableFiltersResult from './booking/membership-table-filters-result';

interface Props extends CardProps {
  title?: string;
  subheader?: string;
  member: AccountDto;
}

const defaultFilters: IMemberRegistrationFilters = {
  status: 'all',
};

export default function BookingDetails({ title, subheader, member, ...other }: Props) {
  const { t } = useLocales();

  const TABLE_HEAD = [
    { id: 'className', label: t('Class') },
    { id: 'classBegins', label: t('Begins') },
    { id: 'SignedUpAt', label: t('Signed up') },
    { id: 'CheckedInAt', label: t('Checked in') },
    { id: 'OptedOutAt', label: t('Opted out') },
    { id: 'status', label: 'Status' },
    { id: '' },
  ];

  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    onResetPage,
    //
    selected,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({
    defaultOrderBy: TABLE_HEAD[0].id,
    defaultOrder: 'desc',
  });

  const confirm = useBoolean();

  const dispatch = useDispatch();

  const tableData = useSelector((state) => state.member.currentMemberClassRegistrations);

  const totalNumberOfSignUps = useSelector(
    (state) => state.member.currentTotalMemberClassRegistrations
  );

  const tableStats = useSelector((state) => state.member.currentTotalMemberClassRegistrationStats);

  const [filters, setFilters] = useState(defaultFilters);

  useEffect(() => {
    dispatch(
      getMemberSignUps({
        pageSize: rowsPerPage,
        pageNumber: page,
        sortBy: [orderBy],
        direction: order === 'asc' ? Direction.Asc : Direction.Desc,
        accountId: member.id!,
        status: filters.status === 'all' ? null : filters.status,
      })
    );
  }, [dispatch, rowsPerPage, page, orderBy, order, member, filters]);

  const STATUS_OPTIONS = [
    { value: 'all', label: t('All'), color: 'info', count: tableStats.total },
    {
      value: ParticipationStatus.OnTime,
      label: t(ParticipationStatus.OnTime),
      color: 'success',
      count: tableStats.onTime,
    },
    {
      value: ParticipationStatus.TooLate,
      label: t(ParticipationStatus.TooLate),
      color: 'warning',
      count: tableStats.tooLate,
    },
    {
      value: ParticipationStatus.OptedOut,
      label: t(ParticipationStatus.OptedOut),
      color: 'default',
      count: tableStats.optedOut,
    },
    {
      value: ParticipationStatus.OptedOutTooLate,
      label: t(ParticipationStatus.OptedOutTooLate),
      color: 'warning',
      count: tableStats.optedOutTooLate,
    },
    {
      value: ParticipationStatus.OnWaitingList,
      label: t(ParticipationStatus.OnWaitingList),
      color: 'info',
      count: tableStats.onWaitingList,
    },
    {
      value: ParticipationStatus.SignedUp,
      label: t(ParticipationStatus.SignedUp),
      color: 'default',
      count: tableStats.signedUp,
    },
    {
      value: ParticipationStatus.NeverMet,
      label: t(ParticipationStatus.NeverMet),
      color: 'error',
      count: tableStats.neverMet,
    },
  ] as const;

  const canReset = !isEqual(defaultFilters, filters);

  const notFound = !tableData.length && !!filters.status;

  const handleFilters = useCallback(
    (name: string, value: IUserTableFilterValue) => {
      onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [onResetPage]
  );

  const handleDeleteRow = useCallback(
    (row: SignUpDto) => {
      const action = async () => {
        await SignUpsService.edit({
          id: row.id!,
          body: {
            id: row.id!,
            status: ParticipationStatus.OptedOut,
          },
        });

        enqueueSnackbar(t('Sign up removed!'));

        dispatch(
          getMemberSignUps({
            pageSize: rowsPerPage,
            pageNumber: page,
            sortBy: [orderBy],
            direction: order === 'asc' ? Direction.Asc : Direction.Desc,
            accountId: member.id!,
            status: filters.status === 'all' ? null : filters.status,
          })
        );
      };

      action();
    },
    [dispatch, rowsPerPage, page, orderBy, order, member, filters, t]
  );

  const handleCheckInRow = useCallback(
    (row: SignUpDto) => {
      const action = async () => {
        await SignUpsService.edit({
          id: row.id!,
          body: {
            id: row.id!,
            status: ParticipationStatus.OnTime,
          },
        });

        enqueueSnackbar(t('Member is checked in!'));

        dispatch(
          getMemberSignUps({
            pageSize: rowsPerPage,
            pageNumber: page,
            sortBy: [orderBy],
            direction: order === 'asc' ? Direction.Asc : Direction.Desc,
            accountId: member.id!,
            status: filters.status === 'all' ? null : filters.status,
          })
        );
      };

      action();
    },
    [dispatch, rowsPerPage, page, orderBy, order, member, filters, t]
  );

  const handleFilterStatus = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      handleFilters('status', newValue);
    },
    [handleFilters]
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  const denseHeight = dense ? 52 : 72;

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} sx={{ mb: 3 }} />

      <Tabs
        value={filters.status}
        onChange={handleFilterStatus}
        sx={{
          px: 2.5,
          boxShadow: (bTheme) => `inset 0 -2px 0 0 ${alpha(bTheme.palette.grey[500], 0.08)}`,
        }}
      >
        {STATUS_OPTIONS.map((tab) => (
          <Tab
            key={tab.value}
            iconPosition="end"
            value={tab.value}
            label={tab.label}
            icon={
              <Label
                variant={
                  ((tab.value === 'all' || tab.value === filters.status) && 'filled') || 'soft'
                }
                color={tab.color}
              >
                {tab.count}
              </Label>
            }
          />
        ))}
      </Tabs>

      <BookingTableToolbar />

      {canReset && (
        <BookingTableFiltersResult
          filters={filters}
          onFilters={handleFilters}
          //
          canReset={canReset}
          onResetFilters={handleResetFilters}
          //
          results={totalNumberOfSignUps}
          sx={{ p: 2.5, pt: 0 }}
        />
      )}

      <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
        <TableSelectedAction
          dense={dense}
          numSelected={selected.length}
          rowCount={tableData.length}
          onSelectAllRows={() => {}}
          action={
            <Tooltip title={t('Delete')}>
              <IconButton color="primary" onClick={confirm.onTrue}>
                <Iconify icon="solar:trash-bin-trash-bold" />
              </IconButton>
            </Tooltip>
          }
        />

        <Scrollbar>
          <Table size={dense ? 'small' : 'medium'}>
            <TableHeadCustom
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={tableData.length}
              numSelected={selected.length}
              onSort={onSort}
            />

            <TableBody>
              {tableData?.map((row) => (
                <BookingDetailsRow
                  key={row.id}
                  row={row}
                  onDelete={() => handleDeleteRow(row)}
                  onCheckIn={() => handleCheckInRow(row)}
                />
              ))}

              <TableEmptyRows
                height={denseHeight}
                emptyRows={emptyRows(page, rowsPerPage, totalNumberOfSignUps)}
              />

              <TableNoData notFound={notFound} />
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>

      <TablePaginationCustom
        count={totalNumberOfSignUps}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={onChangePage}
        onRowsPerPageChange={onChangeRowsPerPage}
        //
        dense={dense}
        onChangeDense={onChangeDense}
      />
    </Card>
  );
}

// ----------------------------------------------------------------------

type BookingDetailsRowProps = {
  row: SignUpDto;
  onDelete: VoidFunction;
  onCheckIn: VoidFunction;
};

function BookingDetailsRow({ row, onDelete, onCheckIn }: BookingDetailsRowProps) {
  const theme = useTheme();

  const router = useRouter();

  const { t } = useLocales();

  const isLight = theme.palette.mode === 'light';

  const popover = usePopover();

  const handleDelete = () => {
    popover.onClose();
    onDelete();
  };

  const goToClass = () => {
    router.push(paths.classes.view(row.classId!));
  };

  return (
    <>
      <TableRow>
        <TableCell sx={{ display: 'flex', alignItems: 'center' }} onClick={goToClass}>
          <ListItemText
            primary={row.className}
            secondary={fDateTime(row.classBegins)}
            primaryTypographyProps={{ typography: 'body2', noWrap: true }}
            secondaryTypographyProps={{
              mt: 0.5,
              component: 'span',
              typography: 'caption',
            }}
          />
        </TableCell>

        <TableCell>{fDateTime(row.classBegins)}</TableCell>

        <TableCell>{fDateTime(row.signedUpAt)}</TableCell>

        <TableCell>{row.checkedInAt !== undefined && fDateTime(row.checkedInAt)}</TableCell>

        <TableCell>{row.optedOutAt !== undefined && fDateTime(row.optedOutAt)}</TableCell>

        <TableCell>
          <Label
            variant={isLight ? 'soft' : 'filled'}
            color={
              (row.status === ParticipationStatus.OnTime && 'success') ||
              (row.status === ParticipationStatus.SignedUp && 'default') ||
              (row.status === ParticipationStatus.OnWaitingList && 'warning') ||
              'error'
            }
          >
            {t(`${row.status}`)}
          </Label>
        </TableCell>

        <TableCell align="right" sx={{ pr: 1 }}>
          <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 160 }}
      >
        <MenuItem onClick={goToClass}>
          <Iconify icon="solar:eye-bold" />
          {t('View class')}
        </MenuItem>
        {row.status !== ParticipationStatus.OnTime && (
          <MenuItem onClick={onCheckIn}>
            <Iconify icon="material-symbols:order-approve-sharp" />
            {t('Present')}
          </MenuItem>
        )}
        {row.status !== ParticipationStatus.OptedOut && (
          <MenuItem onClick={handleDelete} sx={{ color: 'error.main' }}>
            <Iconify icon="solar:trash-bin-trash-bold" />
            {t('Remove')}
          </MenuItem>
        )}
      </CustomPopover>
    </>
  );
}
