// @mui
import Grid from '@mui/material/Unstable_Grid2';
// types
// components
import { ExerciseResultDto } from '../../api';
import TrainingResults from './training-results';
import { useLocales } from '../../locales';

// ----------------------------------------------------------------------

type Props = {
  exerciseResults: ExerciseResultDto[];
};

export default function ProfileHealth({ exerciseResults }: Props) {
  const { t } = useLocales();

  return (
    <Grid container spacing={5} disableEqualOverflow>
      <Grid xs={12}>
        <TrainingResults
          title={t('Newest exercise results')}
          subheader={t('{{count}} results', { count: exerciseResults.length })}
          list={exerciseResults}
        />
      </Grid>
    </Grid>
  );
}
