import { useCallback, useEffect, useState } from 'react';
// @mui
import Table from '@mui/material/Table';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import IconButton from '@mui/material/IconButton';
import CardHeader from '@mui/material/CardHeader';
import Card, { CardProps } from '@mui/material/Card';
import ListItemText from '@mui/material/ListItemText';
import TableContainer from '@mui/material/TableContainer';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
// components
import { enqueueSnackbar } from 'notistack';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import {
  emptyRows,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  useTable,
} from 'src/components/table';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { AccountDto, Direction, FineDto, FineService, FineStatus } from '../../api';
import { fDateTime } from '../../utils/format-time';
import { useLocales } from '../../locales';
import { useBoolean } from '../../hooks/use-boolean';
import { useDispatch, useSelector } from '../../redux/store';
import { getFines } from '../../redux/slices/members';
import { IMemberRegistrationFilters } from '../../types/member';

interface Props extends CardProps {
  title?: string;
  subheader?: string;
  member: AccountDto;
}

const defaultFilters: IMemberRegistrationFilters = {
  status: 'all',
};

export default function Fines({ title, subheader, member, ...other }: Props) {
  const { t } = useLocales();

  const TABLE_HEAD = [
    { id: 'createdAt', label: t('Class') },
    { id: 'reason', label: t('Reason') },
    { id: 'status', label: t('Status') },
    { id: '' },
  ];

  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    //
    selected,
    //
    onSort,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({
    defaultOrderBy: TABLE_HEAD[0].id,
    defaultOrder: 'desc',
  });

  const confirm = useBoolean();

  const dispatch = useDispatch();

  const tableData = useSelector((state) => state.member.currentMemberFines);

  const totalNumberOfSignUps = useSelector((state) => state.member.currentTotalMemberFines);

  const [filters] = useState(defaultFilters);

  useEffect(() => {
    dispatch(
      getFines({
        pageSize: rowsPerPage,
        pageNumber: page,
        sortBy: [orderBy],
        direction: order === 'asc' ? Direction.Asc : Direction.Desc,
        accountId: member.id!,
        status: filters.status === 'all' ? null : filters.status,
      })
    );
  }, [dispatch, rowsPerPage, page, orderBy, order, member, filters]);

  const notFound = !tableData.length && !!filters.status;

  const handleDeleteRow = useCallback(
    (row: FineDto) => {
      const action = async () => {
        await FineService.delete({
          id: row.id!,
        });

        enqueueSnackbar(t('Removed!'));

        dispatch(
          getFines({
            pageSize: rowsPerPage,
            pageNumber: page,
            sortBy: [orderBy],
            direction: order === 'asc' ? Direction.Asc : Direction.Desc,
            accountId: member.id!,
            status: filters.status === 'all' ? null : filters.status,
          })
        );
      };

      action();
    },
    [dispatch, rowsPerPage, page, orderBy, order, member, filters, t]
  );

  const denseHeight = dense ? 52 : 72;

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} sx={{ mb: 3 }} />

      <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
        <TableSelectedAction
          dense={dense}
          numSelected={selected.length}
          rowCount={tableData.length}
          onSelectAllRows={() => {}}
          action={
            <Tooltip title={t('Delete')}>
              <IconButton color="primary" onClick={confirm.onTrue}>
                <Iconify icon="solar:trash-bin-trash-bold" />
              </IconButton>
            </Tooltip>
          }
        />

        <Scrollbar>
          <Table size={dense ? 'small' : 'medium'}>
            <TableHeadCustom
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={tableData.length}
              numSelected={selected.length}
              onSort={onSort}
            />

            <TableBody>
              {tableData?.map((row) => (
                <FineDetailsRow key={row.id} row={row} onDelete={() => handleDeleteRow(row)} />
              ))}

              <TableEmptyRows
                height={denseHeight}
                emptyRows={emptyRows(page, rowsPerPage, totalNumberOfSignUps)}
              />

              <TableNoData notFound={notFound} />
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>

      <TablePaginationCustom
        count={totalNumberOfSignUps}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={onChangePage}
        onRowsPerPageChange={onChangeRowsPerPage}
      />
    </Card>
  );
}

// ----------------------------------------------------------------------

type BookingDetailsRowProps = {
  row: FineDto;
  onDelete: VoidFunction;
};

function FineDetailsRow({ row, onDelete }: BookingDetailsRowProps) {
  const { t } = useLocales();

  const popover = usePopover();

  const handleDelete = () => {
    popover.onClose();
    onDelete();
  };

  return (
    <>
      <TableRow>
        <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
          <ListItemText
            primary={row.class}
            secondary={fDateTime(row.createdAt)}
            primaryTypographyProps={{ typography: 'body2', noWrap: true }}
            secondaryTypographyProps={{
              mt: 0.5,
              component: 'span',
              typography: 'caption',
            }}
          />
        </TableCell>

        <TableCell>
          <Chip size="small" color="info" label={t(row.reason!)} />
        </TableCell>
        {row.status === FineStatus.Collected && (
          <TableCell>
            <Chip size="small" color="success" label={t(row.status!)} />
          </TableCell>
        )}
        {row.status !== FineStatus.Collected && (
          <TableCell>
            <Chip size="small" label={t(row.status!)} />
          </TableCell>
        )}

        <TableCell align="right" sx={{ pr: 1 }}>
          <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 160 }}
      >
        <MenuItem onClick={handleDelete} sx={{ color: 'error.main' }}>
          <Iconify icon="solar:trash-bin-trash-bold" />
          {t('Remove')}
        </MenuItem>
      </CustomPopover>
    </>
  );
}
