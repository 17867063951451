import { useEffect } from 'react';
import { Box } from '@mui/material';
import Intercom from '@intercom/messenger-js-sdk';
import { useAuthContext } from '../../auth/hooks';
import { useSelector } from '../../redux/store';

export default function SupportChat() {
  const { user } = useAuthContext();
  const chain = useSelector((state) => state.chain.currentChain);

  useEffect(() => {
    Intercom({
      app_id: 'hdbfq0dg',
      user_id: `${user?.id}`,
      name: user?.fullName,
      email: user?.email,
      avatar: user?.profileImageUrl,
      phone: user?.phoneNumber,
      company: chain?.name,
    });
  }, [user, chain]);

  return <Box />;
}
