// @mui
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
// hooks
// utils
import { fCurrency } from 'src/utils/format-number';
// types
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { OrderDto, OrderStatuses } from '../../api';
import { useLocales } from '../../locales';

// ----------------------------------------------------------------------

type Props = {
  row: OrderDto;
  onViewRow: VoidFunction;
};

export default function OrderTableRow({ row, onViewRow }: Props) {
  const {
    status,
    createdDate,
    customerName,
    customerEmailAddress,
    customerProfileImageUrl,
    totalCurrency,
    totalAmount,
    id,
  } = row;

  const { t } = useLocales();

  const popover = usePopover();

  const renderPrimary = (
    <TableRow hover>
      <TableCell>
        <Box
          onClick={onViewRow}
          sx={{
            cursor: 'pointer',
            '&:hover': {
              textDecoration: 'underline',
            },
          }}
        >
          #{id}
        </Box>
      </TableCell>

      <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
        <Avatar alt={customerName} src={customerProfileImageUrl} sx={{ mr: 2 }} />

        <ListItemText
          primary={customerName}
          secondary={customerEmailAddress}
          primaryTypographyProps={{ typography: 'body2' }}
          secondaryTypographyProps={{ component: 'span', color: 'text.disabled' }}
        />
      </TableCell>

      <TableCell>
        <ListItemText
          primary={createdDate!.toFormat('dd MMM yyyy')}
          secondary={createdDate!.toFormat('HH:mm')}
          primaryTypographyProps={{ typography: 'body2', noWrap: true }}
          secondaryTypographyProps={{
            mt: 0.5,
            component: 'span',
            typography: 'caption',
          }}
        />
      </TableCell>

      <TableCell> {fCurrency(totalAmount, totalCurrency)} </TableCell>

      <TableCell>
        <Label
          variant="soft"
          color={
            (status === OrderStatuses.Paid && 'success') ||
            (status === OrderStatuses.Open && 'warning') ||
            (status === OrderStatuses.Cancelled && 'error') ||
            'default'
          }
        >
          {status === OrderStatuses.Cancelled ? t('Order Cancelled') : t(`${status}`)}
        </Label>
      </TableCell>

      <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
        <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
          <Iconify icon="eva:more-vertical-fill" />
        </IconButton>
      </TableCell>
    </TableRow>
  );

  return (
    <>
      {renderPrimary}

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            onViewRow();
            popover.onClose();
          }}
        >
          <Iconify icon="solar:eye-bold" />
          {t('View')}
        </MenuItem>
      </CustomPopover>
    </>
  );
}
