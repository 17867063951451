import * as Yup from 'yup';
import React, { useEffect, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { setError } from 'src/redux/slices/error';

// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import InputAdornment from '@mui/material/InputAdornment';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// routes
import { useRouter } from 'src/routes/hook';
// components
import { DateTime } from 'luxon';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, {
  RHFAutocomplete,
  RHFEditor,
  RHFSelect,
  RHFTextField,
} from 'src/components/hook-form';
import Iconify from '../../components/iconify';
// types
import {
  ClazzDto,
  ExerciseDto,
  ProgramDto,
  ProgramsService,
  WorkoutDto,
  WorkoutTypeDto,
} from '../../api';
import { useLocales } from '../../locales';
import { useDispatch, useSelector } from '../../redux/store';
import { getClasses } from '../../redux/slices/classes';
import { fDateTime } from '../../utils/format-time';
import { possibleReps, units } from '../../types/exercise';
import { getExercises } from '../../redux/slices/exercise';
import { getChainSettings } from '../../redux/slices/chain';

// ----------------------------------------------------------------------

type Props = {
  currentTemplate?: ProgramDto;
  currentClazz?: ClazzDto;
  workoutTypes: WorkoutTypeDto[];
};

export default function ProgramNewEditForm({ currentTemplate, currentClazz, workoutTypes }: Props) {
  const router = useRouter();

  const { t } = useLocales();

  const mdUp = useResponsive('up', 'md');

  const { enqueueSnackbar } = useSnackbar();

  const [searchParams] = useSearchParams();

  const dispatch = useDispatch();

  const classes = useSelector((state) => state.clazz.classes);

  const chainSettings = useSelector((state) => state.chain.currentSettings);

  const exercises = useSelector((state) => state.exercise.exercises);

  const sortedExercises = [...exercises];
  sortedExercises?.sort((a, b) => a.name!.localeCompare(b.name!));

  useEffect(() => {
    dispatch(getExercises());
    dispatch(getChainSettings());
  }, [dispatch]);

  const newSchema = Yup.object().shape({
    coachesNotes: Yup.string().nullable(),
    date: Yup.date().nullable(),
    classIds: Yup.array(),
    workouts: Yup.array().min(1).required(t('Add at least one workout')),
    externalProgrammingTrack: Yup.string(),
  });

  const defaultValues = useMemo(
    () =>
      ({
        coachesNotes: currentTemplate?.coachesNotes || '',
        date:
          (currentTemplate?.date ? currentTemplate?.date : null) ||
          (searchParams.get('date') ? DateTime.fromISO(searchParams.get('date')!) : DateTime.now()),
        classIds:
          currentTemplate?.classes?.map((x) => x.id!) ||
          (currentClazz?.id ? [currentClazz!.id!] : []),
        workouts: currentTemplate?.workouts?.map((w) => ({
          ...w,
          workoutTypeId: w.workoutTypeId ?? -1,
        })) || [
          {
            workoutTypeId: -1,
            text: '',
            timecapInMinutes: '',
            timecapInSeconds: '',
          },
        ],
        externalProgrammingTrack: currentTemplate?.externalProgrammingTrack,
      } as any),
    [currentTemplate, currentClazz, searchParams]
  );

  const methods = useForm({
    resolver: yupResolver(newSchema),
    defaultValues,
  });

  const {
    watch,
    reset,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  useEffect(() => {
    if (currentClazz) {
      reset(defaultValues);
    }

    if (currentTemplate) {
      reset(defaultValues);
    }
  }, [currentTemplate, currentClazz, defaultValues, reset]);

  useEffect(() => {
    dispatch(
      getClasses({
        from: defaultValues.date.startOf('day'),
        to: defaultValues.date.endOf('day'),
        pageNumber: 0,
        pageSize: 2 ** 31 - 1,
      })
    );
  }, [dispatch, defaultValues]);

  const addWorkout = () => {
    const workouts = [
      ...values.workouts,
      {
        workoutTypeId: -1,
        text: '',
        timecapInMinutes: '',
        timecapInSeconds: '',
      },
    ];
    setValue('workouts', workouts, { shouldValidate: true });
  };

  const removeWorkout = (w: WorkoutDto) => {
    const workouts = [...values.workouts.filter((z: WorkoutDto) => z.id !== w.id)];
    setValue('workouts', workouts, { shouldValidate: true });
  };

  const addExercise = (w: WorkoutDto) => {
    if (w.exercises) {
      w.exercises = [
        ...w.exercises!,
        {
          count: '',
          numberOfSets: '',
          unit: '',
          exerciseTypeId: '',
          womensWeight: '',
          mensWeight: '',
          percentage: '',
          relationTo: '',
        } as any,
      ];
    } else {
      w.exercises = [
        {
          count: '',
          numberOfSets: '',
          unit: '',
          exerciseTypeId: '',
          womensWeight: '',
          mensWeight: '',
          percentage: '',
          relationTo: '',
        } as any,
      ];
    }
    setValue('workouts', values.workouts, { shouldValidate: true });
  };

  const removeExercise = (w: WorkoutDto, e: ExerciseDto) => {
    w.exercises = [...w.exercises!.filter((z) => z !== e)];
    setValue('workouts', values.workouts, { shouldValidate: true });
  };

  const onSubmit = handleSubmit(async (data) => {
    try {
      // Check if is edit mode
      if (currentTemplate) {
        await ProgramsService.update({
          programId: currentTemplate!.id!,
          body: { id: currentTemplate!.id!, ...data } as any,
        });
      } else {
        await ProgramsService.create({ body: { ...data } as any });
      }

      reset();
      enqueueSnackbar(currentTemplate ? t('Update success!') : t('Create success!'));
      router.back();
    } catch (error) {
      dispatch(setError(error));
    }
  });

  const handleClassChange = (event: SelectChangeEvent<any[]>) => {
    const result =
      typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value;
    setValue('classIds', result, { shouldValidate: true });
  };

  const renderDetails = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            {t('Details')}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {t('Name, path...')}
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title={t('Details')} />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <Stack spacing={1.5}>
              <Typography variant="subtitle2">{t('Coaches notes')}</Typography>
              <RHFEditor simple value={values.coachesNotes} name="coachesNotes" />
            </Stack>

            <Stack spacing={1.5}>
              <Typography variant="subtitle2">{t('Classes')}</Typography>
              <Controller
                name="classIds"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormControl fullWidth error={!!error}>
                    <Select
                      multiple
                      value={values.classIds}
                      onChange={handleClassChange}
                      renderValue={(selected) =>
                        classes
                          .filter((x) => selected.includes(x.id))
                          .map((x) => x.title)
                          .join(', ')
                      }
                      autoWidth={false}
                    >
                      {classes.map((option) => (
                        <MenuItem key={`desc-${option.id}`} value={option.id}>
                          <Checkbox
                            disableRipple
                            size="small"
                            checked={values.classIds!.includes(option.id)}
                          />
                          {`${option.title} (${fDateTime(option.begins)})`}
                        </MenuItem>
                      ))}
                    </Select>
                    {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
                  </FormControl>
                )}
              />
            </Stack>
            {chainSettings.programAccessKey && (
              <Stack spacing={1.5}>
                <Typography variant="subtitle2">{t('External programming track')}</Typography>
                <RHFTextField name="externalProgrammingTrack" />
              </Stack>
            )}
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderPrograms = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            {t('Workouts')}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {t(
              'Here you can add workouts to the program. Usually you would create a workout for warm-up, skills, WOD, and cool down.'
            )}
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Stack spacing={3}>
          <Card>
            {values?.workouts?.map((w: WorkoutDto, j: number) => (
              <Stack spacing={3} key={`workout-${j}`} sx={{ pt: 3, px: 3 }}>
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{
                    pb: 2,
                  }}
                >
                  <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="subtitle1">
                      {t('Workout #{{number}}', { number: j + 1 })}
                    </Typography>
                  </Box>

                  <Box sx={{ flexShrink: 0 }}>
                    <IconButton color="error" onClick={() => removeWorkout(w)}>
                      <Iconify icon="eva:trash-2-fill" />
                    </IconButton>
                  </Box>
                </Stack>

                <Stack spacing={1.5}>
                  <Typography variant="subtitle2">{t('Name')}</Typography>
                  <RHFTextField name={`workouts[${j}].title`} value={w.title} />
                </Stack>

                <Stack spacing={1.5}>
                  <Typography variant="subtitle2">{t('Workout type')}</Typography>
                  <RHFSelect name={`workouts[${j}].workoutTypeId`} defaultValue={w.workoutTypeId}>
                    <MenuItem key="work-t-1" value={-1}>
                      {t('None')}
                    </MenuItem>
                    {workoutTypes.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.abbreviation}
                      </MenuItem>
                    ))}
                  </RHFSelect>
                </Stack>

                <Stack spacing={1.5}>
                  <Typography variant="subtitle2">{t('Time cap')}</Typography>
                  <Stack direction="row" spacing={1.5}>
                    <RHFTextField
                      name={`workouts[${j}].timecapInMinutes`}
                      value={w.timecapInMinutes}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">Min</InputAdornment>,
                      }}
                    />
                    <RHFTextField
                      name={`workouts[${j}].timecapInSeconds`}
                      value={w.timecapInSeconds}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">Sec</InputAdornment>,
                      }}
                    />
                  </Stack>
                </Stack>

                <Stack spacing={1.5}>
                  <Typography variant="subtitle2">{t('Description')}</Typography>
                  <RHFEditor
                    simple
                    value={w.text}
                    name={`workouts[${j}].text`}
                    key={`workout-desc-${w.id ?? j}`}
                    id={`workout-desc-${w.id ?? j}`}
                  />
                </Stack>
                <Divider />
                {w.exercises?.map((e, k) => (
                  <Stack spacing={3} key={`workout-${j}-exer-${k}`}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={{
                        pb: 2,
                      }}
                    >
                      <Box sx={{ flexGrow: 1 }}>
                        <Typography variant="subtitle1">
                          {t('Exercise #{{number}}', { number: k + 1 })}
                        </Typography>
                      </Box>

                      <Box sx={{ flexShrink: 0 }}>
                        <IconButton color="error" onClick={() => removeExercise(w, e)}>
                          <Iconify icon="eva:trash-2-fill" />
                        </IconButton>
                      </Box>
                    </Stack>
                    <Box
                      gap={3}
                      display="grid"
                      gridTemplateColumns={{ xs: 'repeat(2, 1fr)', md: 'repeat(4, 1fr)' }}
                    >
                      <RHFAutocomplete
                        name={`workouts[${j}].exercises[${k}].exerciseTypeName`}
                        label={t('Exercise')}
                        options={sortedExercises.map((x) => x.name)}
                      />

                      <RHFTextField
                        label={t('Sets')}
                        name={`workouts[${j}].exercises[${k}].numberOfSets`}
                        value={e.numberOfSets}
                      />
                      <RHFTextField
                        label={t('Count')}
                        name={`workouts[${j}].exercises[${k}].count`}
                        value={e.count}
                      />
                      <RHFSelect
                        label={t('Unit')}
                        name={`workouts[${j}].exercises[${k}].unit`}
                        value={e.unit}
                      >
                        {units.map((x) => (
                          <MenuItem key={`workout-${j}-exer-${k}-${x}`} value={x}>
                            {t(x)}
                          </MenuItem>
                        ))}
                      </RHFSelect>

                      {e.unit === 'REPS' && (
                        <>
                          {!(e.percentage || e.relationTo) && (
                            <RHFTextField
                              label={t('Womens weight')}
                              name={`workouts[${j}].exercises[${k}].womensWeight`}
                              value={e.womensWeight}
                            />
                          )}
                          {!(e.percentage || e.relationTo) && (
                            <RHFTextField
                              label={t('Mens weight')}
                              name={`workouts[${j}].exercises[${k}].mensWeight`}
                              value={e.mensWeight}
                            />
                          )}
                          {!(e.mensWeight || e.womensWeight) && (
                            <RHFTextField
                              label={t('Percentage')}
                              name={`workouts[${j}].exercises[${k}].percentage`}
                              value={e.percentage}
                              InputLabelProps={{ shrink: true }}
                              InputProps={{
                                startAdornment: <InputAdornment position="start">%</InputAdornment>,
                              }}
                            />
                          )}
                          {!(e.mensWeight || e.womensWeight) && (
                            <RHFSelect
                              label={t('Relation to')}
                              name={`workouts[${j}].exercises[${k}].relationTo`}
                              value={e.relationTo}
                            >
                              {possibleReps.map((x) => (
                                <MenuItem key={`workout-${j}-exer-${k}-${x}`} value={x}>
                                  {t(x)}
                                </MenuItem>
                              ))}
                            </RHFSelect>
                          )}
                        </>
                      )}
                    </Box>
                  </Stack>
                ))}
                <Button
                  onClick={() => addExercise(w)}
                  variant="outlined"
                  startIcon={<Iconify icon="eva:plus-fill" />}
                >
                  {t('Add exercise')}
                </Button>
              </Stack>
            ))}
            <Divider sx={{ py: 1.5 }} />
            <Stack spacing={1.5} sx={{ p: 3, justifyContent: 'space-between' }} direction="row">
              <Button
                onClick={addWorkout}
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill" />}
              >
                {t('Add workout')}
              </Button>
            </Stack>
          </Card>
        </Stack>
      </Grid>
    </>
  );

  const renderActions = (
    <>
      {mdUp && <Grid md={4} />}
      <Grid xs={12} md={8} sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ flexGrow: 1, pl: 3 }} />

        <LoadingButton
          type="submit"
          variant="contained"
          size="large"
          loading={isSubmitting}
          sx={{ ml: 2 }}
        >
          {!currentTemplate ? `${t('Create')}` : `${t('Save Changes')}`}
        </LoadingButton>
      </Grid>
    </>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        {renderDetails}

        {renderPrograms}

        {renderActions}
      </Grid>
    </FormProvider>
  );
}
