import orderBy from 'lodash/orderBy';
import { useCallback, useEffect, useState } from 'react';
// @mui
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
// routes
import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// utils
import { fTimestamp } from 'src/utils/format-time';
// _mock
import { _tours } from 'src/_mock';
// assets
// components
import { DateTime } from 'luxon';
import Iconify from 'src/components/iconify';
import EmptyContent from 'src/components/empty-content';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
// types
import { ITourFilterValue, ITourItem } from 'src/types/tour';
//
import EventList from '../event-list';
import EventSort from '../event-sort';
import EventSearch from '../event-search';
import EventFilters from '../event-filters';
import EventFiltersResult from '../event-filters-result';
import { useDispatch, useSelector } from '../../../redux/store';
import { getEvents } from '../../../redux/slices/event';
import { Direction, EventDto } from '../../../api';
import { IEventFilters } from '../../../types/event';
import { useLocales } from '../../../locales';

// ----------------------------------------------------------------------

const defaultFilters: IEventFilters = {
  title: '',
  startDate: DateTime.now(),
  endDate: null,
};

// ----------------------------------------------------------------------

export default function EventListView() {
  const pageSize = 9;

  const settings = useSettingsContext();

  const dispatch = useDispatch();

  const openFilters = useBoolean();

  const { t } = useLocales();

  const EVENT_SORT_OPTIONS = [
    { value: 'latest', label: t('Latest') },
    { value: 'oldest', label: t('Oldest') },
  ];

  const [sortBy, setSortBy] = useState('latest');

  const [search, setSearch] = useState<{ query: string; results: ITourItem[] }>({
    query: '',
    results: [],
  });

  const [filters, setFilters] = useState(defaultFilters);

  const dateError =
    filters.startDate && filters.endDate ? filters.startDate > filters.endDate : false;

  const events = useSelector((state) => state.event.events);

  const totalEvents = useSelector((state) => state.event.totalEvents);

  useEffect(() => {
    dispatch(
      getEvents({
        pageSize,
        from: filters.startDate,
        to: filters.endDate,
        sortBy: ['begins'],
        direction: sortBy === 'latest' ? Direction.Desc : Direction.Asc,
      })
    );
  }, [dispatch, filters, sortBy]);

  const dataFiltered = applyFilter({
    inputData: events,
    filters,
    sortBy,
    dateError,
  });

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const storedSortBy = queryParams.get('sortBy');
    const storedSearch = queryParams.get('search');
    const storedStartDate = queryParams.get('startDate')
      ? decodeURIComponent(queryParams.get('startDate')!)
      : null;
    const storedEndDate = queryParams.get('endDate')
      ? decodeURIComponent(queryParams.get('endDate')!)
      : null;

    setSortBy(storedSortBy ?? '');
    setSearch({ query: storedSearch ?? '', results: [] });
    setFilters({
      title: '',
      startDate:
        storedStartDate !== null
          ? DateTime.fromISO(storedStartDate, {
              zone: 'local',
            })
          : DateTime.now(),
      endDate:
        storedEndDate !== null
          ? DateTime.fromISO(storedEndDate, {
              zone: 'local',
            })
          : null,
    });
  }, [setSortBy]);

  useEffect(() => {
    const newurl = `${window.location.protocol}//${window.location.host}${
      window.location.pathname
    }?sortBy=${sortBy}&search=${search.query ?? ''}&startDate=${
      filters.startDate
        ? encodeURIComponent(filters.startDate.toISO({ includeOffset: false })!)
        : ''
    }&endDate=${
      filters.endDate ? encodeURIComponent(filters.endDate.toISO({ includeOffset: false })!) : ''
    }`;
    window.history.replaceState({ path: newurl }, '', newurl);
  }, [filters, sortBy, search]);

  const canReset = !!filters.title.length || (!!filters.startDate && !!filters.endDate);

  const notFound = !dataFiltered.length && canReset;

  const handlePageChange = useCallback(
    (value: number) => {
      dispatch(
        getEvents({
          pageNumber: value - 1,
          pageSize,
          from: filters.startDate,
          to: filters.endDate,
          sortBy: ['begins'],
          direction: sortBy === 'latest' ? Direction.Desc : Direction.Asc,
        })
      );
    },
    [dispatch, filters, sortBy]
  );

  const handleFilters = useCallback((name: string, value: ITourFilterValue) => {
    setFilters((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  const handleSortBy = useCallback((newValue: string) => {
    setSortBy(newValue);
  }, []);

  const handleSearch = useCallback(
    (inputValue: string) => {
      setSearch((prevState) => ({
        ...prevState,
        query: inputValue,
      }));

      if (inputValue) {
        const results = _tours.filter(
          (tour) => tour.name.toLowerCase().indexOf(search.query.toLowerCase()) !== -1
        );

        setSearch((prevState) => ({
          ...prevState,
          results,
        }));
      }
    },
    [search.query]
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  const handleDelete = useCallback(() => {
    dispatch(getEvents());
  }, [dispatch]);

  const renderFilters = (
    <Stack
      spacing={3}
      justifyContent="space-between"
      alignItems={{ xs: 'flex-end', sm: 'center' }}
      direction={{ xs: 'column', sm: 'row' }}
    >
      <EventSearch
        query={search.query}
        results={search.results}
        onSearch={handleSearch}
        hrefItem={(id: string) => paths.dashboard.tour.details(id)}
      />

      <Stack direction="row" spacing={1} flexShrink={0}>
        <EventFilters
          open={openFilters.value}
          onOpen={openFilters.onTrue}
          onClose={openFilters.onFalse}
          //
          filters={filters}
          onFilters={handleFilters}
          //
          canReset={canReset}
          onResetFilters={handleResetFilters}
          //
          dateError={dateError}
        />

        <EventSort sort={sortBy} onSort={handleSortBy} sortOptions={EVENT_SORT_OPTIONS} />
      </Stack>
    </Stack>
  );

  const renderResults = (
    <EventFiltersResult
      filters={filters}
      onResetFilters={handleResetFilters}
      //
      canReset={canReset}
      onFilters={handleFilters}
      //
      results={dataFiltered.length}
    />
  );

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <CustomBreadcrumbs
        heading="List"
        links={[
          { name: `${t('Dashboard')}`, href: paths.dashboard.root },
          {
            name: `${t('Event')}`,
            href: paths.events.root,
          },
          { name: `${t('List')}` },
        ]}
        action={
          <Button
            component={RouterLink}
            href={paths.events.new}
            variant="contained"
            startIcon={<Iconify icon="mingcute:add-line" />}
          >
            {t('New event')}
          </Button>
        }
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <Stack
        spacing={2.5}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      >
        {renderFilters}

        {canReset && renderResults}
      </Stack>

      {notFound && <EmptyContent title={t('No Data')} filled sx={{ py: 10 }} />}

      <EventList
        events={dataFiltered}
        totalEvents={totalEvents}
        pageSize={pageSize}
        handlePageChange={handlePageChange}
        onDelete={handleDelete}
      />
    </Container>
  );
}

// ----------------------------------------------------------------------

const applyFilter = ({
  inputData,
  filters,
  sortBy,
  dateError,
}: {
  inputData: EventDto[];
  filters: IEventFilters;
  sortBy: string;
  dateError: boolean;
}) => {
  const { title, startDate, endDate } = filters;

  // SORT BY
  if (sortBy === 'latest') {
    inputData = orderBy(inputData, ['createdAt'], ['desc']);
  }

  if (sortBy === 'oldest') {
    inputData = orderBy(inputData, ['createdAt'], ['asc']);
  }

  // FILTERS
  if (!dateError) {
    if (startDate && endDate) {
      inputData = inputData.filter(
        (event) =>
          fTimestamp(event.begins) >= fTimestamp(startDate) &&
          fTimestamp(event.ends) <= fTimestamp(endDate)
      );
    }
  }

  if (title.length) {
    inputData = inputData.filter((event) => title.includes(event.title ?? ''));
  }

  return inputData;
};
