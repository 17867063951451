import React, { useCallback, useEffect, useMemo, useState } from 'react';
// @mui
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Container from '@mui/material/Container';
// routes
import { paths } from 'src/routes/paths';
import { useParams } from 'src/routes/hook';
// _mock
import { TOUR_PUBLISH_OPTIONS } from 'src/_mock';
// components
import Label from 'src/components/label';
import { useSettingsContext } from 'src/components/settings';
import { saveAs } from 'file-saver';
//
import EventDetailsToolbar from '../event-details-toolbar';
import EventDetailsContent from '../event-details-content';
import EventDetailsTickets from '../event-details-tickets';
import { useLocales } from '../../../locales';
import { useDispatch, useSelector } from '../../../redux/store';
import { getEvent, getParticipants } from '../../../redux/slices/event';
import { getConfigs, serviceOptions } from '../../../api';

// ----------------------------------------------------------------------

export default function EventDetailsView() {
  const settings = useSettingsContext();

  const params = useParams();

  const { t } = useLocales();

  const dispatch = useDispatch();

  const { id } = params;

  const currentEvent = useSelector((state) => state.event.currentEvent);

  const currentEventParticipants = useSelector((state) => state.event.currentEventParticipants);

  useEffect(() => {
    dispatch(
      getEvent({
        id: parseInt(id!, 10),
      })
    );
    dispatch(
      getParticipants({
        id: parseInt(id!, 10),
      })
    );
  }, [dispatch, id]);

  const defaultValues = useMemo(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const storedCurrentTab = queryParams.get('currentTab');

    return {
      storedCurrentTab: storedCurrentTab ?? 'content',
    };
  }, []);

  const [currentTab, setCurrentTab] = useState(defaultValues.storedCurrentTab);

  useEffect(() => {
    const newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?currentTab=${currentTab}`;
    window.history.replaceState({ path: newurl }, '', newurl);
  }, [currentTab]);

  const EVENT_DETAILS_TABS = [
    { value: 'content', label: `${t('Overview')}` },
    { value: 'participants', label: `${t('Participants')}` },
  ];

  const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue);
  }, []);

  const handleDelete = () => {
    dispatch(
      getParticipants({
        id: parseInt(id!, 10),
      })
    );
  };

  const handleExport = useCallback(() => {
    const action = async () => {
      const url = `/api/events/${id}/tickets`;

      const configs = getConfigs('get', 'application/json', url, {
        headers: {
          Accept: 'text/csv',
        },
      });

      configs.params = {};
      const resp = await serviceOptions.axios!.request(configs);

      // Ignore type, because API can return multiple types
      const blob = new Blob([resp.data as any], {
        type: 'text/csv',
      });
      saveAs(blob, `result.csv`);
    };
    action();
  }, [id]);

  const renderTabs = (
    <Tabs
      value={currentTab}
      onChange={handleChangeTab}
      sx={{
        mb: { xs: 3, md: 5 },
      }}
    >
      {EVENT_DETAILS_TABS.map((tab) => (
        <Tab
          key={tab.value}
          iconPosition="end"
          value={tab.value}
          label={tab.label}
          icon={
            tab.value === 'participants' ? (
              <Label variant="filled">{currentEventParticipants?.length}</Label>
            ) : (
              ''
            )
          }
        />
      ))}
    </Tabs>
  );

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <EventDetailsToolbar
        backLink={paths.events.root}
        editLink={paths.events.edit(currentEvent?.id ?? -1)}
        liveLink="#"
        publishOptions={TOUR_PUBLISH_OPTIONS}
        event={currentEvent}
        onExport={handleExport}
      />
      {renderTabs}

      {currentTab === 'content' && <EventDetailsContent event={currentEvent} />}

      {currentTab === 'participants' && (
        <EventDetailsTickets tickets={currentEventParticipants} onDelete={handleDelete} />
      )}
    </Container>
  );
}
