import React from 'react';
// @mui
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { useTheme } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Unstable_Grid2';
// types
// components
import { enqueueSnackbar } from 'notistack';
import Iconify from 'src/components/iconify';
import { ClazzDto, ParticipationStatus, SignUpDto, SignUpsService } from '../../api';
import { useLocales } from '../../locales';
import CustomPopover, { usePopover } from '../../components/custom-popover';
import { getClass } from '../../redux/slices/classes';
import { useDispatch } from '../../redux/store';
import AddParticipantForm from './add-participant-form';
import { useBoolean } from '../../hooks/use-boolean';
import { useRouter } from '../../routes/hook';
import { paths } from '../../routes/paths';
import CheckInMap from './check-in-map';

// ----------------------------------------------------------------------

type Props = {
  participants: SignUpDto[];
  clazz: ClazzDto;
};

export default function ClassParticipants({ participants, clazz }: Props) {
  const { t } = useLocales();

  const dispatch = useDispatch();

  const form = useBoolean();

  const theme = useTheme();

  const handleRemove = async (p: SignUpDto) => {
    await SignUpsService.edit({
      id: p.id!,
      body: {
        id: p.id!,
        status: ParticipationStatus.OptedOut,
      },
    });

    enqueueSnackbar(t('Sign up removed!'));

    dispatch(getClass(p.classId!));
  };

  const handlePresent = async (p: SignUpDto) => {
    await SignUpsService.edit({
      id: p.id!,
      body: {
        id: p.id!,
        status: ParticipationStatus.OnTime,
      },
    });

    enqueueSnackbar(t('Member is checked in!'));

    dispatch(getClass(p.classId!));
  };

  const handleCreate = async () => {
    dispatch(getClass(clazz.id!));
  };

  const filteredParticipants = participants.filter(
    (x) =>
      x.status !== ParticipationStatus.OptedOut && x.status !== ParticipationStatus.OptedOutTooLate
  );

  return (
    <>
      <Grid container spacing={3}>
        <Grid xs={12} md={8}>
          <Stack spacing={3}>
            <Stack direction="row" alignItems="center">
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" gutterBottom>
                  {t('Participants')}
                </Typography>
              </Box>

              <Box sx={{ flexShrink: 0 }}>
                <Button
                  variant="contained"
                  onClick={form.onTrue}
                  startIcon={<Iconify icon="eva:plus-fill" />}
                >
                  {t('Add')}
                </Button>
              </Box>
            </Stack>

            <Box
              gap={3}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
                md: 'repeat(3, 1fr)',
              }}
            >
              {filteredParticipants.map((p) => (
                <ParticipantItem
                  key={p.id}
                  participant={p}
                  onPresentRow={() => handlePresent(p)}
                  onDeleteRow={() => handleRemove(p)}
                />
              ))}
            </Box>
          </Stack>
        </Grid>

        <Grid xs={12} md={4}>
          {clazz.gym && (
            <Stack spacing={3}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" gutterBottom>
                  {t('Check in map')}
                </Typography>
              </Box>

              <CheckInMap participants={clazz.participants ?? []} gym={clazz.gym} />
            </Stack>
          )}
        </Grid>
      </Grid>

      <Dialog
        fullWidth
        maxWidth="xs"
        open={form.value}
        onClose={form.onFalse}
        transitionDuration={{
          enter: theme.transitions.duration.shortest,
          exit: theme.transitions.duration.shortest - 80,
        }}
      >
        <DialogTitle sx={{ minHeight: 76 }}>{t('Add participant')}</DialogTitle>

        <AddParticipantForm
          currentClazz={clazz}
          onClose={() => {
            handleCreate();
            form.onFalse();
          }}
        />
      </Dialog>
    </>
  );
}

// ----------------------------------------------------------------------

type ParticipantItemProps = {
  participant: SignUpDto;
  onPresentRow: VoidFunction;
  onDeleteRow: VoidFunction;
};

function ParticipantItem({ participant, onPresentRow, onDeleteRow }: ParticipantItemProps) {
  const { name, accountEmailAddress, profileImageUrl, status } = participant;

  const { t } = useLocales();

  const popover = usePopover();

  const router = useRouter();

  return (
    <>
      <Card
        sx={{
          p: (th) => th.spacing(2, 2, 2, 2),
        }}
      >
        <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
          <Avatar
            alt={name}
            src={profileImageUrl}
            sx={[
              {
                '&:hover': {
                  cursor: 'pointer',
                },
              },
              { width: 48, height: 48, mr: 2 },
            ]}
            onClick={() => router.push(paths.members.view(participant.accountId!))}
          />

          <ListItemText
            primary={name}
            secondary={accountEmailAddress}
            primaryTypographyProps={{
              noWrap: true,
              typography: 'subtitle2',
            }}
            secondaryTypographyProps={{
              mt: 0.5,
              noWrap: true,
              display: 'flex',
              component: 'span',
              alignItems: 'center',
              typography: 'caption',
              color: 'text.disabled',
            }}
            onClick={() => router.push(paths.members.view(participant.accountId!))}
            sx={[
              {
                '&:hover': {
                  cursor: 'pointer',
                },
              },
            ]}
          />
          <IconButton
            color={popover.open ? 'inherit' : 'default'}
            onClick={popover.onOpen}
            sx={{
              top: 8,
              right: 8,
              position: 'absolute',
            }}
          >
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </Stack>
        <Stack direction="row" spacing={0.5}>
          {status === ParticipationStatus.NeverMet && (
            <Chip
              color="error"
              label={t(`${status}`)}
              sx={{
                mt: 1.5,
              }}
            />
          )}

          {status === ParticipationStatus.OnWaitingList && (
            <Chip
              color="warning"
              label={t(`${status}`)}
              sx={{
                mt: 1.5,
              }}
            />
          )}

          {status === ParticipationStatus.TooLate && (
            <Chip
              color="warning"
              label={t(`${status}`)}
              sx={{
                mt: 1.5,
              }}
            />
          )}

          {status === ParticipationStatus.OnTime && (
            <Chip
              color="success"
              label={t(`${status}`)}
              sx={{
                mt: 1.5,
              }}
            />
          )}

          {status !== ParticipationStatus.NeverMet &&
            status !== ParticipationStatus.TooLate &&
            status !== ParticipationStatus.OnWaitingList &&
            status !== ParticipationStatus.OnTime && (
              <Chip
                label={t(`${status}`)}
                sx={{
                  mt: 1.5,
                }}
              />
            )}
          {participant.isGuest && (
            <Chip
              color="info"
              label={t(`Guest`)}
              sx={{
                mt: 1.5,
              }}
              icon={<Iconify icon="fluent:guest-16-filled" />}
            />
          )}
        </Stack>
      </Card>
      <CustomPopover open={popover.open} onClose={popover.onClose} arrow="top-right">
        {status !== ParticipationStatus.OnTime && (
          <MenuItem
            onClick={() => {
              onPresentRow();
              popover.onClose();
            }}
          >
            <Iconify icon="material-symbols:order-approve-sharp" />
            {t('Present')}
          </MenuItem>
        )}

        {status !== ParticipationStatus.OptedOut && (
          <MenuItem
            onClick={() => {
              onDeleteRow();
              popover.onClose();
            }}
            sx={{ color: 'error.main' }}
          >
            <Iconify icon="solar:trash-bin-trash-bold" />
            {t('Remove')}
          </MenuItem>
        )}
      </CustomPopover>
    </>
  );
}
