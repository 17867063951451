// @mui
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
// components
import { DateTime } from 'luxon';
import { saveAs } from 'file-saver';
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { useLocales } from '../../locales';
// types
import { BookkeepingService } from '../../api';
import { IHourBankTableFilters, IHourBankTableFilterValue } from '../../types/hour-bank';

// ----------------------------------------------------------------------

type Props = {
  filters: IHourBankTableFilters;
  onFilters: (name: string, value: IHourBankTableFilterValue) => void;
};

export default function HourBankTableToolbar({ filters, onFilters }: Props) {
  const popover = usePopover();

  const { t } = useLocales();

  const handlePrintPage: VoidFunction = () => window.print();

  const downloadForPeriod = async () => {
    const result = await BookkeepingService.export({
      fromDate: filters.fromDate ?? DateTime.now(),
      toDate: filters.toDate ?? DateTime.now(),
    });

    const blob = new Blob([result], {
      type: 'text/csv',
    });
    saveAs(
      blob,
      `ledger_${filters.fromDate!.toFormat('yyyy-MM-dd')}__${filters.toDate!.toFormat(
        'yyyy-MM-dd'
      )}.csv`
    );
  };

  return (
    <>
      <Stack
        spacing={2}
        alignItems={{ xs: 'flex-end', md: 'center' }}
        direction={{
          xs: 'column',
          md: 'row',
        }}
        sx={{
          p: 2.5,
          pr: { xs: 2.5, md: 1 },
        }}
      >
        <Stack direction="row" alignItems="center" spacing={2} flexGrow={1} sx={{ width: 1 }}>
          <IconButton onClick={popover.onOpen}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </Stack>
      </Stack>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            popover.onClose();
            handlePrintPage();
          }}
        >
          <Iconify icon="solar:printer-minimalistic-bold" />
          {t('Print')}
        </MenuItem>

        <MenuItem
          onClick={() => {
            popover.onClose();
            downloadForPeriod();
          }}
        >
          <Iconify icon="solar:export-bold" />
          {t('Export')}
        </MenuItem>
      </CustomPopover>
    </>
  );
}
