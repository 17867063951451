// @mui
import Grid from '@mui/material/Unstable_Grid2';
// types
// components
import { EmployeeDto } from '../../api';
import ClazzDetails from './clazz-details';
import { useLocales } from '../../locales';

// ----------------------------------------------------------------------

type Props = {
  employee: EmployeeDto;
};

export default function ProfileClazz({ employee }: Props) {
  const { t } = useLocales();

  return (
    <Grid container spacing={5} disableEqualOverflow>
      <Grid xs={12}>
        <ClazzDetails title={t('Classes')} member={employee} />
      </Grid>
    </Grid>
  );
}
