import * as Yup from 'yup';
import { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { setError } from 'src/redux/slices/error';

// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import InputAdornment from '@mui/material/InputAdornment';
// routes
import { paths } from 'src/routes/paths';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// _mock
// components
import { DateTime } from 'luxon';
import { useSnackbar } from 'src/components/snackbar';
import { useRouter } from 'src/routes/hook';
import FormProvider, { RHFMultiSelect, RHFSwitch, RHFTextField } from 'src/components/hook-form';
// types
import { DiscountVoucherDto, DiscountVoucherService } from '../../api';
import { useLocales } from '../../locales';
import { useDispatch, useSelector } from '../../redux/store';
import { getChainSettings } from '../../redux/slices/chain';
import { fCurrencySymbol } from '../../utils/format-number';
import {
  getCampaigns,
  getContingents,
  getPersonalTrainings,
  getPunchCards,
} from '../../redux/slices/membership-type';
import { getGyms } from '../../redux/slices/gym';

// ----------------------------------------------------------------------

type Props = {
  voucher?: DiscountVoucherDto;
};

export default function ProductNewEditForm({ voucher }: Props) {
  const router = useRouter();

  const mdUp = useResponsive('up', 'md');

  const { t } = useLocales();

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const settings = useSelector((state) => state.chain.currentSettings);

  const globalGym = useSelector((state) => state.gym.globalGym);

  const gyms = useSelector((state) => state.gym.gyms);

  const campaigns = useSelector((state) => state.membershipType.campaigns);
  const contingents = useSelector((state) => state.membershipType.contingents);
  const punchCards = useSelector((state) => state.membershipType.punchCards);
  const personalTrainings = useSelector((state) => state.membershipType.personalTrainings);

  const membershipTypes = [...campaigns, ...contingents, ...punchCards, ...personalTrainings];

  useEffect(() => {
    dispatch(getChainSettings());
    dispatch(
      getCampaigns({
        pageSize: 2 ** 31 - 1,
        pageNumber: 0,
        validOn: new Date(),
      })
    );
    dispatch(
      getContingents({
        pageSize: 2 ** 31 - 1,
        pageNumber: 0,
        validOn: new Date(),
      })
    );
    dispatch(
      getPersonalTrainings({
        pageSize: 2 ** 31 - 1,
        pageNumber: 0,
        validOn: new Date(),
      })
    );
    dispatch(
      getPunchCards({
        pageSize: 2 ** 31 - 1,
        pageNumber: 0,
        isActive: true,
        validOn: new Date(),
      })
    );
    dispatch(
      getGyms({
        pageSize: 2 ** 31 - 1,
        pageNumber: 0,
      })
    );
  }, [dispatch]);

  const newSchema = Yup.object().shape({
    name: Yup.string().required(t('Name is required')),
    code: Yup.string().required(t('Code is required')),
    clips: Yup.number().min(1, t('Clips is required')),
    discountAmount: Yup.number(),
    discountCurrency: Yup.string(),
    discountPercentage: Yup.number(),
    validFrom: Yup.mixed<any>()
      .required(t('Valid from is required'))
      .test('is-set', t('Valid from is required'), (value, { parent }) => value),
    validTo: Yup.mixed<any>()
      .nullable()
      .test(
        'date-min',
        t('Valid to must be later than valid from'),
        (value, { parent }) => !value || value > new Date(parent.validFrom).getTime()
      ),
    isOneTimeUse: Yup.boolean(),
    availableInGyms: Yup.array().min(voucher ? 0 : 1, t('Please select at least one gym')),
    availableForMembershipTypes: Yup.array().min(
      1,
      t('Please select at least one membership type')
    ),
  });

  const defaultValues = useMemo(
    () => ({
      name: voucher?.name || '',
      code: voucher?.code || '',
      clips: voucher?.clips || 0,
      discountAmount: voucher?.discountAmount || 0,
      discountCurrency: settings?.defaultCurrency || 'EUR',
      discountPercentage: voucher?.discountPercentage || 0,
      validFrom: voucher?.validFrom || DateTime.now(),
      validTo: voucher?.validTo || null,
      isOneTimeUse: voucher?.isOneTimeUse ?? true,
      gymId: voucher?.gymId || null,
      availableInGyms: [`${globalGym.id}`],
      availableForMembershipTypes: voucher?.availableForMembershipTypes?.length
        ? voucher?.availableForMembershipTypes?.map((e) => `${e}`)
        : [],
    }),
    [voucher, settings, globalGym]
  );

  const methods = useForm({
    resolver: yupResolver(newSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  useEffect(() => {
    if (voucher) {
      reset(defaultValues);
    }
    if (settings) {
      reset(defaultValues);
    }
    if (globalGym) {
      reset(defaultValues);
    }
  }, [voucher, defaultValues, reset, settings, globalGym]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      // Check if is edit mode
      if (voucher) {
        await DiscountVoucherService.update({
          id: voucher!.id!,
          body: { id: voucher!.id!, ...data },
        });
      } else {
        await DiscountVoucherService.create({ body: { ...data } });
      }

      reset();
      enqueueSnackbar(voucher ? t('Update success!') : t('Create success!'));
      router.push(paths.vouchers.root);
    } catch (error) {
      dispatch(setError(error));
    }
  });

  const renderProperties = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            {t('Properties')}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {t('Additional functions and attributes...')}
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title={t('Properties')} />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <Box
              columnGap={2}
              rowGap={3}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                md: 'repeat(2, 1fr)',
              }}
            >
              <RHFTextField name="clips" label={t('Number of seats')} type="number" />

              <RHFSwitch name="isOneTimeUse" label={t('Single-use')} />
            </Box>

            {!voucher && (
              <RHFMultiSelect
                name="availableInGyms"
                label={t('Available in gyms')}
                options={gyms.map((e) => ({ value: `${e.id}`, label: e.name! }))}
                checkbox
              />
            )}

            {(values.availableInGyms?.length ?? 0) <= 1 && (
              <RHFMultiSelect
                name="availableForMembershipTypes"
                label={t('Available for membership types')}
                options={membershipTypes.map((e) => ({ value: `${e.id}`, label: e.name! }))}
                checkbox
              />
            )}
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderPricing = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            {t('Discount')}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {t('What the discount is in either amount or percentage.')}
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title={t('Discount')} />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <RHFTextField
              name="discountAmount"
              label={t('Discount amount')}
              placeholder="0.00"
              type="number"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Box component="span" sx={{ color: 'text.disabled' }}>
                      {fCurrencySymbol(defaultValues.discountCurrency)}
                    </Box>
                  </InputAdornment>
                ),
              }}
            />

            <RHFTextField
              name="discountPercentage"
              label={t('Discount percentage')}
              type="number"
            />
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderValidity = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            {t('Validity')}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {t('How long is the voucher valid.')}
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title={t('Validity')} />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <Box
              columnGap={2}
              rowGap={3}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                md: 'repeat(2, 1fr)',
              }}
            >
              <Controller
                name="validFrom"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <DatePicker
                    {...field}
                    label={t('Valid from')}
                    value={field.value}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: !!error,
                        helperText: error?.message,
                      },
                    }}
                  />
                )}
              />

              <Controller
                name="validTo"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <DatePicker
                    {...field}
                    label={t('Valid to')}
                    value={field.value}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: !!error,
                        helperText: error?.message,
                      },
                    }}
                  />
                )}
              />
            </Box>
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderDetails = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            {t('Details')}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {t('Name and code.')}
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title={t('Details')} />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <RHFTextField name="name" label={t('Name')} />

            <RHFTextField name="code" label={t('Code')} />
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderActions = (
    <>
      {mdUp && <Grid md={4} />}
      <Grid xs={12} md={8} sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ flexGrow: 1, pl: 3 }} />

        <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
          {!voucher ? t('Create') : t('Save Changes')}
        </LoadingButton>
      </Grid>
    </>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        {renderDetails}

        {renderProperties}

        {renderPricing}

        {renderValidity}

        {renderActions}
      </Grid>
    </FormProvider>
  );
}
