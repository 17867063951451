import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'src/redux/store';
// @mui
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import {
  Chip,
  DialogContentText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  timelineOppositeContentClasses,
  TimelineSeparator,
} from '@mui/lab';
// components
import { DateTime } from 'luxon';
import { useLocales } from '../../../locales';
import { Audit, MembershipDto } from '../../../api';
import { getMembershipAuditLog } from '../../../redux/slices/members';

// ----------------------------------------------------------------------

type Props = {
  open: boolean;
  onClose: VoidFunction;
  membership?: MembershipDto;
};

export default function MembershipAuditLog({ open, onClose, membership }: Props) {
  const { t } = useLocales();

  const dispatch = useDispatch();

  const auditLog = useSelector((state) => state.member.membershipAuditLog);

  useEffect(() => {
    dispatch(getMembershipAuditLog({ id: membership?.id! }));
  }, [membership, dispatch]);

  const sortedAuditLog = useMemo(
    () => [...auditLog].sort((a, b) => b.dateTime!.toMillis() - a.dateTime!.toMillis()!),
    [auditLog]
  );

  const changedValues = (entry: Audit) => {
    const { affectedColumns } = entry;

    const newValues = Object.values(entry.newValues!);
    const oldValues = Object.values(entry.oldValues!);

    return (affectedColumns as any)!.map((affectedColumn: any, index: any) => {
      const oldValue = oldValues![index];
      const newValue = newValues![index];
      return {
        affectedColumn,
        oldValue,
        newValue,
      };
    });
  };

  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={onClose} scroll="paper">
      <DialogTitle>{t('Audit log')}</DialogTitle>

      <DialogContent dividers>
        <DialogContentText>
          {t(
            'Shows the audit log for this entity for the last 6 months. Entries older than 6 months are automatically deleted.'
          )}
        </DialogContentText>
      </DialogContent>
      <DialogContent dividers>
        <Timeline
          sx={{
            [`& .${timelineOppositeContentClasses.root}`]: {
              flex: 0.2,
            },
          }}
        >
          {sortedAuditLog.map((el) => (
            <TimelineItem key={el.dateTime?.toString()}>
              <TimelineOppositeContent color="text.secondary">
                {el.dateTime?.toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)} <br />
                <Chip
                  label={el.type}
                  size="small"
                  sx={{
                    mt: 1,
                  }}
                />
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <TableContainer component={Paper}>
                  <Table aria-label="simple table" size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>{t('Affected column')}</TableCell>
                        <TableCell align="right">{t('Old')}</TableCell>
                        <TableCell align="right">{t('New')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {changedValues(el).map((row: any) => (
                        <TableRow
                          key={row.affectedColumn}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component="th" scope="row">
                            {row.affectedColumn}
                          </TableCell>
                          <TableCell align="right">{row.oldValue?.toString()}</TableCell>
                          <TableCell align="right">{row.newValue?.toString()}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </DialogContent>
    </Dialog>
  );
}
