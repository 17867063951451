import { useCallback } from 'react';
// @mui
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from '@mui/material';
// components
import { DateTime } from 'luxon';
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import {
  CampaignMembershipTypeDto,
  ContingentTypeDto,
  DropInTypeDto,
  PersonalTrainingTypeDto,
  VoucherTypeDto,
} from 'src/api';
import { useLocales } from '../../../locales';
import { IReportFilterValue, IReportTableFilters } from '../../../types/report';

// ----------------------------------------------------------------------

type Props = {
  filters: IReportTableFilters;
  onFilters: (name: string, value: IReportFilterValue) => void;
  onExport: VoidFunction;
  campaigns: CampaignMembershipTypeDto[];
  dropIns: DropInTypeDto[];
  contingents: ContingentTypeDto[];
  punchCards: VoucherTypeDto[];
  personalTrainings: PersonalTrainingTypeDto[];
};

export default function MemberChangeReportToolbar({
  filters,
  onFilters,
  onExport,
  campaigns,
  contingents,
  punchCards,
  personalTrainings,
  dropIns,
}: Props) {
  const popover = usePopover();

  const { t } = useLocales();

  const handleFilterValidFrom = useCallback(
    (newValue: DateTime | null) => {
      onFilters('fromDate', newValue);
    },
    [onFilters]
  );

  const handleFilterValidTo = useCallback(
    (newValue: DateTime | null) => {
      onFilters('toDate', newValue);
    },
    [onFilters]
  );

  const handleFilterMinimumLengthOfMembershipInDays = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onFilters('minimumLengthOfMembershipInDays', event.target.value);
    },
    [onFilters]
  );

  const handleFilterMembershipTypes = useCallback(
    (event: SelectChangeEvent<number[] | null>) => {
      onFilters('membershipTypeIds', event.target.value);
    },
    [onFilters]
  );

  return (
    <>
      <Stack
        spacing={2}
        alignItems={{ xs: 'flex-end', md: 'center' }}
        direction={{
          xs: 'column',
          md: 'row',
        }}
        sx={{
          p: 2.5,
          pr: { xs: 2.5, md: 1 },
        }}
      >
        <Stack direction="row" alignItems="center" spacing={2} flexGrow={1} sx={{ width: 1 }}>
          <FormControl fullWidth>
            <DatePicker
              label={t('From date')}
              value={filters.fromDate}
              slotProps={{
                textField: {
                  fullWidth: true,
                },
              }}
              onChange={handleFilterValidFrom}
            />
          </FormControl>

          <FormControl fullWidth>
            <DatePicker
              label={t('To date')}
              value={filters.toDate}
              slotProps={{
                textField: {
                  fullWidth: true,
                },
              }}
              onChange={handleFilterValidTo}
            />
          </FormControl>

          <FormControl fullWidth variant="outlined">
            <InputLabel>{t('Membership minimum length')}</InputLabel>

            <OutlinedInput
              label={t('Membership minimum length')}
              value={filters.minimumLengthOfMembershipInDays}
              onChange={handleFilterMinimumLengthOfMembershipInDays}
              endAdornment={<InputAdornment position="end">{t('Days')}</InputAdornment>}
            />
          </FormControl>

          <FormControl fullWidth>
            <InputLabel>{t('Membership types')}</InputLabel>
            <Select
              label={t('Membership types')}
              value={filters.membershipTypeIds}
              onChange={handleFilterMembershipTypes}
              autoWidth={false}
              multiple
            >
              <MenuItem divider disabled>
                {t('Campaigns')}
              </MenuItem>
              {campaigns.map((option) => (
                <MenuItem
                  key={`membership-type-${option.id}`}
                  value={option.id}
                  sx={{
                    justifyContent: 'space-between',
                  }}
                >
                  {option.name}
                </MenuItem>
              ))}
              <MenuItem divider disabled>
                {t('Contingents')}
              </MenuItem>
              {contingents.map((option) => (
                <MenuItem
                  key={`membership-type-${option.id}`}
                  value={option.id}
                  sx={{
                    justifyContent: 'space-between',
                  }}
                >
                  {option.name}
                </MenuItem>
              ))}
              <MenuItem divider disabled>
                {t('Personal training')}
              </MenuItem>
              {personalTrainings.map((option) => (
                <MenuItem
                  key={`membership-type-${option.id}`}
                  value={option.id}
                  sx={{
                    justifyContent: 'space-between',
                  }}
                >
                  {option.name}
                </MenuItem>
              ))}
              <MenuItem divider disabled>
                {t('Punch cards')}
              </MenuItem>
              {punchCards.map((option) => (
                <MenuItem
                  key={`membership-type-${option.id}`}
                  value={option.id}
                  sx={{
                    justifyContent: 'space-between',
                  }}
                >
                  {option.name}
                </MenuItem>
              ))}
              <MenuItem divider disabled>
                {t('Drop In')}
              </MenuItem>
              {dropIns.map((option) => (
                <MenuItem
                  key={`membership-type-${option.id}`}
                  value={option.id}
                  sx={{
                    justifyContent: 'space-between',
                  }}
                >
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <IconButton onClick={popover.onOpen}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </Stack>
      </Stack>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            popover.onClose();
            onExport();
          }}
        >
          <Iconify icon="solar:export-bold" />
          {t('Export')}
        </MenuItem>
      </CustomPopover>
    </>
  );
}
