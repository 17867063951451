/* eslint-disable react/no-danger */
import { useCallback } from 'react';
// @mui
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
// types
// hooks
// utils
import { fDate } from 'src/utils/format-time';
// components
import Iconify from 'src/components/iconify';
import { enqueueSnackbar } from 'notistack';
import { AccountDto, CommentDto, CommentService } from '../../api';
import { getMemberComments, getMemberEvents } from '../../redux/slices/members';
import { useDispatch } from '../../redux/store';
import { useLocales } from '../../locales';

// ----------------------------------------------------------------------

interface Props {
  member: AccountDto;
  comment: CommentDto;
}

export default function ProfileCommentItem({ comment, member }: Props) {
  const { t } = useLocales();

  const dispatch = useDispatch();

  const handleDeleteComment = useCallback(async () => {
    await CommentService.remove({
      id: comment.id!,
    });
    dispatch(getMemberComments(member.id!));
    dispatch(getMemberEvents(member.id!));

    enqueueSnackbar(t('Comment removed!'));
  }, [dispatch, comment, member, t]);

  const renderAvatar = () => (
    <Avatar src={comment?.creatorProfileImage} alt={comment?.creatorName} />
  );

  const renderHead = (
    <CardHeader
      disableTypography
      avatar={renderAvatar()}
      title={comment?.creatorName}
      subheader={
        <Box sx={{ color: 'text.disabled', typography: 'caption', mt: 0.5 }}>
          {fDate(comment.createdDate)}
        </Box>
      }
      action={
        <IconButton color="error" onClick={handleDeleteComment}>
          <Iconify icon="eva:trash-2-outline" />
        </IconButton>
      }
    />
  );

  return (
    <Card>
      {renderHead}

      <Typography
        variant="body2"
        sx={{
          p: (theme) => theme.spacing(3, 3, 2, 3),
        }}
      >
        <div dangerouslySetInnerHTML={{ __html: comment.body! }} />
      </Typography>
    </Card>
  );
}
