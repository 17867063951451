import React, { useCallback, useEffect, useMemo, useState } from 'react';
// @mui
import Card from '@mui/material/Card';
import Grid from '@mui/material/Unstable_Grid2';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import CardHeader from '@mui/material/CardHeader';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// assets
//
import PaymentCardListDialog from '../payment/payment-card-list-dialog';
import { AccountDto, MembershipDto, MembershipStatuses, PaymentMethodDto } from '../../api';
import { useLocales } from '../../locales';
import Iconify from '../../components/iconify';
import { MembershipNewForm } from './membership';
import { useDispatch, useSelector } from '../../redux/store';
import {
  getCampaigns,
  getContingents,
  getDropIns,
  getPersonalTrainings,
  getPunchCards,
} from '../../redux/slices/membership-type';
import { getChainSettings } from '../../redux/slices/chain';
import MembershipItem from './membership/membership-item';
import Label from '../../components/label';

// ----------------------------------------------------------------------

type Props = {
  cardList: PaymentMethodDto[];
  plans: MembershipDto[];
  member: AccountDto;
  handleAddNewMembership: VoidFunction;
};

export default function AccountMemberships({
  cardList,
  plans,
  member,
  handleAddNewMembership,
}: Props) {
  const { t } = useLocales();

  const dispatch = useDispatch();

  const openCards = useBoolean();

  const primaryCard = cardList.find(() => true);

  const [selectedCard, setSelectedCard] = useState<PaymentMethodDto | undefined>(primaryCard);

  const campaigns = useSelector((state) => state.membershipType.campaigns);
  const contingents = useSelector((state) => state.membershipType.contingents);
  const punchCards = useSelector((state) => state.membershipType.punchCards);
  const dropIns = useSelector((state) => state.membershipType.dropIns);
  const personalTrainings = useSelector((state) => state.membershipType.personalTrainings);

  useEffect(() => {
    dispatch(
      getCampaigns({
        pageSize: 2 ** 31 - 1,
        pageNumber: 0,
        validOn: new Date(),
        sortBy: ['name'],
      })
    );
    dispatch(
      getContingents({
        pageSize: 2 ** 31 - 1,
        pageNumber: 0,
        validOn: new Date(),
        sortBy: ['name'],
      })
    );
    dispatch(
      getDropIns({
        pageSize: 2 ** 31 - 1,
        pageNumber: 0,
        validOn: new Date(),
        sortBy: ['name'],
      })
    );
    dispatch(
      getPersonalTrainings({
        pageSize: 2 ** 31 - 1,
        pageNumber: 0,
        validOn: new Date(),
        sortBy: ['name'],
      })
    );
    dispatch(
      getPunchCards({
        pageSize: 2 ** 31 - 1,
        pageNumber: 0,
        isActive: true,
        validOn: new Date(),
        sortBy: ['name'],
      })
    );
    dispatch(getChainSettings());
  }, [dispatch]);

  const addMembershipForm = useBoolean();

  const handleChangeTab = useCallback(
    (event: React.SyntheticEvent, newValue: MembershipStatuses) => {
      setCurrentTab(newValue);
    },
    []
  );

  const activePlans = [...plans].filter((x) => x.status === MembershipStatuses.Active);
  const inactivePlans = [...plans].filter((x) => x.status === MembershipStatuses.InActive);
  const onHoldPlans = [...plans].filter((x) => x.status === MembershipStatuses.OnHold);
  const missingPaymentPlans = [...plans].filter(
    (x) => x.status === MembershipStatuses.MissingPayment
  );
  const cancelledPlans = [...plans].filter((x) => x.status === MembershipStatuses.Cancelled);

  const whichTab = useMemo(() => {
    if (missingPaymentPlans.length) {
      return MembershipStatuses.MissingPayment;
    }
    if (activePlans.length) {
      return MembershipStatuses.Active;
    }
    if (onHoldPlans.length) {
      return MembershipStatuses.OnHold;
    }
    if (cancelledPlans.length) {
      return MembershipStatuses.Cancelled;
    }
    return MembershipStatuses.InActive;
  }, [missingPaymentPlans, activePlans, onHoldPlans, cancelledPlans]);

  const [currentTab, setCurrentTab] = useState(whichTab);

  const tabs = [
    {
      value: MembershipStatuses.InActive,
      label: t(MembershipStatuses.InActive),
      count: inactivePlans.length,
      color: 'default',
    },
    {
      value: MembershipStatuses.Active,
      label: t(MembershipStatuses.Active),
      count: activePlans.length,
      color: 'success',
    },
    {
      value: MembershipStatuses.OnHold,
      label: t(MembershipStatuses.OnHold),
      count: onHoldPlans.length,
      color: 'info',
    },
    {
      value: MembershipStatuses.Cancelled,
      label: t(MembershipStatuses.Cancelled),
      count: cancelledPlans.length,
      color: 'warning',
    },
  ] as const;

  const handleSelectCard = useCallback((newValue: PaymentMethodDto | undefined) => {
    setSelectedCard(newValue);
  }, []);

  const selectedPlans = [...plans]
    .filter((x) => x.status === currentTab)
    .map((plan) => <MembershipItem plan={plan} key={`membership-${plan.id}`} />);

  const renderPlans = [...selectedPlans];

  return (
    <>
      <Card>
        <CardHeader
          title={t('Memberships')}
          action={
            <Button
              size="small"
              color="primary"
              startIcon={<Iconify icon="mingcute:add-line" />}
              onClick={addMembershipForm.onTrue}
            >
              {t('Add')}
            </Button>
          }
        />
        <Tabs
          value={currentTab}
          onChange={handleChangeTab}
          sx={{
            pt: 2,
            px: 3,
          }}
        >
          {tabs.map((tab) => (
            <Tab
              key={tab.value}
              value={tab.value}
              label={tab.label}
              iconPosition="end"
              icon={
                <Label variant={(tab.value === currentTab && 'filled') || 'soft'} color={tab.color}>
                  {tab.count}
                </Label>
              }
            />
          ))}
        </Tabs>
        <Divider />
        <Grid container spacing={2} sx={{ p: 3 }}>
          {renderPlans}
        </Grid>
      </Card>

      <PaymentCardListDialog
        list={cardList}
        open={openCards.value}
        onClose={openCards.onFalse}
        selected={(selectedId: string) => selectedCard?.maskedCard === selectedId}
        onSelect={handleSelectCard}
        member={member}
      />

      <MembershipNewForm
        open={addMembershipForm.value}
        onClose={addMembershipForm.onFalse}
        onCreate={handleAddNewMembership}
        member={member}
        dropIns={dropIns}
        punchCards={punchCards}
        contingents={contingents}
        campaigns={campaigns}
        personalTrainings={personalTrainings}
      />
    </>
  );
}
