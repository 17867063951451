import * as Yup from 'yup';
import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'src/redux/store';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// components
import { useSnackbar } from 'src/components/snackbar';
import { useRouter } from 'src/routes/hook';
// types
import { GymDto, ImportService } from '../../api';
import { useLocales } from '../../locales';
import FormProvider, { RHFUpload } from '../../components/hook-form';
import { setError } from '../../redux/slices/error';

// ----------------------------------------------------------------------

type Props = {
  currentGym?: GymDto;
};

export default function GymSetup({ currentGym }: Props) {
  const router = useRouter();

  const mdUp = useResponsive('up', 'md');

  const { t } = useLocales();

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const newSchema = Yup.object().shape({
    members: Yup.array().min(0, t('Members is required')).nullable(),
    memberships: Yup.array().min(0, t('Memberships is required')).nullable(),
    paymentMethods: Yup.array().min(0, t('Payment methods is required')).nullable(),
  });

  const defaultValues = useMemo(
    () => ({
      members: [],
      memberships: [],
      paymentMethods: [],
    }),
    []
  );

  const methods = useForm({
    resolver: yupResolver(newSchema),
    defaultValues,
  });

  const {
    reset,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const handleMemberDrop = useCallback(
    (acceptedFiles: File[]) => {
      const files = acceptedFiles.map((file) =>
        Object.assign(file, { preview: URL.createObjectURL(file) })
      );

      setValue('members', files, { shouldValidate: true });
    },
    [setValue]
  );

  const handleMemberRemoveFile = useCallback(() => {
    setValue('members', null);
  }, [setValue]);

  const handleMembershipDrop = useCallback(
    (acceptedFiles: File[]) => {
      const files = acceptedFiles.map((file) =>
        Object.assign(file, { preview: URL.createObjectURL(file) })
      );

      setValue('memberships', files, { shouldValidate: true });
    },
    [setValue]
  );

  const handleMembershipRemoveFile = useCallback(() => {
    setValue('memberships', null);
  }, [setValue]);

  const handlePaymentMethodDrop = useCallback(
    (acceptedFiles: File[]) => {
      const files = acceptedFiles.map((file) =>
        Object.assign(file, { preview: URL.createObjectURL(file) })
      );

      setValue('paymentMethods', files, { shouldValidate: true });
    },
    [setValue]
  );

  const handlePaymentMethodRemoveFile = useCallback(() => {
    setValue('paymentMethods', null);
  }, [setValue]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      if (data.members?.length) {
        await ImportService.importMembers({
          gymId: currentGym!.id!,
          memberFiles: data.members ?? [],
        });
      }

      if (data.memberships?.length) {
        await ImportService.importMemberships({
          gymId: currentGym!.id!,
          membershipFiles: data.memberships ?? [],
        });
      }

      if (data.paymentMethods?.length) {
        await ImportService.importPaymentMethods({
          gymId: currentGym!.id!,
          paymentMethodFiles: data.paymentMethods ?? [],
        });
      }

      reset();
      enqueueSnackbar(t('Import started!'));
      router.back();
    } catch (error) {
      dispatch(setError(error));
    }
  });

  const renderDetails = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            {t('Import')}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {t(
              'Here you can make an import of members, memberships, payment methods etc.. If you already have a functioning system do not use this as it will overwrite existing data.'
            )}
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title={t('Details')} />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <Stack spacing={1.5}>
              <Typography variant="subtitle2">{t('Members')}</Typography>
              <RHFUpload
                multiple
                name="members"
                maxSize={1024 * 1024 * 5}
                onDrop={handleMemberDrop}
                onDelete={handleMemberRemoveFile}
                accept={{
                  'text/csv': [],
                }}
              />
            </Stack>
            <Stack spacing={1.5}>
              <Typography variant="subtitle2">{t('Memberships')}</Typography>
              <RHFUpload
                multiple
                name="memberships"
                maxSize={1024 * 1024 * 5}
                onDrop={handleMembershipDrop}
                onDelete={handleMembershipRemoveFile}
                accept={{
                  'text/csv': [],
                }}
              />
            </Stack>
            <Stack spacing={1.5}>
              <Typography variant="subtitle2">{t('Payment methods')}</Typography>
              <RHFUpload
                multiple
                name="paymentMethods"
                maxSize={1024 * 1024 * 5}
                onDrop={handlePaymentMethodDrop}
                onDelete={handlePaymentMethodRemoveFile}
                accept={{
                  'text/csv': [],
                }}
              />
            </Stack>
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderActions = (
    <>
      {mdUp && <Grid md={4} />}
      <Grid xs={12} md={8} sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ flexGrow: 1, pl: 3 }} />

        <LoadingButton
          type="submit"
          variant="contained"
          size="large"
          loading={isSubmitting}
          sx={{ ml: 2 }}
        >
          {t('Import')}
        </LoadingButton>
      </Grid>
    </>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        <Grid xs={12}>
          <Alert severity="warning">
            {t(
              'If you already have a functioning system do not use this as it will overwrite existing data.'
            )}
          </Alert>
        </Grid>

        {renderDetails}

        {renderActions}
      </Grid>
    </FormProvider>
  );
}
