import { useMemo } from 'react';
// @mui
import Box from '@mui/material/Box';
import Paper, { PaperProps } from '@mui/material/Paper';
import Stack, { StackProps } from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Unstable_Grid2';
// types
import { DateTime } from 'luxon';
// components
import { useAuthContext } from 'src/auth/hooks';
import { enqueueSnackbar } from 'notistack';
import { MembershipDto, MembershipStatuses } from '../../../api';
import { PlanFreeIcon, PlanPremiumIcon, PlanStarterIcon } from '../../../assets/icons';
import { fCurrency } from '../../../utils/format-number';
import Iconify from '../../../components/iconify';
import CustomPopover, { usePopover } from '../../../components/custom-popover';
import { useLocales } from '../../../locales';
import { useBoolean } from '../../../hooks/use-boolean';
import { getMemberships } from '../../../redux/slices/members';
import { useDispatch } from '../../../redux/store';
import MembershipPauseForm from './membership-pause-form';
import MembershipCancelForm from './membership-cancel-form';
import MembershipEditForm from './membership-edit-form';
import { paths } from '../../../routes/paths';
import { useRouter } from '../../../routes/hook';
import MembershipCancelOnHoldForm from './membership-cancel-on-hold-form';
import MembershipAuditLog from './membership-audit-log';

// ----------------------------------------------------------------------

type Props = PaperProps &
  StackProps & {
    plan: MembershipDto;
  };

export default function MembershipItem({ plan, sx }: Props) {
  const popover = usePopover();

  const { t } = useLocales();

  const router = useRouter();

  const dispatch = useDispatch();

  const editMembershipForm = useBoolean();

  const pauseMembershipForm = useBoolean();

  const cancelMembershipForm = useBoolean();

  const cancelMembershipOnHoldForm = useBoolean();

  const showAuditLog = useBoolean();

  const { user } = useAuthContext();

  const isDropIn = plan?.typeOfMembership === 'DropIn';
  const isPunchCard = plan?.typeOfMembership === 'Voucher';
  const isContingent = plan?.typeOfMembership === 'Contingent';
  const isCampaign = plan?.typeOfMembership === 'Campaign';
  const isPersonalTraining = plan?.typeOfMembership === 'PersonalTrainingMembership';

  const handleEdit = (membership: MembershipDto) => {
    popover.onClose();
    editMembershipForm.onTrue();
  };

  const handlePause = (membership: MembershipDto) => {
    popover.onClose();
    pauseMembershipForm.onTrue();
  };

  const handleCancel = (membership: MembershipDto) => {
    popover.onClose();
    cancelMembershipForm.onTrue();
  };

  const handleResume = (membership: MembershipDto) => {
    popover.onClose();
    cancelMembershipOnHoldForm.onTrue();
  };

  const handleEditNewMembership = () => {
    editMembershipForm.onFalse();

    dispatch(
      getMemberships({
        id: plan.ownerId!,
        statuses: [
          MembershipStatuses.Active,
          MembershipStatuses.InActive,
          MembershipStatuses.OnHold,
          MembershipStatuses.Cancelled,
          MembershipStatuses.MissingPayment,
        ],
      })
    );

    enqueueSnackbar(t('Update success!'));
  };

  const handleCancelMembership = () => {
    cancelMembershipForm.onFalse();

    dispatch(
      getMemberships({
        id: plan.ownerId!,
        statuses: [
          MembershipStatuses.Active,
          MembershipStatuses.InActive,
          MembershipStatuses.OnHold,
          MembershipStatuses.Cancelled,
          MembershipStatuses.MissingPayment,
        ],
      })
    );
    enqueueSnackbar(t('Membership cancelled!'));
  };

  const handlePauseMembership = () => {
    pauseMembershipForm.onFalse();

    dispatch(
      getMemberships({
        id: plan.ownerId!,
        statuses: [
          MembershipStatuses.Active,
          MembershipStatuses.InActive,
          MembershipStatuses.OnHold,
          MembershipStatuses.Cancelled,
          MembershipStatuses.MissingPayment,
        ],
      })
    );
    enqueueSnackbar(t('Membership on hold!'));
  };

  const handleResumeMembership = () => {
    cancelMembershipOnHoldForm.onFalse();

    dispatch(
      getMemberships({
        id: plan.ownerId!,
        statuses: [
          MembershipStatuses.Active,
          MembershipStatuses.InActive,
          MembershipStatuses.OnHold,
          MembershipStatuses.Cancelled,
          MembershipStatuses.MissingPayment,
        ],
      })
    );
    enqueueSnackbar(t('Membership resumed!'));
  };

  const now = useMemo(() => DateTime.now(), []);

  const isOnHold = useMemo(
    () =>
      plan.status === MembershipStatuses.OnHold &&
      plan.validTo !== undefined &&
      plan.validTo <= now,
    [plan, now]
  );

  const isOnHoldButActiveStill = useMemo(
    () =>
      plan.status === MembershipStatuses.OnHold && plan.validTo !== undefined && now < plan.validTo,
    [plan, now]
  );

  const isCancelledButActiveStill = useMemo(
    () =>
      (plan.status === MembershipStatuses.Active || plan.status === MembershipStatuses.Cancelled) &&
      plan.validTo !== undefined &&
      now < plan.validTo &&
      plan?.typeOfMembership !== 'Voucher',
    [plan, now]
  );

  const isActive = useMemo(
    () =>
      plan.status === MembershipStatuses.Active &&
      (plan.validTo === undefined || plan?.typeOfMembership === 'Voucher'),
    [plan]
  );

  const hasMissingPayment = useMemo(
    () => plan.status === MembershipStatuses.MissingPayment,
    [plan]
  );

  const isCancelled = useMemo(
    () =>
      plan.status === MembershipStatuses.Cancelled &&
      plan.validTo !== undefined &&
      plan.validTo < now,
    [plan, now]
  );

  const isInactive = useMemo(() => plan.status === MembershipStatuses.InActive, [plan]);

  const goToMembershipType = (membership: MembershipDto) => {
    if (isContingent) {
      router.push(paths.contingentType.details(membership.membershipTypeId ?? -1));
    } else if (isCampaign) {
      router.push(paths.campaignType.details(membership.membershipTypeId ?? -1));
    } else if (isPunchCard) {
      router.push(paths.punchCardType.details(membership.membershipTypeId ?? -1));
    } else if (isDropIn) {
      router.push(paths.dropInType.details(membership.membershipTypeId ?? -1));
    } else {
      router.push(paths.personalTrainingMembershipType.details(membership.membershipTypeId ?? -1));
    }
  };

  const isOwner = useMemo(
    () => user?.roles!.filter((x: string) => x === 'Owner').length > 0,
    [user]
  );

  return (
    <>
      <Grid xs={12} md={6} key={`membership-row-${plan.id}`}>
        <Stack
          component={Paper}
          variant="outlined"
          sx={{
            p: 2.5,
            position: 'relative',
            cursor: 'pointer',
          }}
        >
          <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
            {plan.priceAmount !== undefined && plan.priceAmount > 500 && (
              <Box sx={{ width: 48, height: 48 }}>
                <PlanPremiumIcon />
              </Box>
            )}

            {plan.priceAmount !== undefined && plan.priceAmount > 0 && plan.priceAmount <= 500 && (
              <Box sx={{ width: 48, height: 48 }}>
                <PlanStarterIcon />
              </Box>
            )}

            {(plan.priceAmount === undefined || plan.priceAmount <= 0) && (
              <Box sx={{ width: 48, height: 48 }}>
                <PlanFreeIcon />
              </Box>
            )}
          </Stack>
          <Box sx={{ typography: 'subtitle2', mt: 2, mb: 0.5, textTransform: 'capitalize' }}>
            {plan.name}{' '}
            {plan.numberOfClips !== undefined && (
              <Chip size="small" label={`${plan.remainingNumberOfClips}/${plan.numberOfClips}`} />
            )}
          </Box>

          <Stack direction="row" alignItems="center" sx={{ typography: 'h4' }}>
            {plan.priceAmount !== undefined &&
              plan.priceAmount > 0 &&
              fCurrency(plan.priceAmount, plan.priceCurrency)}

            {(plan.priceAmount === undefined || plan.priceAmount <= 0) && t('Free')}

            {!!plan.priceAmount && (
              <Box component="span" sx={{ typography: 'body2', color: 'text.disabled', ml: 0.5 }}>
                /{t(`${plan.billable}`)}
              </Box>
            )}
          </Stack>

          {isOnHoldButActiveStill && (
            <Chip
              color="info"
              label={t(`Active ({{status}})`, {
                status: t(`${plan.status}`),
              })}
              sx={{ mt: 2.5 }}
            />
          )}

          {isOnHold && <Chip color="info" label={t(`${plan.status}`)} sx={{ mt: 2.5 }} />}

          {isCancelledButActiveStill && (
            <Chip
              color="info"
              label={t(`Active ({{status}})`, {
                status: t(`${MembershipStatuses.Cancelled}`),
              })}
              sx={{ mt: 2.5 }}
            />
          )}

          {isActive && <Chip color="success" label={t(`${plan.status}`)} sx={{ mt: 2.5 }} />}

          {hasMissingPayment && <Chip color="error" label={t(`${plan.status}`)} sx={{ mt: 2.5 }} />}

          {isCancelled && <Chip color="warning" label={t(`${plan.status}`)} sx={{ mt: 2.5 }} />}

          {isInactive && <Chip label={t(`${plan.status}`)} sx={{ mt: 2.5 }} />}

          <IconButton
            onClick={popover.onOpen}
            sx={{
              top: 8,
              right: 8,
              position: 'absolute',
            }}
          >
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </Stack>
        <CustomPopover open={popover.open} onClose={popover.onClose}>
          <MenuItem onClick={() => handleEdit(plan)}>
            <Iconify icon="solar:pen-bold" />
            {t('Edit')}
          </MenuItem>

          {isActive && (isContingent || isPersonalTraining) && !isOnHold && (
            <MenuItem onClick={() => handlePause(plan)}>
              <Iconify icon="solar:pause-linear" />
              {t('Membership on hold')}
            </MenuItem>
          )}

          {isOnHold && (
            <MenuItem onClick={() => handleResume(plan)}>
              <Iconify icon="solar:play-bold" />
              {t('Resume membership')}
            </MenuItem>
          )}

          <MenuItem onClick={() => goToMembershipType(plan)}>
            <Iconify icon="material-symbols:card-membership" />
            {t('Go to membership type')}
          </MenuItem>

          {isOwner && (
            <MenuItem onClick={() => showAuditLog.onTrue()}>
              <Iconify icon="hugeicons:audit-02" />
              {t('Audit log')}
            </MenuItem>
          )}

          {!isCancelled && (
            <MenuItem onClick={() => handleCancel(plan)} sx={{ color: 'error.main' }}>
              <Iconify icon="solar:trash-bin-trash-bold" />
              {t('Cancel membership')}
            </MenuItem>
          )}
        </CustomPopover>
      </Grid>

      <MembershipEditForm
        open={editMembershipForm.value}
        onClose={() => editMembershipForm.onFalse()}
        onComplete={handleEditNewMembership}
        membership={plan}
      />

      <MembershipCancelForm
        open={cancelMembershipForm.value}
        onClose={() => cancelMembershipForm.onFalse()}
        onComplete={handleCancelMembership}
        membership={plan}
      />

      <MembershipCancelOnHoldForm
        open={cancelMembershipOnHoldForm.value}
        onClose={() => cancelMembershipOnHoldForm.onFalse()}
        onComplete={handleResumeMembership}
        membership={plan}
      />

      <MembershipPauseForm
        open={pauseMembershipForm.value}
        onClose={() => pauseMembershipForm.onFalse()}
        onComplete={handlePauseMembership}
        membership={plan}
      />

      <MembershipAuditLog
        open={showAuditLog.value}
        onClose={() => showAuditLog.onFalse()}
        membership={plan}
      />
    </>
  );
}
