// @mui
import Link from '@mui/material/Link';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
// hooks
// components
import Iconify from 'src/components/iconify';
import { usePopover } from 'src/components/custom-popover';
// utils
import { MembershipTypePriceDto } from '../../../api';
import { fCurrency } from '../../../utils/format-number';
import { useLocales } from '../../../locales';

// ----------------------------------------------------------------------

type Props = {
  row: MembershipTypePriceDto;
  onEditRow: VoidFunction;
};

export default function PersonalTrainingPricingTableRow({ row, onEditRow }: Props) {
  const {
    priceAmount,
    priceCurrency,
    validFrom,
    validTo,
    taxRate,
    taxRateInclusive,
    updatePriceOnExistingMemberships,
  } = row;

  const { t } = useLocales();

  const popover = usePopover();

  return (
    <TableRow hover>
      <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
        <ListItemText
          disableTypography
          primary={
            <Link noWrap color="inherit" variant="subtitle2" sx={{ cursor: 'pointer' }}>
              {fCurrency(priceAmount, priceCurrency)}
            </Link>
          }
        />
      </TableCell>

      <TableCell>
        <ListItemText
          primary={validFrom!.toFormat('dd MMM yyyy')}
          secondary={validFrom!.toFormat('HH:mm')}
          primaryTypographyProps={{ typography: 'body2', noWrap: true }}
          secondaryTypographyProps={{
            mt: 0.5,
            component: 'span',
            typography: 'caption',
          }}
        />
      </TableCell>
      <TableCell>
        {validTo ? (
          <ListItemText
            primary={validTo!.toFormat('dd MMM yyyy')}
            secondary={validTo!.toFormat('HH:mm')}
            primaryTypographyProps={{ typography: 'body2', noWrap: true }}
            secondaryTypographyProps={{
              mt: 0.5,
              component: 'span',
              typography: 'caption',
            }}
          />
        ) : (
          '-'
        )}
      </TableCell>

      <TableCell>
        {taxRate ? `${taxRateInclusive ? t('Incl.') : t('Excl.')} ${taxRate}%` : '-'}
      </TableCell>

      <TableCell>{updatePriceOnExistingMemberships ? t('Yes') : t('No')}</TableCell>

      <TableCell align="right">
        <IconButton color={popover.open ? 'primary' : 'default'} onClick={popover.onOpen}>
          <Iconify icon="eva:more-vertical-fill" />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
