// @mui
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
// hooks
// components
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
// types
import { MembershipDto, MembershipStatuses } from '../../api';
//
import { useLocales } from '../../locales';
import Label from '../../components/label';
import { paths } from '../../routes/paths';
import { useRouter } from '../../routes/hook';
import { fCurrency } from '../../utils/format-number';

// ----------------------------------------------------------------------

type Props = {
  row: MembershipDto;
  onSelectRow: VoidFunction;
};

export default function MembershipTableRow({ row, onSelectRow }: Props) {
  const router = useRouter();

  const {
    ownerId,
    ownerName,
    name,
    renewalDate,
    billable,
    ownerProfileImageUrl,
    ownerEmail,
    status,
    validFrom,
    validTo,
    priceCurrency,
    priceAmount,
  } = row;

  const { t } = useLocales();

  const popover = usePopover();

  return (
    <>
      <TableRow hover>
        <TableCell
          sx={[
            {
              '&:hover': {
                cursor: 'pointer',
              },
            },
            { display: 'flex', alignItems: 'center' },
          ]}
          onClick={() => router.push(paths.members.view(ownerId!))}
        >
          <Avatar alt={ownerName} src={ownerProfileImageUrl} sx={{ mr: 2 }} />

          <ListItemText
            primary={ownerName}
            secondary={ownerEmail}
            primaryTypographyProps={{ typography: 'body2' }}
            secondaryTypographyProps={{ component: 'span', color: 'text.disabled' }}
          />
        </TableCell>

        <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
          {name}
        </TableCell>
        <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
          <TableCell>{fCurrency(priceAmount, priceCurrency)}</TableCell>
        </TableCell>
        <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
          {t(`${billable}`)}
        </TableCell>

        <TableCell>
          {renewalDate ? (
            <ListItemText
              primary={renewalDate!.toFormat('dd MMM yyyy')}
              secondary={renewalDate!.toFormat('HH:mm')}
              primaryTypographyProps={{ typography: 'body2', noWrap: true }}
              secondaryTypographyProps={{
                mt: 0.5,
                component: 'span',
                typography: 'caption',
              }}
            />
          ) : (
            '-'
          )}
        </TableCell>
        <TableCell>
          {validFrom ? (
            <ListItemText
              primary={validFrom!.toFormat('dd MMM yyyy')}
              secondary={validFrom!.toFormat('HH:mm')}
              primaryTypographyProps={{ typography: 'body2', noWrap: true }}
              secondaryTypographyProps={{
                mt: 0.5,
                component: 'span',
                typography: 'caption',
              }}
            />
          ) : (
            '-'
          )}
        </TableCell>
        <TableCell>
          {validTo ? (
            <ListItemText
              primary={validTo!.toFormat('dd MMM yyyy')}
              secondary={validTo!.toFormat('HH:mm')}
              primaryTypographyProps={{ typography: 'body2', noWrap: true }}
              secondaryTypographyProps={{
                mt: 0.5,
                component: 'span',
                typography: 'caption',
              }}
            />
          ) : (
            '-'
          )}
        </TableCell>

        <TableCell>
          <Label
            variant="soft"
            color={
              (status === MembershipStatuses.Active && 'success') ||
              (status === MembershipStatuses.OnHold && 'info') ||
              (status === MembershipStatuses.MissingPayment && 'error') ||
              (status === MembershipStatuses.Cancelled && 'warning') ||
              'default'
            }
          >
            {t(`${status}`)}
          </Label>
        </TableCell>

        <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
          <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <CustomPopover open={popover.open} onClose={popover.onClose} arrow="right-top">
        <MenuItem
          onClick={() => {
            onSelectRow();
            popover.onClose();
          }}
        >
          <Iconify icon="solar:eye-bold" />
          {t('View')}
        </MenuItem>
      </CustomPopover>
    </>
  );
}
