import { useEffect } from 'react';
// @mui
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
// routes
import { paths } from 'src/routes/paths';
// components
import { RouterLink } from 'src/routes/components';
import { useSettingsContext } from 'src/components/settings';
//
import { useParams } from 'react-router-dom';
import { useLocales } from '../../../locales';
import { useDispatch, useSelector } from '../../../redux/store';
import { getGym } from '../../../redux/slices/gym';
import Iconify from '../../../components/iconify';
import GymSetup from '../gym-setup';

// ----------------------------------------------------------------------

export default function GymSetupView() {
  const settings = useSettingsContext();

  const { t } = useLocales();

  const dispatch = useDispatch();

  const params = useParams();

  const { id = '' } = params;

  useEffect(() => {
    dispatch(
      getGym({
        id: parseInt(id, 10),
      })
    );
  }, [dispatch, id]);

  const currentGym = useSelector((state) => state.gym.currentGym);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <Stack
        spacing={1.5}
        direction="row"
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      >
        <Button
          component={RouterLink}
          href={paths.gyms.view(currentGym?.id ?? -1)}
          startIcon={<Iconify icon="eva:arrow-ios-back-fill" width={16} />}
        >
          {t('Back')}
        </Button>

        <Box sx={{ flexGrow: 1 }} />
      </Stack>

      <GymSetup currentGym={currentGym} />
    </Container>
  );
}
