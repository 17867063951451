// @mui
import Table from '@mui/material/Table';
import Tooltip from '@mui/material/Tooltip';
import TableBody from '@mui/material/TableBody';
import IconButton from '@mui/material/IconButton';
import TableContainer from '@mui/material/TableContainer';
// routes
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import {
  emptyRows,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  useTable,
} from 'src/components/table';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
//
import { useLocales } from '../../../locales';
import { MembershipTypePriceDto } from '../../../api';
import ContingentPricingTableRow from './contingent-pricing-table-row';

// ----------------------------------------------------------------------

type Props = {
  prices: MembershipTypePriceDto[];
};

export default function ContingentDetailsPricing({ prices }: Props) {
  const { t } = useLocales();

  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    //
    selected,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const TABLE_HEAD = [
    { id: 'price', label: t('Price') },
    { id: 'validFrom', label: t('Valid from'), width: 160 },
    { id: 'validTo', label: t('Valid to'), width: 160 },
    { id: 'vat', label: t('VAT') },
    { id: 'priceIncrease', label: t('Price increase'), width: 160 },
    { id: '', width: 88 },
  ];

  const confirm = useBoolean();

  const denseHeight = dense ? 60 : 80;

  const notFound = !prices.length;

  return (
    <>
      <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
        <TableSelectedAction
          dense={dense}
          numSelected={selected.length}
          rowCount={prices.length}
          onSelectAllRows={(checked) => {}}
          action={
            <Tooltip title={t('Delete')}>
              <IconButton color="primary" onClick={confirm.onTrue}>
                <Iconify icon="solar:trash-bin-trash-bold" />
              </IconButton>
            </Tooltip>
          }
        />

        <Scrollbar>
          <Table size={dense ? 'small' : 'medium'}>
            <TableHeadCustom
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={prices.length}
              numSelected={selected.length}
              onSort={onSort}
            />

            <TableBody>
              <>
                {prices.map((row) => (
                  <ContingentPricingTableRow key={row.id} row={row} />
                ))}
              </>

              <TableEmptyRows
                height={denseHeight}
                emptyRows={emptyRows(page, rowsPerPage, prices.length)}
              />

              <TableNoData notFound={notFound} />
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>

      <TablePaginationCustom
        count={prices.length}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={onChangePage}
        onRowsPerPageChange={onChangeRowsPerPage}
        //
        dense={dense}
        onChangeDense={onChangeDense}
      />
    </>
  );
}
