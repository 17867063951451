// @mui
import Stack from '@mui/material/Stack';
import Box, { BoxProps } from '@mui/material/Box';
import CardHeader from '@mui/material/CardHeader';
import ListItemText from '@mui/material/ListItemText';
import Card from '@mui/material/Card';
// utils
import { fDateTime } from 'src/utils/format-time';
// components
import Iconify from 'src/components/iconify';
import { ExerciseResultDto } from '../../api';
import { fNumber } from '../../utils/format-number';

interface Props extends BoxProps {
  title?: string;
  subheader?: string;
  list: ExerciseResultDto[];
}

export default function TrainingResults({ title, subheader, list, sx }: Props) {
  const ordered = [...list].sort((a, b) => {
    if (a.exerciseTypeName!.localeCompare(b.exerciseTypeName!) !== 0) {
      return a.exerciseTypeName!.localeCompare(b.exerciseTypeName!);
    }

    if (a.count! - b.count! !== 0) {
      return a.count! - b.count!;
    }

    if (a.value! - b.value! !== 0) {
      return b.value! - a.value!;
    }

    return 0;
  });

  return (
    <>
      <CardHeader
        title={title}
        subheader={subheader}
        sx={{
          p: 0,
          mb: 3,
        }}
      />

      <Box
        display="grid"
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)',
          md: 'repeat(2, 1fr)',
        }}
        gap={3}
      >
        {ordered.map((item) => (
          <ExerciseResultItem key={`exercise-${item.createdDate}`} item={item} />
        ))}
      </Box>
    </>
  );
}

// ----------------------------------------------------------------------

type ExerciseResultItemProps = {
  item: ExerciseResultDto;
};

function ExerciseResultItem({ item }: ExerciseResultItemProps) {
  const { exerciseTypeName, value, count, createdDate } = item;

  return (
    <Card>
      <Stack
        spacing={2}
        sx={{
          p: 2,
        }}
      >
        <Stack direction="row" alignItems="center" spacing={2}>
          <ListItemText
            primary={exerciseTypeName}
            secondary={fDateTime(createdDate)}
            secondaryTypographyProps={{
              mt: 0.5,
              component: 'span',
              typography: 'caption',
              color: 'text.disabled',
            }}
          />
        </Stack>

        <Stack
          spacing={3}
          direction="row"
          alignItems="center"
          sx={{ color: 'text.secondary', typography: 'caption' }}
        >
          <Stack direction="row" alignItems="center">
            <Iconify icon="solar:dumbbell-small-bold-duotone" width={16} sx={{ mr: 0.5 }} />
            {count ?? 1} x {fNumber(value ?? 0)} kg
          </Stack>
        </Stack>
      </Stack>
    </Card>
  );
}
