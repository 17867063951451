// @mui
import Accordion from '@mui/material/Accordion';
import Typography from '@mui/material/Typography';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
// _mock
// components
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

type Props = {
  questionAndAnswers: any[];
};

export default function FaqsList({ questionAndAnswers }: Props) {
  console.log(questionAndAnswers);

  return (
    <div>
      {questionAndAnswers.map((qa: any) => (
        <Accordion key={qa.question}>
          <AccordionSummary expandIcon={<Iconify icon="eva:arrow-ios-downward-fill" />}>
            <Typography variant="subtitle1">{qa.question}</Typography>
          </AccordionSummary>

          <AccordionDetails>
            <Typography>{qa.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
