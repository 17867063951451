// @mui
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
// config
// components
// types
import { GymDto } from '../../api';
import { useLocales } from '../../locales';

// ----------------------------------------------------------------------

type Props = {
  gym: GymDto;
};

export default function GymOpeningHours({ gym }: Props) {
  const { t } = useLocales();

  return (
    <Card>
      <Stack spacing={3} sx={{ p: 3 }}>
        {gym.openingHours?.map((item, index) => (
          <Stack spacing={2} key={`day-of-week-${item.dayOfWeek}`}>
            <Typography
              variant="subtitle2"
              sx={{
                textTransform: 'capitalize',
              }}
            >
              {t(`${item.dayOfWeek}`)}
            </Typography>
            <Stack direction="row" spacing={1.5}>
              <TimePicker label={t('Open')} value={item.from} ampm={false} disabled />

              <TimePicker label={t('Closed')} value={item.to} ampm={false} disabled />
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Card>
  );
}
