import { useCallback, useRef, useState } from 'react';
// @mui
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
// types
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Iconify from 'src/components/iconify';
import { TableProps } from 'src/components/table';
//
import FileManagerPanel from './file-manager-panel';
import FileManagerFileItem from './file-manager-file-item';
import FileManagerActionSelected from './file-manager-action-selected';
import FileManagerShareDialog from './file-manager-share-dialog';
import FileManagerNewFolderDialog from './file-manager-new-folder-dialog';
import { AccountDto, DocumentDto } from '../../../api';
import { useLocales } from '../../../locales';

// ----------------------------------------------------------------------

type Props = {
  table: TableProps;
  data: DocumentDto[];
  dataFiltered: DocumentDto[];
  onOpenConfirm: VoidFunction;
  onClose: VoidFunction;
  onDeleteItem: (id: number) => void;
  member: AccountDto;
};

export default function FileManagerGridView({
  table,
  data,
  dataFiltered,
  onDeleteItem,
  onOpenConfirm,
  member,
  onClose,
}: Props) {
  const { t } = useLocales();

  const { selected, onSelectRow: onSelectItem, onSelectAllRows: onSelectAllItems } = table;

  const containerRef = useRef(null);

  const [inviteEmail, setInviteEmail] = useState('');

  const share = useBoolean();

  const upload = useBoolean();

  const files = useBoolean();

  const handleChangeInvite = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setInviteEmail(event.target.value);
  }, []);

  return (
    <>
      <Box ref={containerRef}>
        <FileManagerPanel
          title={t('Documents')}
          subTitle={`${data.length} files`}
          onOpen={upload.onTrue}
          collapse={files.value}
          onCollapse={files.onToggle}
        />

        <Collapse in={!files.value} unmountOnExit>
          <Box
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
              md: 'repeat(3, 1fr)',
              lg: 'repeat(4, 1fr)',
            }}
            gap={3}
          >
            {dataFiltered.map((doc) => (
              <FileManagerFileItem
                key={doc.id}
                document={doc}
                selected={selected.includes(`${doc.id}`)}
                onSelect={() => onSelectItem(`${doc.id}`)}
                onDelete={() => onDeleteItem(doc.id!)}
                sx={{ maxWidth: 'auto' }}
              />
            ))}
          </Box>
        </Collapse>

        {!!selected?.length && (
          <FileManagerActionSelected
            numSelected={selected.length}
            rowCount={data.length}
            selected={selected}
            onSelectAllItems={(checked) =>
              onSelectAllItems(
                checked,
                data.map((row) => `${row.id}`)
              )
            }
            action={
              <>
                <Button
                  size="small"
                  color="error"
                  variant="contained"
                  startIcon={<Iconify icon="solar:trash-bin-trash-bold" />}
                  onClick={onOpenConfirm}
                  sx={{ mr: 1 }}
                >
                  {t('Delete')}
                </Button>

                <Button
                  color="primary"
                  size="small"
                  variant="contained"
                  startIcon={<Iconify icon="solar:share-bold" />}
                  onClick={share.onTrue}
                >
                  View
                </Button>
              </>
            }
          />
        )}
      </Box>

      <FileManagerShareDialog
        open={share.value}
        inviteEmail={inviteEmail}
        onChangeInvite={handleChangeInvite}
        onClose={() => {
          share.onFalse();
          setInviteEmail('');
        }}
      />

      <FileManagerNewFolderDialog
        title={t('Upload Files')}
        open={upload.value}
        onClose={() => {
          upload.onFalse();
          onClose();
        }}
        member={member}
      />
    </>
  );
}
