import { forwardRef } from 'react';
// @mui
import Box, { BoxProps } from '@mui/material/Box';
import Link from '@mui/material/Link';
// routes
import { RouterLink } from 'src/routes/components';
import { Avatar } from '@mui/material';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
  src?: string;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, src = '/logo/logo_full.png', sx }) => {
    // OR using local (public folder)
    // -------------------------------------------------------
    const logo = (
      <Box component="img" src={src} sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }} />
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
        <Avatar
          src={src}
          alt="logo"
          sx={{
            ...sx,
            border: (theme) => `solid 2px ${theme.palette.background.default}`,
          }}
        />
      </Link>
    );
  }
);

export default Logo;
