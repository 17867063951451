import { useCallback, useEffect, useMemo, useState } from 'react';
// @mui
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { alpha, useTheme } from '@mui/material/styles';
// routes
import { paths } from 'src/routes/paths';
// components
import { useRouter } from 'src/routes/hook';
import isEqual from 'lodash/isEqual';
import { enqueueSnackbar } from 'notistack';
//
import { ClassTemplatesService, Direction } from '../../../api';
import { useLocales } from '../../../locales';
import {
  emptyRows,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  useTable,
} from '../../../components/table';
import { useSettingsContext } from '../../../components/settings';
import { useDispatch, useSelector } from '../../../redux/store';

import { IUserTableFilterValue } from '../../../types/user';
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
import { RouterLink } from '../../../routes/components';
import Iconify from '../../../components/iconify';
import Label from '../../../components/label';
import Scrollbar from '../../../components/scrollbar/scrollbar';
import { getClassTemplates } from '../../../redux/slices/class-template';
import ClassTemplateTableRow from '../class-template-table-row';
import ClassTemplateTableToolbar from '../class-template-table-toolbar';
import ClassTemplateTableFiltersResult from '../class-template-table-filters-result';

// ----------------------------------------------------------------------

export default function ClassTemplateList() {
  useTheme();

  const { t } = useLocales();

  const TABLE_HEAD = [
    { id: 'title', label: t('Title'), align: 'left' },
    { id: 'daysOfWeek', label: t('Days'), align: 'left' },
    { id: 'begins', label: t('Begins'), align: 'left' },
    { id: 'rating', label: t('Rating'), align: 'left' },
    { id: 'fillRate', label: t('Fill rate'), align: 'left' },
    { id: 'validFrom', label: t('Valid from'), align: 'left' },
    { id: 'validTo', label: t('Valid to'), align: 'left' },
    { id: '' },
  ];

  const tableSettings = useMemo(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const storedRowsPerPage = queryParams.get('rowsPerPage');
    const storedPage = queryParams.get('page');
    const storedOrderBy = queryParams.get('orderBy');
    const storedOrder = queryParams.get('order');

    const filters = {
      rowsPerPage: parseInt(storedRowsPerPage ?? '10', 10),
      page: parseInt(storedPage ?? '0', 10),
      orderBy: storedOrderBy ?? 'begins',
      order: storedOrder === 'asc' ? 'asc' : ('desc' as any),
    };

    return filters;
  }, []);

  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    onResetPage,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({
    defaultOrderBy: tableSettings.orderBy,
    defaultOrder: tableSettings.order,
    defaultRowsPerPage: tableSettings.rowsPerPage,
    defaultCurrentPage: tableSettings.page,
  });

  const settings = useSettingsContext();

  const router = useRouter();

  const dispatch = useDispatch();

  const tableData = useSelector((state) => state.classTemplate.templates);

  const totalNumberOfMemberships = useSelector(
    (state) => state.classTemplate.totalNumberOfTemplates
  );

  const tableStats = useSelector((state) => state.classTemplate.stats);

  const defaultFilters = useMemo(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const storedTitle = queryParams.get('title');

    return {
      title: storedTitle !== null && storedTitle !== '' ? storedTitle : undefined,
    };
  }, []);

  const [filters, setFilters] = useState(defaultFilters);

  useEffect(() => {
    dispatch(
      getClassTemplates({
        pageSize: rowsPerPage,
        pageNumber: page,
        sortBy: [orderBy],
        direction: order === 'asc' ? Direction.Asc : Direction.Desc,
        search: filters.title,
        isActive: true,
      })
    );
  }, [dispatch, rowsPerPage, page, filters, orderBy, order]);

  const STATUS_OPTIONS = [
    { value: 'all', label: t('All'), color: 'info', count: tableStats.active },
  ] as const;

  const canReset = !isEqual(defaultFilters, filters);

  const notFound = !tableData.length && !!filters.title;

  const handleFilters = useCallback(
    (name: string, value: IUserTableFilterValue) => {
      onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [onResetPage]
  );

  const handleDeleteRow = useCallback(
    (id: number) => {
      const request = async () => {
        await ClassTemplatesService.deleteTemplate({ id });

        enqueueSnackbar(t('Deleted!'));

        dispatch(
          getClassTemplates({
            pageSize: rowsPerPage,
            pageNumber: page,
            sortBy: [orderBy],
            direction: order === 'asc' ? Direction.Asc : Direction.Desc,
            isActive: true,
          })
        );
      };

      request();
    },
    [dispatch, order, orderBy, page, rowsPerPage, t]
  );
  useCallback(
    (id: number) => {
      router.push(paths.classTemplates.view(id));
    },
    [router]
  );

  const handleEditRow = useCallback(
    (id: number) => {
      router.push(paths.classTemplates.edit(id));
    },
    [router]
  );
  const handleFilterStatus = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      handleFilters('status', newValue);
    },
    [handleFilters]
  );

  const handleResetFilters = useCallback(() => {
    setFilters({ title: '' });
  }, [setFilters]);

  const denseHeight = dense ? 52 : 72;

  useEffect(() => {
    const newurl = `${window.location.protocol}//${window.location.host}${
      window.location.pathname
    }?rowsPerPage=${rowsPerPage}&page=${page}&orderBy=${orderBy}&order=${order}&title=${
      filters.title ?? ''
    }`;
    window.history.replaceState({ path: newurl }, '', newurl);
  }, [rowsPerPage, page, filters, orderBy, order]);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <CustomBreadcrumbs
        heading={`${t('Planning')}`}
        links={[
          {
            name: `${t('Dashboard')}`,
            href: paths.dashboard.root,
          },
          {
            name: `${t('Class Templates')}`,
            href: paths.classTemplates.root,
          },
          { name: `${t('List')}` },
        ]}
        action={
          <Button
            component={RouterLink}
            href={paths.classTemplates.new}
            variant="contained"
            startIcon={<Iconify icon="mingcute:add-line" />}
          >
            {t('Schedule')}
          </Button>
        }
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <Card>
        <Tabs
          value="all"
          onChange={handleFilterStatus}
          sx={{
            px: 2.5,
            boxShadow: (bTheme) => `inset 0 -2px 0 0 ${alpha(bTheme.palette.grey[500], 0.08)}`,
          }}
        >
          {STATUS_OPTIONS.map((tab) => (
            <Tab
              key={tab.value}
              iconPosition="end"
              value={tab.value}
              label={tab.label}
              icon={
                <Label variant={(tab.value === 'all' && 'filled') || 'soft'} color={tab.color}>
                  {tab.count}
                </Label>
              }
            />
          ))}
        </Tabs>

        <ClassTemplateTableToolbar
          filters={filters}
          onFilters={handleFilters}
          onExport={() => {}}
        />

        {canReset && (
          <ClassTemplateTableFiltersResult
            filters={filters}
            onFilters={handleFilters}
            //
            canReset={canReset}
            onResetFilters={handleResetFilters}
            //
            results={totalNumberOfMemberships}
            sx={{ p: 2.5, pt: 0 }}
          />
        )}

        <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
          <Scrollbar>
            <Table size={dense ? 'small' : 'medium'}>
              <TableHeadCustom
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={tableData.length}
                onSort={onSort}
              />

              <TableBody>
                {tableData?.map((row) => (
                  <ClassTemplateTableRow
                    key={row.id}
                    row={row}
                    onEditRow={() => handleEditRow(row.id!)}
                    onDeleteRow={() => handleDeleteRow(row.id!)}
                  />
                ))}

                <TableEmptyRows
                  height={denseHeight}
                  emptyRows={emptyRows(page, rowsPerPage, totalNumberOfMemberships)}
                />

                <TableNoData notFound={notFound} />
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>

        <TablePaginationCustom
          count={totalNumberOfMemberships}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
          //
          dense={dense}
          onChangeDense={onChangeDense}
        />
      </Card>
    </Container>
  );
}
