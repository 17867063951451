import { createSlice, Dispatch } from '@reduxjs/toolkit';
// utils
import {
  ClassDescriptionDto,
  ClassDescriptionsService,
  Direction,
  EntityStatuses,
  getConfigs,
  serviceOptions,
} from '../../api';
import { IClassTemplateStats } from '../../types/class-templates';
import { setError } from './error';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  descriptions: [] as ClassDescriptionDto[],
  currentDescription: {} as ClassDescriptionDto,
  totalNumberOfDescriptions: 0,
  stats: {
    total: 0,
    active: 0,
    inactive: 0,
  } as IClassTemplateStats,
};

const slice = createSlice({
  name: 'class-description',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    stoppedLoading(state) {
      state.isLoading = false;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getDescriptionsSuccess(state, action) {
      state.descriptions = action.payload.descriptions;
      state.totalNumberOfDescriptions = action.payload.total;
      state.stats = action.payload.stats;
    },
    getDescriptionSuccess(state, action) {
      state.currentDescription = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getClassDescriptions(
  params: {
    /**  */
    pageNumber?: number;
    /**  */
    pageSize?: number;
    /**  */
    sortBy?: any | null[];
    /**  */
    direction?: Direction;
    statuses?: EntityStatuses[];
    search?: string;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = '/api/class-descriptions';

      const configs = getConfigs('get', 'application/json', url, {});

      configs.params = params;
      const resp = await serviceOptions.axios!.request(configs);

      const stats = JSON.parse(resp.headers['x-page-stats']);

      dispatch(
        slice.actions.getDescriptionsSuccess({
          total: parseInt(resp.headers['x-total-count'], 10),
          descriptions: resp.data,
          stats,
        })
      );

      dispatch(slice.actions.stoppedLoading());
    } catch (error) {
      dispatch(setError(error));
    }
  };
}

export function getClassDescription(
  params: {
    /**  */
    id: number;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await ClassDescriptionsService.details(params);

      dispatch(slice.actions.getDescriptionSuccess(resp));
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}
