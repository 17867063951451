import { useCallback } from 'react';
// @mui
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// components
import { IInsightFilterValue, IInsightTableFilters } from 'src/types/insight';
import { DateTime } from 'luxon';
import { InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import {
  CampaignMembershipTypeDto,
  ContingentTypeDto,
  DropInTypeDto,
  PersonalTrainingTypeDto,
  VoucherTypeDto,
} from 'src/api';
import { useLocales } from '../../../locales';

// ----------------------------------------------------------------------

type Props = {
  filters: IInsightTableFilters;
  onFilters: (name: string, value: IInsightFilterValue) => void;
  campaigns: CampaignMembershipTypeDto[];
  dropIns: DropInTypeDto[];
  contingents: ContingentTypeDto[];
  punchCards: VoucherTypeDto[];
  personalTrainings: PersonalTrainingTypeDto[];
};

export default function InsightsToolbar({
  filters,
  onFilters,
  campaigns,
  contingents,
  punchCards,
  personalTrainings,
  dropIns,
}: Props) {
  const { t } = useLocales();

  const handleFilterValidFrom = useCallback(
    (newValue: DateTime | null) => {
      onFilters('fromDate', newValue);
    },
    [onFilters]
  );

  const handleFilterValidTo = useCallback(
    (newValue: DateTime | null) => {
      onFilters('toDate', newValue);
    },
    [onFilters]
  );

  const handleFilterMembershipTypes = useCallback(
    (event: SelectChangeEvent<number[] | null>) => {
      onFilters('membershipTypeIds', event.target.value);
    },
    [onFilters]
  );

  return (
    <Stack
      spacing={2}
      alignItems={{ xs: 'flex-end', md: 'center' }}
      direction={{
        xs: 'column',
        md: 'row',
      }}
      sx={{
        p: 2.5,
      }}
    >
      <Stack direction="row" alignItems="center" spacing={2} flexGrow={1} sx={{ width: 1 }}>
        <FormControl fullWidth>
          <DatePicker
            label={t('From date')}
            value={filters.fromDate}
            slotProps={{
              textField: {
                fullWidth: true,
              },
            }}
            onChange={handleFilterValidFrom}
          />
        </FormControl>

        <FormControl fullWidth>
          <DatePicker
            label={t('To date')}
            value={filters.toDate}
            slotProps={{
              textField: {
                fullWidth: true,
              },
            }}
            onChange={handleFilterValidTo}
          />
        </FormControl>

        <FormControl fullWidth>
          <InputLabel>{t('Membership types')}</InputLabel>
          <Select
            label={t('Membership types')}
            value={filters.membershipTypeIds}
            onChange={handleFilterMembershipTypes}
            autoWidth={false}
            multiple
          >
            <MenuItem divider disabled>
              {t('Campaigns')}
            </MenuItem>
            {campaigns.map((option) => (
              <MenuItem
                key={`membership-type-${option.id}`}
                value={option.id}
                sx={{
                  justifyContent: 'space-between',
                }}
              >
                {option.name}
              </MenuItem>
            ))}
            <MenuItem divider disabled>
              {t('Contingents')}
            </MenuItem>
            {contingents.map((option) => (
              <MenuItem
                key={`membership-type-${option.id}`}
                value={option.id}
                sx={{
                  justifyContent: 'space-between',
                }}
              >
                {option.name}
              </MenuItem>
            ))}
            <MenuItem divider disabled>
              {t('Personal training')}
            </MenuItem>
            {personalTrainings.map((option) => (
              <MenuItem
                key={`membership-type-${option.id}`}
                value={option.id}
                sx={{
                  justifyContent: 'space-between',
                }}
              >
                {option.name}
              </MenuItem>
            ))}
            <MenuItem divider disabled>
              {t('Punch cards')}
            </MenuItem>
            {punchCards.map((option) => (
              <MenuItem
                key={`membership-type-${option.id}`}
                value={option.id}
                sx={{
                  justifyContent: 'space-between',
                }}
              >
                {option.name}
              </MenuItem>
            ))}
            <MenuItem divider disabled>
              {t('Drop In')}
            </MenuItem>
            {dropIns.map((option) => (
              <MenuItem
                key={`membership-type-${option.id}`}
                value={option.id}
                sx={{
                  justifyContent: 'space-between',
                }}
              >
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
    </Stack>
  );
}
