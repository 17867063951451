import { useEffect, useState } from 'react';
// @mui
import Grid from '@mui/material/Unstable_Grid2';
// types
// components
import { AccountDto, ChainDto, MembershipDto, SubscriptionType } from '../../api';
import PersonalProgram from './personal-program';
import BookingDetails from './booking-details';
import { useLocales } from '../../locales';
import PersonalTrainer from './personal-trainer';
import Fines from './fines';

// ----------------------------------------------------------------------

type Props = {
  member: AccountDto;
  chain: ChainDto;
  memberships: MembershipDto[];
  coverUrl: string;
};

export default function ProfileTraining({ member, chain, memberships, coverUrl }: Props) {
  const { t } = useLocales();

  const [personalTrainingAvailable, setPersonalTrainingAvailable] = useState(false);

  const [personalTrainingMembership, setPersonalTrainingMembership] =
    useState<MembershipDto | null>(null);

  useEffect(() => {
    const pt = memberships.filter((x) => x.isPersonalTrainingMembership === true);
    setPersonalTrainingAvailable(pt.length > 0);
    setPersonalTrainingMembership(pt.length > 0 ? pt[0] : null);
  }, [memberships]);

  return (
    <Grid container spacing={3} disableEqualOverflow>
      {(chain.subscriptionType === SubscriptionType.Premium ||
        chain.subscriptionType === SubscriptionType.Platinum) &&
        personalTrainingAvailable && (
          <Grid xs={12} md={8}>
            <PersonalProgram member={member} />
          </Grid>
        )}

      {(chain.subscriptionType === SubscriptionType.Premium ||
        chain.subscriptionType === SubscriptionType.Platinum) &&
        personalTrainingAvailable && (
          <Grid xs={12} md={4}>
            <PersonalTrainer
              member={member}
              coverUrl={coverUrl}
              membership={personalTrainingMembership!}
            />
          </Grid>
        )}

      <Grid xs={12} md={8}>
        <BookingDetails title={t('Booking Details')} member={member} />
      </Grid>
      <Grid xs={12} md={4}>
        <Fines title={t('Fines')} member={member} />
      </Grid>
    </Grid>
  );
}
