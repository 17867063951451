import { useCallback, useEffect, useState } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
// _mock
import { PRODUCT_PUBLISH_OPTIONS } from 'src/_mock';
// routes
import { paths } from 'src/routes/paths';
import { useParams } from 'src/routes/hook';
import { RouterLink } from 'src/routes/components';
// api
// components
import Iconify from 'src/components/iconify';
import EmptyContent from 'src/components/empty-content';
import { useSettingsContext } from 'src/components/settings';
//
import { PunchCardDetailsSkeleton } from '../punch-card-skeleton';
import PunchCardDetailsToolbar from '../punch-card-details-toolbar';
import PunchCardDetailsDescription from '../punch-card-details-description';
import { useDispatch, useSelector } from '../../../../redux/store';
import { useLocales } from '../../../../locales';
import { getPunchCard } from '../../../../redux/slices/membership-type';
import { getChain } from '../../../../redux/slices/chain';
import PunchCardDetailsMembers from '../punch-card-details-members';

// ----------------------------------------------------------------------

export default function PunchCardDetailsView() {
  const params = useParams();

  const { t } = useLocales();

  const { id = '' } = params;

  const dispatch = useDispatch();

  const membershipType = useSelector((state) => state.membershipType.currentPunchCard);

  const membershipTypeError = useSelector((state) => state.membershipType.error);

  const membershipTypeLoading = useSelector((state) => state.membershipType.isLoading);

  useEffect(() => {
    dispatch(getPunchCard(parseInt(id, 10)));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(getChain());
  }, [dispatch]);

  const settings = useSettingsContext();

  const [currentTab, setCurrentTab] = useState('members');

  const [publish, setPublish] = useState('');

  const handleChangePublish = useCallback((newValue: string) => {
    setPublish(newValue);
  }, []);

  const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue);
  }, []);

  const renderSkeleton = <PunchCardDetailsSkeleton />;

  const renderError = (
    <EmptyContent
      filled
      title={`${membershipTypeError}`}
      action={
        <Button
          component={RouterLink}
          href={paths.punchCardType.root}
          startIcon={<Iconify icon="eva:arrow-ios-back-fill" width={16} />}
          sx={{ mt: 3 }}
        >
          {t('Back to List')}
        </Button>
      }
      sx={{ py: 10 }}
    />
  );

  const renderProduct = membershipType && (
    <>
      <PunchCardDetailsToolbar
        backLink={paths.punchCardType.root}
        editLink={paths.punchCardType.edit(membershipType?.id!)}
        liveLink={paths.punchCardType.details(membershipType?.id!)}
        publish={publish || ''}
        onChangePublish={handleChangePublish}
        publishOptions={PRODUCT_PUBLISH_OPTIONS}
        membershipType={membershipType}
      />

      <Card>
        <Tabs
          value={currentTab}
          onChange={handleChangeTab}
          sx={{
            px: 3,
            boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
          }}
        >
          {[
            {
              value: 'description',
              label: t('Description'),
            },
            {
              value: 'members',
              label: t('Members'),
            },
          ].map((tab) => (
            <Tab key={tab.value} value={tab.value} label={tab.label} />
          ))}
        </Tabs>

        {currentTab === 'description' && (
          <PunchCardDetailsDescription description={membershipType?.description ?? ''} />
        )}
        {currentTab === 'members' && <PunchCardDetailsMembers membershipType={membershipType} />}
      </Card>
    </>
  );

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      {membershipTypeLoading && renderSkeleton}

      {membershipTypeError && renderError}

      {!membershipTypeLoading && membershipType && renderProduct}
    </Container>
  );
}
