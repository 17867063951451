import { ApexOptions } from 'apexcharts';
// @mui
import CardHeader from '@mui/material/CardHeader';
import Card, { CardProps } from '@mui/material/Card';
// utils
// components
import CardContent from '@mui/material/CardContent';

// ----------------------------------------------------------------------

interface Props extends CardProps {
  title?: string;
  subheader?: string;
  chart: {
    colors?: string[];
    labels: string[];
    series: {
      name: string;
      data: number[];
    }[];
    options?: ApexOptions;
  };
}

export default function HeatmapWidget({ title, subheader, chart, ...other }: Props) {
  const { series, labels } = chart;

  const calculateBackgroundColor = (value: number) => `rgba(2, 136, 209, ${value / 100})`;

  const calculateTextColor = () => {
    let d = 0;

    // Counting the perceptive luminance - human eye favors green color...
    const luminance = (0.299 * 2 + 0.587 * 136 + 0.114 * 209) / 186;

    if (luminance > 0.5) d = 0;
    // bright colors - black font
    else d = 255; // dark colors - white font

    return `rgb(${d}, ${d}, ${d})`;
  };

  function renderMonth(data: number, index: number) {
    if (data > 0) {
      return (
        <td
          key={`churn-tr-td-${index}`}
          style={{
            backgroundColor: calculateBackgroundColor(data),
            color: calculateTextColor(),
            border: '1px solid rgba(241, 243, 244, 1)',
            borderCollapse: 'collapse',
            padding: '6px',
            fontWeight: '600',
          }}
        >
          {data}%
        </td>
      );
    }
    return (
      <td
        key={`churn-tr-td-${index}`}
        style={{
          backgroundColor: calculateBackgroundColor(data),
          color: calculateTextColor(),
          border: '1px solid rgba(241, 243, 244, 1)',
          borderCollapse: 'collapse',
          padding: '6px',
          fontWeight: '600',
        }}
      />
    );
  }

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <CardContent
        sx={{
          overflowX: 'auto',
        }}
      >
        <table
          style={{
            border: '1px solid rgba(241, 243, 244, 1)',
            borderCollapse: 'collapse',
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  border: '1px solid rgba(241, 243, 244, 1)',
                  borderCollapse: 'collapse',
                  padding: '8px',
                  fontWeight: '600',
                  width: '5em',
                  minWidth: '6em',
                  maxWidth: '6em',
                  wordBreak: 'break-all',
                  color: '#637381',
                  fontSize: '14px',
                }}
              />
              {labels.map((item) => (
                <th
                  key={`churn-tr-th-${item}`}
                  style={{
                    border: '1px solid rgba(241, 243, 244, 1)',
                    borderCollapse: 'collapse',
                    padding: '8px',
                    fontWeight: '600',
                    color: '#637381',
                    fontSize: '14px',
                  }}
                >
                  {item}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {series.map((item) => (
              <tr key={`churn-tr-td-${item.name}`}>
                <td
                  style={{
                    border: '1px solid rgba(241, 243, 244, 1)',
                    borderCollapse: 'collapse',
                    padding: '8px',
                    fontWeight: '600',
                    width: '5em',
                    minWidth: '5em',
                    maxWidth: '5em',
                    wordBreak: 'break-all',
                    color: '#637381',
                    fontSize: '14px',
                  }}
                >
                  {item.name}
                </td>
                <td
                  style={{
                    border: '1px solid rgba(241, 243, 244, 1)',
                    borderCollapse: 'collapse',
                    padding: '6px',
                    fontWeight: '600',
                    color: '#637381',
                  }}
                >
                  {item.data[0]}
                </td>
                {item.data.slice(1).map((data, index) => renderMonth(data, index))}
              </tr>
            ))}
          </tbody>
        </table>
      </CardContent>
    </Card>
  );
}
