import { useCallback, useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
// @mui
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
//
import { DateTime } from 'luxon';
// components
import { useDispatch, useSelector } from '../../../redux/store';
//
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
import { useSettingsContext } from '../../../components/settings';
import MemberChangeReportToolbar from './member-change-report-toolbar';

import { IReportFilterValue, IReportTableFilters } from '../../../types/report';
import { useLocales } from '../../../locales';
import { paths } from '../../../routes/paths';
import { getMemberChangeReport } from '../../../redux/slices/report';
import { convertToCSV } from '../../../utils/array-utils';
import MemberChangeNewTableView from './member-change-new-table';
import MemberChangeOnHoldTableView from './member-change-on-hold-table';
import MemberChangeLostTableView from './member-change-lost-table';

// ----------------------------------------------------------------------

const defaultFilters: IReportTableFilters = {
  fromDate: DateTime.now().startOf('month'),
  toDate: DateTime.now().endOf('month'),
  membershipTypeIds: [],
  minimumLengthOfMembershipInDays: 7,
};

// ----------------------------------------------------------------------

export default function MemberChangeReportView() {
  const settings = useSettingsContext();

  const { t } = useLocales();

  const dispatch = useDispatch();

  const report = useSelector((state) => state.report.memberChangeReport);

  const campaigns = useSelector((state) => state.membershipType.campaigns);
  const contingents = useSelector((state) => state.membershipType.contingents);
  const punchCards = useSelector((state) => state.membershipType.punchCards);
  const dropIns = useSelector((state) => state.membershipType.dropIns);
  const personalTrainings = useSelector((state) => state.membershipType.personalTrainings);

  const [filters, setFilters] = useState(defaultFilters);

  useEffect(() => {
    dispatch(getMemberChangeReport(filters));
  }, [dispatch, filters]);

  const handleFilters = useCallback((name: string, value: IReportFilterValue) => {
    setFilters((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  const exportCsv = useCallback(() => {
    const lostMembers = convertToCSV(report.lostMembers ?? []);

    const lostBlob = new Blob([lostMembers as any], {
      type: 'text/csv',
    });
    saveAs(
      lostBlob,
      `lost-members_${filters.fromDate!.toFormat('yyyy-MM-dd')}__${filters.toDate!.toFormat(
        'yyyy-MM-dd'
      )}.csv`
    );

    const newMembers = convertToCSV(report.newMembers ?? []);

    // Ignore type, because API can return multiple types
    const newBlob = new Blob([newMembers as any], {
      type: 'text/csv',
    });
    saveAs(
      newBlob,
      `new-members_${filters.fromDate!.toFormat('yyyy-MM-dd')}__${filters.toDate!.toFormat(
        'yyyy-MM-dd'
      )}.csv`
    );

    const onHoldMembers = convertToCSV(report.onHoldMembers ?? []);

    // Ignore type, because API can return multiple types
    const onHoldBlob = new Blob([onHoldMembers as any], {
      type: 'text/csv',
    });
    saveAs(
      onHoldBlob,
      `on-hold-members_${filters.fromDate!.toFormat('yyyy-MM-dd')}__${filters.toDate!.toFormat(
        'yyyy-MM-dd'
      )}.csv`
    );
  }, [report, filters]);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <CustomBreadcrumbs
        heading={`${t('Member change report')}`}
        links={[
          {
            name: `${t('Dashboard')}`,
            href: paths.dashboard.root,
          },
          {
            name: `${t('Reports')}`,
            href: paths.report.root,
          },
          { name: `${t('Member change report')}` },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
      <Stack spacing={3} id="printarea">
        <Card>
          <MemberChangeReportToolbar
            filters={filters}
            onFilters={handleFilters}
            onExport={exportCsv}
            dropIns={dropIns}
            punchCards={punchCards}
            contingents={contingents}
            campaigns={campaigns}
            personalTrainings={personalTrainings}
          />
        </Card>
        <Card>
          <CardHeader title={t('Overview')} />
          <CardContent>
            <table>
              <tbody>
                <tr>
                  <th align="left">{t('Active members at beginning')}</th>
                  <td align="right">{report.numberOfActiveMembersAtStart}</td>
                </tr>
                <tr>
                  <th align="left">{t('Number of new members')}</th>
                  <td align="right">{report.numberOfNewMembers}</td>
                </tr>
                <tr>
                  <th align="left">{t('Number of lost members')}</th>
                  <td align="right">{report.numberOfLostMembers}</td>
                </tr>
                <tr>
                  <th align="left">{t('Active members at end')}</th>
                  <td align="right" width={100}>
                    {report.numberOfActiveMembersAtEnd}
                  </td>
                </tr>
                <tr>
                  <th align="left">{t('Members on hold')}</th>
                  <td align="right">{report.numberOfOnHoldMembers}</td>
                </tr>
              </tbody>
            </table>
          </CardContent>
        </Card>

        <MemberChangeNewTableView rows={report.newMembers ?? []} />

        <MemberChangeLostTableView rows={report.lostMembers ?? []} />

        <MemberChangeOnHoldTableView rows={report.onHoldMembers ?? []} />
      </Stack>
    </Container>
  );
}
