import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { setError } from 'src/redux/slices/error';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo } from 'react';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
// types
// assets
// components
import FormProvider, { RHFSelect, RHFTextField } from 'src/components/hook-form';
import { useLocales } from '../../locales';
import { useDispatch, useSelector } from '../../redux/store';
import { fCurrencySymbol } from '../../utils/format-number';
import { AccountDto, AdjustmentType, MembersService } from '../../api';

// ----------------------------------------------------------------------

type Props = {
  open: boolean;
  onClose: VoidFunction;
  onCreate: VoidFunction;
  member: AccountDto;
};

export default function AdjustBalanceForm({ open, onClose, onCreate, member }: Props) {
  const { t } = useLocales();

  const settings = useSelector((state) => state.chain.currentSettings);

  const gyms = useSelector((state) => state.gym.gyms);

  const selectedGym = useSelector((state) => state.gym.globalGym);

  const newSchema = Yup.object().shape({
    adjustmentType: Yup.string().required(t('Type is required')),
    amount: Yup.lazy((value) => (value === '' ? Yup.string().default('') : Yup.number().min(0.01))),
    currency: Yup.string(),
    note: Yup.string().required(),
    gymId: Yup.number().min(1, t('Gym is required')),
  });

  const defaultValues = useMemo(
    () =>
      ({
        adjustmentType: AdjustmentType.Debit,
        amount: '',
        currency: settings.defaultCurrency,
        note: '',
        gymId: selectedGym?.id || (gyms.length > 0 ? gyms[0].id : -1),
      } as any),
    [gyms, selectedGym, settings]
  );

  const methods = useForm({
    resolver: yupResolver(newSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = methods;

  const dispatch = useDispatch();

  const onSubmit = handleSubmit(async (data) => {
    try {
      await MembersService.adjustCreditBalance({
        memberId: member.id!,
        body: {
          ...data,
          accountId: member.id!,
          gymId: selectedGym?.id,
        } as any,
      });
      onCreate();
      onClose();
      reset();
    } catch (error) {
      dispatch(setError(error));
    }
  });

  const adjustmentTypes = [
    {
      value: AdjustmentType.Debit,
      text: t(AdjustmentType.Debit),
    },
    {
      value: AdjustmentType.Credit,
      text: t(AdjustmentType.Credit),
    },
  ];

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle>{t('Adjust balance')}</DialogTitle>
        <DialogContent dividers>
          <Typography variant="body1">
            {t(
              'Here you can adjust the member balance. Debit increases the amount of money owed to the member, and credit increases the amount that the member owes the gym.'
            )}
          </Typography>
          <Stack spacing={3} sx={{ mt: 1.5 }}>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >
              <Stack spacing={1.5}>
                <Typography variant="subtitle2">{t('Type')}</Typography>
                <RHFSelect
                  name="adjustmentType"
                  InputLabelProps={{ shrink: true }}
                  PaperPropsSx={{ textTransform: 'capitalize' }}
                >
                  {adjustmentTypes.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.text}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Stack>
              <Stack spacing={1.5}>
                <Typography variant="subtitle2">{t('Amount')}</Typography>
                <RHFTextField
                  name="amount"
                  placeholder="0.00"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Box component="span" sx={{ color: 'text.disabled' }}>
                          {fCurrencySymbol(settings.defaultCurrency)}
                        </Box>
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>
            </Box>
            <Stack spacing={1.5}>
              <Typography variant="subtitle2">{t('Note')}</Typography>
              <RHFTextField name="note" />
            </Stack>
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button color="inherit" variant="outlined" onClick={onClose}>
            {t('Cancel')}
          </Button>

          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            {t('Adjust')}
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
