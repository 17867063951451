import * as Yup from 'yup';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import Alert from '@mui/material/Alert';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// routes
import { useParams, useRouter } from 'src/routes/hook';
// components
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFTextField, RHFUpload } from 'src/components/hook-form';
import EmailEditor, { EditorRef, EmailEditorProps } from 'react-email-editor';
// types
import {
  EmailTemplateDto,
  EmailTemplatesService,
  EmailTemplateType,
  ImageService,
} from '../../api';
import { useLocales } from '../../locales';

// ----------------------------------------------------------------------

type Props = {
  currentTemplate?: EmailTemplateDto;
};

export default function EmailTemplateNewEditForm({ currentTemplate }: Props) {
  const router = useRouter();

  const { t } = useLocales();

  const mdUp = useResponsive('up', 'md');

  const { enqueueSnackbar } = useSnackbar();

  const [alertMessage, setAlertMessage] = useState('');

  const emailEditorRef = useRef<EditorRef>(null);

  const newSchema = Yup.object().shape({
    type: Yup.mixed<EmailTemplateType>().required(t('Type is required')),
    html: Yup.string(),
    json: Yup.mixed().nullable(),
    css: Yup.string(),
    subject: Yup.string().required(t('Subject is required')),
    from: Yup.string().required(t('From is required')),
    name: Yup.string().required(t('Name is required')),
    attachments: Yup.array().min(0, t('Attachments is required')).nullable(),
  });

  const defaultValues = useMemo(
    () => ({
      type: currentTemplate?.type || EmailTemplateType.WelcomeEmail,
      html: currentTemplate?.html || '',
      css: currentTemplate?.css || '',
      json: currentTemplate?.json || null,
      subject: currentTemplate?.subject || '',
      from: currentTemplate?.from || '',
      name: currentTemplate?.name || '',
      attachments: currentTemplate?.attachments?.map((e) => e.url) || null,
    }),
    [currentTemplate]
  );

  const methods = useForm({
    resolver: yupResolver(newSchema),
    defaultValues,
  });

  const {
    watch,
    reset,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  const params = useParams();

  const { id } = params;

  useEffect(() => {
    if (currentTemplate) {
      reset(defaultValues);
    }
  }, [currentTemplate, defaultValues, reset]);

  const onReady: EmailEditorProps['onReady'] = (unlayer) => {
    unlayer.setBodyValues({
      backgroundColor: '#fff',
    });
    unlayer.registerCallback('image', (d: any, x: any) => {
      ImageService.create({
        files: d.attachments,
      }).then((resp) => x({ url: resp.url }));
    });

    EmailTemplatesService.getById({ id: parseInt(id!, 10) }).then((resp) => {
      if (resp?.json !== undefined && resp?.json !== null && resp?.json !== '') {
        unlayer.loadDesign(resp!.json! as any);
      } else if (resp?.html !== undefined && resp?.html !== null && resp?.html !== '') {
        unlayer.loadDesign({
          classic: true,
          html: resp?.html,
        } as any);
      }
    });
  };

  const onSubmit = handleSubmit(
    (data) =>
      new Promise((resolve) => {
        const unlayer = emailEditorRef.current?.editor;
        unlayer?.exportHtml(async (d) => {
          try {
            const { design, html } = d;
            // Check if is edit mode
            if (currentTemplate) {
              await EmailTemplatesService.edit({
                id: currentTemplate!.id!,
                body: {
                  id: currentTemplate!.id!,
                  ...data,
                  html,
                  json: JSON.stringify(design),
                } as any,
              });
              await EmailTemplatesService.uploadAttachment({
                id: currentTemplate!.id!,
                files: data.attachments ?? [],
              });
            } else {
              const template = await EmailTemplatesService.create({
                body: { ...data, html, json: JSON.stringify(design) } as any,
              });
              await EmailTemplatesService.uploadAttachment({
                id: template!.id!,
                files: data.attachments ?? [],
              });
            }

            reset();
            enqueueSnackbar(currentTemplate ? t('Update success!') : t('Create success!'));
            router.back();
          } catch (error) {
            setAlertMessage(`${error.response?.data?.detail}`);
          }
          resolve(null);
        });
      })
  );

  const emailTypes = [
    EmailTemplateType.WelcomeEmail,
    EmailTemplateType.WelcomeEmailExistingMember,
    EmailTemplateType.FineWhenTooLate,
    EmailTemplateType.FineWhenNeverMet,
    EmailTemplateType.Custom,
    EmailTemplateType.PutOnHold,
    EmailTemplateType.Cancellation,
    EmailTemplateType.Guest,
    EmailTemplateType.Referral,
    EmailTemplateType.UpdatedContract,
    EmailTemplateType.MissingPayment,
    EmailTemplateType.TicketConfirmation,
    EmailTemplateType.DropIn,
  ]
    .map((el) => ({
      value: el,
      label: t(el),
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const handleTypeChange = (event: SelectChangeEvent<any>) => {
    setValue('type', event.target.value, { shouldValidate: true });
  };

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const files = acceptedFiles.map((file) =>
        Object.assign(file, { preview: URL.createObjectURL(file) })
      );

      setValue('attachments', files, { shouldValidate: true });
    },
    [setValue]
  );

  const handleRemoveFile = useCallback(() => {
    setValue('attachments', null);
  }, [setValue]);

  const renderAlert = (
    <>
      {alertMessage && (
        <Grid xs={12}>
          <Alert severity="error">{alertMessage}</Alert>
        </Grid>
      )}
    </>
  );

  const renderPlaceholders = (
    <>
      {mdUp && (
        <Grid md={12}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            {t('Placeholders')}
          </Typography>
        </Grid>
      )}

      <Grid xs={12}>
        <Card>
          {!mdUp && <CardHeader title={t('Details')} />}
          <Stack spacing={3} sx={{ p: 3 }}>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {t('Here you see the available placeholders for the given email type.')}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              <table>
                <tbody>
                  <tr>
                    <th align="left">{'{{FULL_NAME}}'}</th>
                    <td>{t('Full name of member')}</td>
                  </tr>
                  <tr>
                    <th align="left">{'{{FIRST_NAME}}'}</th>
                    <td>{t('First name of member')}</td>
                  </tr>
                  <tr>
                    <th align="left">{'{{LAST_NAME}}'}</th>
                    <td>{t('Last name of member')}</td>
                  </tr>
                  <tr>
                    <th align="left">{'{{CHAIN_NAME}}'}</th>
                    <td>{t('Chain name')}</td>
                  </tr>
                  {values.type === EmailTemplateType.WelcomeEmail && (
                    <>
                      <tr>
                        <th align="left">{'{{MEMBERSHIP_TYPE_NAME}}'}</th>
                        <td>{t('Name of the membership')}</td>
                      </tr>
                      <tr>
                        <th align="left">{'{{PLAY_STORE_LINK}}'}</th>
                        <td>{t('Link to app on Play Store')}</td>
                      </tr>
                      <tr>
                        <th align="left">{'{{APP_STORE_LINK}}'}</th>
                        <td>{t('Link to app on App Store')}</td>
                      </tr>
                      <tr>
                        <th align="left">{'{{PASSWORD}}'}</th>
                        <td>{t('New password for the member account')}</td>
                      </tr>
                      <tr>
                        <th align="left">{'{{DOOR_CODE}}'}</th>
                        <td>{t('New pin code for the door')}</td>
                      </tr>
                    </>
                  )}
                  {values.type === EmailTemplateType.WelcomeEmailExistingMember && (
                    <tr>
                      <th align="left">{'{{DOOR_CODE}}'}</th>
                      <td>{t('New pin code for the door')}</td>
                    </tr>
                  )}
                  {values.type === EmailTemplateType.FineWhenTooLate && (
                    <>
                      <tr>
                        <th align="left">{'{{DATE}}'}</th>
                        <td>{t('Date on which the fine was issued')}</td>
                      </tr>
                      <tr>
                        <th align="left">{'{{FINE_AMOUNT}}'}</th>
                        <td>{t('The fine amount due')}</td>
                      </tr>
                      <tr>
                        <th align="left">{'{{CLASS_NAME}}'}</th>
                        <td>{t('Class name of which the fine was issued')}</td>
                      </tr>
                    </>
                  )}
                  {values.type === EmailTemplateType.FineWhenNeverMet && (
                    <>
                      <tr>
                        <th align="left">{'{{DATE}}'}</th>
                        <td>{t('Date on which the fine was issued')}</td>
                      </tr>
                      <tr>
                        <th align="left">{'{{FINE_AMOUNT}}'}</th>
                        <td>{t('The fine amount due')}</td>
                      </tr>
                      <tr>
                        <th align="left">{'{{CLASS_NAME}}'}</th>
                        <td>{t('Class name of which the fine was issued')}</td>
                      </tr>
                    </>
                  )}
                  {values.type === EmailTemplateType.PutOnHold && (
                    <>
                      <tr>
                        <th align="left">{'{{ON_HOLD_UNTIL}}'}</th>
                        <td>{t('When the membership is no longer on hold')}</td>
                      </tr>
                      <tr>
                        <th align="left">{'{{VALID_TO}}'}</th>
                        <td>{t('When the current membership is set on hold')}</td>
                      </tr>
                      <tr>
                        <th align="left">{'{{MEMBERSHIP_TYPE_NAME}}'}</th>
                        <td>{t('Name of the membership')}</td>
                      </tr>
                    </>
                  )}
                  {values.type === EmailTemplateType.Cancellation && (
                    <>
                      <tr>
                        <th align="left">{'{{VALID_TO}}'}</th>
                        <td>{t('When the membership expires')}</td>
                      </tr>
                      <tr>
                        <th align="left">{'{{MEMBERSHIP_TYPE_NAME}}'}</th>
                        <td>{t('Name of the membership')}</td>
                      </tr>
                    </>
                  )}
                  {values.type === EmailTemplateType.Guest && (
                    <>
                      <tr>
                        <th align="left">{'{{CLASS_NAME}}'}</th>
                        <td>{t('Class name')}</td>
                      </tr>
                      <tr>
                        <th align="left">{'{{CLASS_SUBTITLE}}'}</th>
                        <td>{t('Class subtitle')}</td>
                      </tr>
                      <tr>
                        <th align="left">{'{{CLASS_DATE}}'}</th>
                        <td>{t('Date on which the class is held')}</td>
                      </tr>
                      <tr>
                        <th align="left">{'{{CLASS_TIME}}'}</th>
                        <td>{t('Time of day that the class is held')}</td>
                      </tr>
                      <tr>
                        <th align="left">{'{{CLASS_DATETIME}}'}</th>
                        <td>{t('Date and time on which the class is held')}</td>
                      </tr>
                    </>
                  )}
                  {values.type === EmailTemplateType.Referral && (
                    <>
                      <tr>
                        <th align="left">{'{{CANDIDATE_NAME}}'}</th>
                        <td>{t('Name of the candidate')}</td>
                      </tr>
                      <tr>
                        <th align="left">{'{{REFERRER_NAME}}'}</th>
                        <td>{t('Name of the referrer')}</td>
                      </tr>
                      <tr>
                        <th align="left">{'{{REFERRAL_BONUS}}'}</th>
                        <td>{t('The bonus amount for the referral')}</td>
                      </tr>
                      <tr>
                        <th align="left">{'{{REFERRAL_EXPIRY_DATE}}'}</th>
                        <td>{t('When the referral expires')}</td>
                      </tr>
                    </>
                  )}
                  {values.type === EmailTemplateType.MissingPayment && (
                    <tr>
                      <th align="left">{'{{LINK}}'}</th>
                      <td>{t('Payment link for the invoice')}</td>
                    </tr>
                  )}
                  {values.type === EmailTemplateType.DropIn && (
                    <>
                      <tr>
                        <th align="left">{'{{MEMBERSHIP_TYPE_NAME}}'}</th>
                        <td>{t('Name of the membership')}</td>
                      </tr>
                      <tr>
                        <th align="left">{'{{PLAY_STORE_LINK}}'}</th>
                        <td>{t('Link to app on Play Store')}</td>
                      </tr>
                      <tr>
                        <th align="left">{'{{APP_STORE_LINK}}'}</th>
                        <td>{t('Link to app on App Store')}</td>
                      </tr>
                      <tr>
                        <th align="left">{'{{PASSWORD}}'}</th>
                        <td>{t('New password for the member account')}</td>
                      </tr>
                      <tr>
                        <th align="left">{'{{DOOR_CODE}}'}</th>
                        <td>{t('New pin code for the door')}</td>
                      </tr>
                      <tr>
                        <th align="left">{'{{CLASS_NAME}}'}</th>
                        <td>{t('Class name')}</td>
                      </tr>
                      <tr>
                        <th align="left">{'{{CLASS_DATE}}'}</th>
                        <td>{t('Date on which the class is held')}</td>
                      </tr>
                      <tr>
                        <th align="left">{'{{CLASS_TIME}}'}</th>
                        <td>{t('Time of day that the class is held')}</td>
                      </tr>
                    </>
                  )}
                  {values.type === EmailTemplateType.TicketConfirmation && (
                    <>
                      <tr>
                        <th align="left">{'{{TICKET_NAME}}'}</th>
                        <td>{t('Name of the ticket')}</td>
                      </tr>
                      <tr>
                        <th align="left">{'{{EVENT_NAME}}'}</th>
                        <td>{t('Name of the event')}</td>
                      </tr>
                      <tr>
                        <th align="left">{'{{BEGINS_DATE}}'}</th>
                        <td>{t('Date on which the event is held')}</td>
                      </tr>
                      <tr>
                        <th align="left">{'{{BEGINS_TIME}}'}</th>
                        <td>{t('Time of day that the event is held')}</td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </Typography>
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderDetails = (
    <>
      {mdUp && (
        <Grid md={12}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            {t('Details')}
          </Typography>
        </Grid>
      )}

      <Grid xs={12}>
        <Card>
          {!mdUp && <CardHeader title={t('Details')} />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <Stack spacing={1.5}>
              <Typography variant="subtitle2">{t('Name')}</Typography>
              <RHFTextField name="name" />
            </Stack>

            <Stack spacing={1.5}>
              <Typography variant="subtitle2">{t('Type')}</Typography>
              <Controller
                name="type"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormControl fullWidth error={!!error}>
                    <Select value={values.type} onChange={handleTypeChange} autoWidth={false}>
                      {emailTypes.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
                  </FormControl>
                )}
              />
            </Stack>

            <Stack spacing={1.5}>
              <Typography variant="subtitle2">{t('From')}</Typography>
              <RHFTextField name="from" />
            </Stack>

            <Stack spacing={1.5}>
              <Typography variant="subtitle2">{t('Subject')}</Typography>
              <RHFTextField name="subject" />
            </Stack>

            <Stack spacing={1.5}>
              <Typography variant="subtitle2">{t('Content')}</Typography>
              <EmailEditor ref={emailEditorRef} projectId={223858} onReady={onReady} />
            </Stack>

            <Stack spacing={1.5}>
              <Typography variant="subtitle2">{t('Attachment')}</Typography>
              <RHFUpload
                multiple
                name="attachments"
                maxSize={1024 * 1024 * 5}
                onDrop={handleDrop}
                onDelete={handleRemoveFile}
                accept={{
                  'text/*': [],
                  'application/pdf': [],
                  'application/vnd.oasis.opendocument.text': [],
                  'application/msword': [],
                  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
                }}
              />
            </Stack>
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderActions = (
    <>
      {mdUp && <Grid md={4} />}
      <Grid xs={12} md={8} sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ flexGrow: 1, pl: 3 }} />

        <LoadingButton
          type="submit"
          variant="contained"
          size="large"
          loading={isSubmitting}
          sx={{ ml: 2 }}
        >
          {!currentTemplate ? t('Create') : t('Save Changes')}
        </LoadingButton>
      </Grid>
    </>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        {renderAlert}

        {renderPlaceholders}

        {renderDetails}

        {renderActions}
      </Grid>
    </FormProvider>
  );
}
