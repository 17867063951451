import 'src/utils/mapboxgl';
import { Map, Marker } from 'react-map-gl';
import { useEffect, useState } from 'react';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
// config
import { MAPBOX_API } from 'src/config-global';
// components
import Iconify from 'src/components/iconify';
import { MapControl, MapPopup } from 'src/components/map';
import { GymDto, ParticipationStatus, PlaceDto, SignUpDto } from '../../api';
import { useLocales } from '../../locales';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  zIndex: 0,
  height: 492,
  overflow: 'hidden',
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  '& .mapboxgl-ctrl-logo, .mapboxgl-ctrl-bottom-right': {
    display: 'none',
  },
}));

// ----------------------------------------------------------------------

type Props = {
  participants: SignUpDto[];
  gym: GymDto;
};

export default function CheckInMap({ participants, gym }: Props) {
  const theme = useTheme();

  const { t } = useLocales();

  const isLight = theme.palette.mode === 'light';

  const [popupInfo, setPopupInfo] = useState<PlaceDto | null>(null);

  const filteredParticipants = participants.filter(
    (x) => x.status === ParticipationStatus.OnTime || x.status === ParticipationStatus.TooLate
  );

  const [viewState, setViewState] = useState({
    latitude: gym.address?.latitude ?? 0,
    longitude: gym.address?.longitude ?? 0,
    zoom: 10,
  });

  useEffect(() => {
    if (gym.address) {
      setViewState({
        latitude: gym.address?.latitude ?? 0,
        longitude: gym.address?.longitude ?? 0,
        zoom: 10,
      });
    }
  }, [gym]);

  return (
    <StyledRoot>
      <Map
        initialViewState={viewState}
        mapStyle={`mapbox://styles/mapbox/${isLight ? 'light' : 'dark'}-v10`}
        mapboxAccessToken={MAPBOX_API}
      >
        <MapControl />

        {filteredParticipants.map((x) => (
          <Marker
            longitude={x.checkedInLocation?.longitude ?? 0}
            latitude={x.checkedInLocation?.latitude ?? 0}
            anchor="bottom"
          >
            <Tooltip title={x.name ?? ''} placement="right-end">
              <Iconify icon="mdi:map-marker" color="red" />
            </Tooltip>
          </Marker>
        ))}
        {popupInfo && (
          <MapPopup
            longitude={popupInfo.longitude ?? 0}
            latitude={popupInfo.latitude ?? 0}
            onClose={() => setPopupInfo(null)}
            sx={{
              '& .mapboxgl-popup-content': { bgcolor: 'common.white' },
              '&.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip': { borderTopColor: '#FFF' },
              '&.mapboxgl-popup-anchor-top .mapboxgl-popup-tip': { borderBottomColor: '#FFF' },
            }}
          >
            <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
              {t('Name')}
            </Typography>

            <Typography component="div" variant="caption">
              {popupInfo.name}
            </Typography>
          </MapPopup>
        )}
      </Map>
    </StyledRoot>
  );
}
