import { useCallback, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'src/redux/store';
import {
  getCampaigns,
  getContingents,
  getDropIns,
  getPersonalTrainings,
  getPunchCards,
} from 'src/redux/slices/membership-type';
import { useLocales } from '../../../../locales';
import { useSettingsContext } from '../../../../components/settings';
import { paths } from '../../../../routes/paths';
import CustomBreadcrumbs from '../../../../components/custom-breadcrumbs';
import ActiveMemberView from '../active-member-view';
import MemberLifetimeView from '../member-lifetime-view';
import LengthOfEngagementView from '../length-of-engagement-view';
import MembershipDistributionView from '../membership-distribution';
import MemberAgeDistributionView from '../member-age-distribution';
import MemberGenderDistributionView from '../member-gender-distribution';
import ContingentDistributionView from '../contingent-distribution';
import CampaignDistributionView from '../campaign-distribution';
import PunchCardDistributionView from '../punch-card-distribution';
import PersonalTrainingDistributionView from '../personal-training-distribution';
import DropInDistributionView from '../drop-in-distribution';
import { IInsightFilterValue, IInsightTableFilters } from '../../../../types/insight';
import ActiveMembershipView from '../active-membership-view';
import MemberChurnReportView from '../member-churn-report-view';
import InsightsToolbar from '../insights-toolbar';

const defaultFilters: IInsightTableFilters = {
  fromDate: DateTime.now().startOf('year'),
  toDate: DateTime.now().endOf('year'),
  membershipTypeIds: [],
  minimumLengthOfMembershipInDays: 7,
};

export default function MembershipInsightsView() {
  const { t } = useLocales();

  const settings = useSettingsContext();

  const dispatch = useDispatch();

  const [filters, setFilters] = useState(defaultFilters);

  const handleFilters = useCallback((name: string, value: IInsightFilterValue) => {
    setFilters((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  const campaigns = useSelector((state) => state.membershipType.campaigns);
  const contingents = useSelector((state) => state.membershipType.contingents);
  const punchCards = useSelector((state) => state.membershipType.punchCards);
  const dropIns = useSelector((state) => state.membershipType.dropIns);
  const personalTrainings = useSelector((state) => state.membershipType.personalTrainings);

  useEffect(() => {
    dispatch(
      getCampaigns({
        pageSize: 2 ** 31 - 1,
        pageNumber: 0,
        validOn: new Date(),
        sortBy: ['name'],
      })
    );
    dispatch(
      getContingents({
        pageSize: 2 ** 31 - 1,
        pageNumber: 0,
        validOn: new Date(),
        sortBy: ['name'],
      })
    );
    dispatch(
      getDropIns({
        pageSize: 2 ** 31 - 1,
        pageNumber: 0,
        validOn: new Date(),
        sortBy: ['name'],
      })
    );
    dispatch(
      getPersonalTrainings({
        pageSize: 2 ** 31 - 1,
        pageNumber: 0,
        validOn: new Date(),
        sortBy: ['name'],
      })
    );
    dispatch(
      getPunchCards({
        pageSize: 2 ** 31 - 1,
        pageNumber: 0,
        isActive: true,
        validOn: new Date(),
        sortBy: ['name'],
      })
    );
  }, [dispatch]);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <CustomBreadcrumbs
        heading={t('Insights')}
        links={[
          { name: t('Dashboard'), href: paths.dashboard.root },
          {
            name: t('Insights'),
          },
          { name: t('Memberships') },
        ]}
        sx={{ mb: 3 }}
      />
      <Typography variant="body2" sx={{ mb: 3 }}>
        {t(
          'Here you can get insights about memberships. You can see stats about active members, active memberships, member lifetime, length of engagement, member churn report, member age distribution, and more.'
        )}
      </Typography>
      <Grid container spacing={3} sx={{ mb: 3 }}>
        <Grid xs={12}>
          <Card>
            <InsightsToolbar
              filters={filters}
              onFilters={handleFilters}
              dropIns={dropIns}
              punchCards={punchCards}
              contingents={contingents}
              campaigns={campaigns}
              personalTrainings={personalTrainings}
            />
          </Card>
        </Grid>
        <Grid xs={12} md={12} lg={6}>
          <ActiveMemberView
            fromDate={filters.fromDate}
            toDate={filters.toDate}
            membershipTypeIds={filters.membershipTypeIds}
            minimumLengthOfMembershipInDays={filters.minimumLengthOfMembershipInDays}
          />
        </Grid>
        <Grid xs={12} md={12} lg={6}>
          <ActiveMembershipView
            fromDate={filters.fromDate}
            toDate={filters.toDate}
            membershipTypeIds={filters.membershipTypeIds}
            minimumLengthOfMembershipInDays={filters.minimumLengthOfMembershipInDays}
          />
        </Grid>
        <Grid xs={12} md={12} lg={6} xl={4}>
          <MemberLifetimeView
            fromDate={filters.fromDate}
            toDate={filters.toDate}
            membershipTypeIds={filters.membershipTypeIds}
            minimumLengthOfMembershipInDays={filters.minimumLengthOfMembershipInDays}
          />
        </Grid>
        <Grid xs={12} md={12} lg={6} xl={4}>
          <LengthOfEngagementView
            fromDate={filters.fromDate}
            toDate={filters.toDate}
            membershipTypeIds={filters.membershipTypeIds}
            minimumLengthOfMembershipInDays={filters.minimumLengthOfMembershipInDays}
          />
        </Grid>
        <Grid xs={12} md={12} lg={6} xl={4}>
          <MemberChurnReportView
            fromDate={filters.fromDate}
            toDate={filters.toDate}
            membershipTypeIds={filters.membershipTypeIds}
            minimumLengthOfMembershipInDays={filters.minimumLengthOfMembershipInDays}
          />
        </Grid>
        <Grid xs={12} md={12} lg={6} xl={4}>
          <MemberAgeDistributionView
            fromDate={filters.fromDate}
            toDate={filters.toDate}
            membershipTypeIds={filters.membershipTypeIds}
            minimumLengthOfMembershipInDays={filters.minimumLengthOfMembershipInDays}
          />
        </Grid>
        <Grid xs={12} md={12} lg={6} xl={4}>
          <MemberGenderDistributionView
            fromDate={filters.fromDate}
            toDate={filters.toDate}
            membershipTypeIds={filters.membershipTypeIds}
            minimumLengthOfMembershipInDays={filters.minimumLengthOfMembershipInDays}
          />
        </Grid>
        <Grid xs={12} md={12} lg={6} xl={4}>
          <MembershipDistributionView
            fromDate={filters.fromDate}
            toDate={filters.toDate}
            membershipTypeIds={filters.membershipTypeIds}
            minimumLengthOfMembershipInDays={filters.minimumLengthOfMembershipInDays}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid xs={12} md={12}>
          <ContingentDistributionView
            fromDate={filters.fromDate}
            toDate={filters.toDate}
            membershipTypeIds={filters.membershipTypeIds}
            minimumLengthOfMembershipInDays={filters.minimumLengthOfMembershipInDays}
          />
        </Grid>
        <Grid xs={12} md={12}>
          <CampaignDistributionView
            fromDate={filters.fromDate}
            toDate={filters.toDate}
            membershipTypeIds={filters.membershipTypeIds}
            minimumLengthOfMembershipInDays={filters.minimumLengthOfMembershipInDays}
          />
        </Grid>
        <Grid xs={12} md={12}>
          <PunchCardDistributionView
            fromDate={filters.fromDate}
            toDate={filters.toDate}
            membershipTypeIds={filters.membershipTypeIds}
            minimumLengthOfMembershipInDays={filters.minimumLengthOfMembershipInDays}
          />
        </Grid>
        <Grid xs={12} md={12}>
          <PersonalTrainingDistributionView
            fromDate={filters.fromDate}
            toDate={filters.toDate}
            membershipTypeIds={filters.membershipTypeIds}
            minimumLengthOfMembershipInDays={filters.minimumLengthOfMembershipInDays}
          />
        </Grid>
        <Grid xs={12} md={12}>
          <DropInDistributionView
            fromDate={filters.fromDate}
            toDate={filters.toDate}
            membershipTypeIds={filters.membershipTypeIds}
            minimumLengthOfMembershipInDays={filters.minimumLengthOfMembershipInDays}
          />
        </Grid>
      </Grid>
    </Container>
  );
}
