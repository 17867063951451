import * as Yup from 'yup';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { setError } from 'src/redux/slices/error';

// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import InputAdornment from '@mui/material/InputAdornment';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// routes
import { useRouter } from 'src/routes/hook';
// components
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, {
  RHFAutocomplete,
  RHFEditor,
  RHFSelect,
  RHFTextField,
} from 'src/components/hook-form';
// types
import { useDispatch, useSelector } from '../../redux/store';
import {
  AccountDto,
  ExerciseDto,
  PersonalProgramsService,
  ProgramDto,
  ProgramTemplateDto,
  WorkoutDto,
  WorkoutTypeDto,
} from '../../api';
import { useLocales } from '../../locales';
import Iconify from '../../components/iconify';
import { possibleReps, units } from '../../types/exercise';

// ----------------------------------------------------------------------

type Props = {
  currentTemplate?: ProgramTemplateDto;
  programTemplates: ProgramTemplateDto[];
  workoutTypes: WorkoutTypeDto[];
  member?: AccountDto;
};

export default function ProgramTemplateNewEditForm({
  currentTemplate,
  workoutTypes,
  member,
  programTemplates,
}: Props) {
  const router = useRouter();

  const { t } = useLocales();

  const mdUp = useResponsive('up', 'md');

  const { enqueueSnackbar } = useSnackbar();

  const exercises = useSelector((state) => state.exercise.exercises);

  const sortedExercises = [...exercises];
  sortedExercises?.sort((a, b) => a.name!.localeCompare(b.name!));

  const newSchema = Yup.object().shape({
    name: Yup.string().required(t('Name is required')),
    path: Yup.string(),
    programs: Yup.array().min(1).required(t('Programs are required')),
  });

  const defaultValues = useMemo(
    () => ({
      name: currentTemplate?.name || '',
      path:
        currentTemplate?.path ||
        (member?.id !== undefined ? `${member?.fullName} (${member?.id})` : ''),
      programs: currentTemplate?.programs?.map((e) => ({
        ...e,
        workouts: e.workouts?.map((w) => ({
          ...w,
          workoutTypeId: w.workoutTypeId ?? -1,
        })),
      })) || [
        {
          workouts: [
            {
              workoutTypeId: -1,
              text: '',
              timecapInMinutes: '',
              timecapInSeconds: '',
            },
          ],
        },
      ],
    }),
    [currentTemplate, member]
  );

  const methods = useForm({
    resolver: yupResolver(newSchema),
    defaultValues,
  });

  const {
    watch,
    reset,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  useEffect(() => {
    if (currentTemplate) {
      reset(defaultValues);
    }

    if (member) {
      reset(defaultValues);
    }
  }, [currentTemplate, member, defaultValues, reset]);

  const addNewProgram = useCallback(() => {
    const programs = [
      ...values.programs,
      {
        workouts: [
          {
            workoutTypeId: -1,
            text: '',
            timecapInMinutes: '',
            timecapInSeconds: '',
          },
        ],
      },
    ];
    setValue('programs', programs, { shouldValidate: true });
  }, [setValue, values.programs]);

  const addWorkout = (program: ProgramDto) => {
    program.workouts?.push({ workoutTypeId: -1, text: '' });
    const programs = [...values.programs];
    setValue('programs', programs, { shouldValidate: true });
  };

  const removeWorkout = (program: ProgramDto, w: WorkoutDto) => {
    program.workouts = [...program.workouts!.filter((z) => z.id !== w.id)];
    const programs = [...values.programs];
    setValue('programs', programs, { shouldValidate: true });
  };

  const addExercise = (w: WorkoutDto) => {
    if (w.exercises) {
      w.exercises = [
        ...w.exercises!,
        {
          count: '',
          numberOfSets: '',
          unit: '',
          exerciseTypeId: '',
          womensWeight: '',
          mensWeight: '',
          percentage: '',
          relationTo: '',
        } as any,
      ];
    } else {
      w.exercises = [
        {
          count: '',
          numberOfSets: '',
          unit: '',
          exerciseTypeId: '',
          womensWeight: '',
          mensWeight: '',
          percentage: '',
          relationTo: '',
        } as any,
      ];
    }
    setValue('programs', values.programs, { shouldValidate: true });
  };

  const removeExercise = (w: WorkoutDto, e: ExerciseDto) => {
    w.exercises = [...w.exercises!.filter((z) => z !== e)];
    setValue('programs', values.programs, { shouldValidate: true });
  };
  const dispatch = useDispatch();

  const onSubmit = handleSubmit(async (data) => {
    try {
      // Check if is edit mode
      if (currentTemplate) {
        await PersonalProgramsService.edit({
          programId: currentTemplate!.id!,
          body: {
            id: currentTemplate!.id!,
            memberId: member?.id!,
            isForPersonalTraining: true,
            ...data,
          } as any,
        });
      } else {
        await PersonalProgramsService.create({
          body: {
            isForPersonalTraining: true,
            memberId: member?.id!,
            ...data,
          } as any,
        });
      }

      reset();
      enqueueSnackbar(currentTemplate ? t('Update success!') : t('Create success!'));
      router.back();
    } catch (error) {
      dispatch(setError(error));
    }
  });

  const [programTemplate, setProgramTemplate] = useState<ProgramTemplateDto | null>(null);

  const handleBaseTemplateChange = (p: ProgramTemplateDto | null) => {
    if (p === null) {
      return;
    }
    values.programs = p.programs ?? [];
    setValue('programs', values.programs, { shouldValidate: true });
  };

  const renderDetails = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            {t('Details')}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {t('Name, path...')}
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title={t('Details')} />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <Stack spacing={1.5}>
              <Typography variant="subtitle2"> {t('Base template')} </Typography>
              <Autocomplete
                value={programTemplate}
                onChange={(event: any, newValue: ProgramTemplateDto | null) => {
                  setProgramTemplate(newValue);
                  handleBaseTemplateChange(newValue);
                }}
                options={programTemplates}
                getOptionLabel={(option) => option.name!}
                renderInput={(params) => <TextField {...params} />}
              />
            </Stack>
            <Stack spacing={1.5}>
              <Typography variant="subtitle2"> {t('Name')} </Typography>
              <RHFTextField name="name" placeholder={t('Ex: Strength')} />
            </Stack>

            <Stack spacing={1.5}>
              <Typography variant="subtitle2"> {t('Path')} </Typography>
              <RHFTextField name="path" placeholder={t('Ex: Client Name')} />
            </Stack>
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderPrograms = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            {t('Programs')}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {t(
              'Add programs to this template. For each program you can add workouts. Usually you would create a workout for warm-up, skills, WOD, and cool down.'
            )}
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Stack spacing={3}>
          <Card>
            {values.programs.map((p: ProgramDto, i) => (
              <Stack key={`program-${p.id ?? i}`} spacing={3} sx={{ p: 3 }}>
                <Stack
                  direction="row"
                  sx={{
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography variant="h5">
                    {t('Program #{{number}}', { number: i + 1 })}
                  </Typography>

                  <Button
                    onClick={(e) => addWorkout(p)}
                    variant="contained"
                    startIcon={<Iconify icon="eva:plus-fill" />}
                  >
                    {t('Add workout')}
                  </Button>
                </Stack>
                <Stack spacing={3}>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {t(
                      'Here you can add workouts to this specific program. Usually you would create a workout for warm-up, skills, WOD, and cool down.'
                    )}
                  </Typography>
                </Stack>

                {p.workouts?.map((w: WorkoutDto, j) => (
                  <Stack spacing={3} key={`workout-${p.id ?? i}-${w.id ?? j}`}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={{
                        pb: 2,
                      }}
                    >
                      <Box sx={{ flexGrow: 1 }}>
                        <Typography variant="subtitle1">
                          {t('Workout #{{number}}', { number: j + 1 })}
                        </Typography>
                      </Box>

                      <Box sx={{ flexShrink: 0 }}>
                        <IconButton color="error" onClick={() => removeWorkout(p, w)}>
                          <Iconify icon="eva:trash-2-fill" />
                        </IconButton>
                      </Box>
                    </Stack>
                    <Stack spacing={1.5}>
                      <Typography variant="subtitle2">{t('Name')}</Typography>
                      <RHFTextField name={`programs[${i}].workouts[${j}].title`} value={w.title} />
                    </Stack>

                    <Stack spacing={1.5}>
                      <Typography variant="subtitle2">{t('Workout type')}</Typography>
                      <RHFSelect
                        name={`programs[${i}].workouts[${j}].workoutTypeId`}
                        defaultValue={w.workoutTypeId}
                      >
                        <MenuItem key="work-t-1" value={-1}>
                          {t('None')}
                        </MenuItem>
                        {workoutTypes.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.abbreviation}
                          </MenuItem>
                        ))}
                      </RHFSelect>
                    </Stack>

                    <Stack spacing={1.5}>
                      <Typography variant="subtitle2">{t('Time cap')}</Typography>
                      <Stack direction="row" spacing={1.5}>
                        <RHFTextField
                          name={`workouts[${j}].timecapInMinutes`}
                          value={w.timecapInMinutes}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">Min</InputAdornment>,
                          }}
                        />
                        <RHFTextField
                          name={`workouts[${j}].timecapInSeconds`}
                          value={w.timecapInSeconds}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">Sec</InputAdornment>,
                          }}
                        />
                      </Stack>
                    </Stack>

                    <Stack spacing={1.5}>
                      <Typography variant="subtitle2">{t('Description')}</Typography>
                      <RHFEditor
                        simple
                        value={w.text}
                        name={`programs[${i}].workouts[${j}].text`}
                        key={`workout-desc-${p.id ?? i}-${w.id ?? j}`}
                        id={`workout-desc-${p.id ?? i}-${w.id ?? j}`}
                      />
                    </Stack>
                    <Divider />
                    {w.exercises?.map((e, k) => (
                      <Stack spacing={3} key={`workout-${j}-exer-${k}`}>
                        <Stack
                          direction="row"
                          alignItems="center"
                          sx={{
                            pb: 2,
                          }}
                        >
                          <Box sx={{ flexGrow: 1 }}>
                            <Typography variant="subtitle1">
                              {t('Exercise #{{number}}', { number: k + 1 })}
                            </Typography>
                          </Box>

                          <Box sx={{ flexShrink: 0 }}>
                            <IconButton color="error" onClick={() => removeExercise(w, e)}>
                              <Iconify icon="eva:trash-2-fill" />
                            </IconButton>
                          </Box>
                        </Stack>
                        <Box
                          gap={3}
                          display="grid"
                          gridTemplateColumns={{
                            xs: 'repeat(2, 1fr)',
                            md: 'repeat(4, 1fr)',
                          }}
                        >
                          <RHFAutocomplete
                            name={`programs[${i}].workouts[${j}].exercises[${k}].exerciseTypeName`}
                            label={t('Exercise')}
                            options={sortedExercises.map((x) => x.name)}
                          />

                          <RHFTextField
                            label={t('Sets')}
                            name={`programs[${i}].workouts[${j}].exercises[${k}].numberOfSets`}
                            value={e.numberOfSets}
                          />
                          <RHFTextField
                            label={t('Count')}
                            name={`programs[${i}].workouts[${j}].exercises[${k}].count`}
                            value={e.count}
                          />
                          <RHFSelect
                            label={t('Unit')}
                            name={`programs[${i}].workouts[${j}].exercises[${k}].unit`}
                            value={e.unit}
                          >
                            {units.map((x) => (
                              <MenuItem key={`workout-${j}-exer-${k}-${x}`} value={x}>
                                {t(x)}
                              </MenuItem>
                            ))}
                          </RHFSelect>

                          {e.unit === 'REPS' && (
                            <>
                              {!(e.percentage || e.relationTo) && (
                                <RHFTextField
                                  label={t('Womens weight')}
                                  name={`programs[${i}].workouts[${j}].exercises[${k}].womensWeight`}
                                  value={e.womensWeight}
                                />
                              )}
                              {!(e.percentage || e.relationTo) && (
                                <RHFTextField
                                  label={t('Mens weight')}
                                  name={`programs[${i}].workouts[${j}].exercises[${k}].mensWeight`}
                                  value={e.mensWeight}
                                />
                              )}
                              {!(e.mensWeight || e.womensWeight) && (
                                <RHFTextField
                                  label={t('Percentage')}
                                  name={`programs[${i}].workouts[${j}].exercises[${k}].percentage`}
                                  value={e.percentage}
                                  InputLabelProps={{ shrink: true }}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">%</InputAdornment>
                                    ),
                                  }}
                                />
                              )}
                              {!(e.mensWeight || e.womensWeight) && (
                                <RHFSelect
                                  label={t('Relation to')}
                                  name={`programs[${i}].workouts[${j}].exercises[${k}].relationTo`}
                                  value={e.relationTo}
                                >
                                  {possibleReps.map((x) => (
                                    <MenuItem key={`workout-${j}-exer-${k}-${x}`} value={x}>
                                      {t(x)}
                                    </MenuItem>
                                  ))}
                                </RHFSelect>
                              )}
                            </>
                          )}
                        </Box>
                      </Stack>
                    ))}
                    <Button
                      onClick={() => addExercise(w)}
                      variant="outlined"
                      startIcon={<Iconify icon="eva:plus-fill" />}
                    >
                      {t('Add exercise')}
                    </Button>
                  </Stack>
                ))}
              </Stack>
            ))}
            <Stack sx={{ pl: 3, pr: 3, pb: 3 }}>
              <Button
                onClick={addNewProgram}
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill" />}
              >
                {t('New program')}
              </Button>
            </Stack>
          </Card>
        </Stack>
      </Grid>
    </>
  );

  const renderActions = (
    <>
      {mdUp && <Grid md={4} />}
      <Grid xs={12} md={8} sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ flexGrow: 1, pl: 3 }} />

        <LoadingButton
          type="submit"
          variant="contained"
          size="large"
          loading={isSubmitting}
          sx={{ ml: 2 }}
        >
          {!currentTemplate ? `${t('Create')}` : `${t('Save Changes')}`}
        </LoadingButton>
      </Grid>
    </>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        {renderDetails}

        {renderPrograms}

        {renderActions}
      </Grid>
    </FormProvider>
  );
}
