import * as Yup from 'yup';
import { useCallback, useEffect, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// routes
import { useRouter } from 'src/routes/hook';
// components
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, {
  RHFEditor,
  RHFMultiSelect,
  RHFSelect,
  RHFTextField,
  RHFUpload,
} from 'src/components/hook-form';
// types
import { setError } from 'src/redux/slices/error';
import {
  ChainSettingsDto,
  EmailTemplateType,
  EventDetailsDto,
  EventService,
  OptionTypeTypes,
} from '../../api';
import { useLocales } from '../../locales';
import Iconify from '../../components/iconify';
import { fCurrencySymbol } from '../../utils/format-number';
import { useDispatch, useSelector } from '../../redux/store';
import { getGyms } from '../../redux/slices/gym';
import { getEmailTemplates } from '../../redux/slices/email-template';

// ----------------------------------------------------------------------

type Props = {
  currentEvent?: EventDetailsDto;
  settings: ChainSettingsDto;
};

export default function EventNewEditForm({ currentEvent, settings }: Props) {
  const router = useRouter();

  const { t } = useLocales();

  const dispatch = useDispatch();

  const mdUp = useResponsive('up', 'md');

  const gyms = useSelector((state) => state.gym.gyms);
  const globalGym = useSelector((state) => state.gym.globalGym);
  const emailTemplates = useSelector((state) => state.emailTemplate.templates);

  const { enqueueSnackbar } = useSnackbar();

  const newSchema = Yup.object().shape({
    title: Yup.string().required(t('Name is required')),
    description: Yup.string().required(t('Description is required')),
    shortDescription: Yup.string().required(t('Short description is required')),
    images: Yup.array().min(1, t('At least one image is required')),
    ticketTypes: Yup.array().min(1, t('At least one ticket type is required')),
    optionTypes: Yup.array(),
    accountNumber: Yup.mixed(),
    availableInGyms: Yup.array().min(currentEvent ? 0 : 1, t('Please select at least one gym')),
    begins: Yup.mixed<any>()
      .nullable()
      .required(t('Begins is required'))
      .test('is-set', t('Begins is required'), (value, { parent }) => value),
    ends: Yup.mixed<any>()
      .required(t('End date is required'))
      .test(
        'date-min',
        t('End date must be later than start date'),
        (value, { parent }) => value > parent.begins
      ),
    openForSignUpAt: Yup.mixed<any>()
      .nullable()
      .required(t('Open for sign up at is required'))
      .test('is-set', t('Open for sign up at is required'), (value, { parent }) => value),
    closedForSignUpAt: Yup.mixed<any>()
      .required(t('Closed for sign up at is required'))
      .test(
        'date-min',
        t('Closed for sign up at must be later than start date'),
        (value, { parent }) => value > parent.openForSignUpAt
      ),
    confirmationEmailId: Yup.number()
      .default(undefined)
      .min(0, t('Confirmation email is required'))
      .test('is-set', t('Duration in hours is required'), (value, { parent }) =>
        Number.isInteger(value)
      ),
  });

  const defaultValues = useMemo(
    () => ({
      title: currentEvent?.title || '',
      description: currentEvent?.description || '',
      shortDescription: currentEvent?.shortDescription || '',
      images: currentEvent?.images?.map((e) => e.url) ?? [],
      begins: currentEvent?.begins || null,
      accountNumber: currentEvent?.accountNumber || '',
      ends: currentEvent?.ends || null,
      gymId: currentEvent?.gymId || null,
      availableInGyms: [`${globalGym.id}`],
      openForSignUpAt: currentEvent?.openForSignUpAt || null,
      closedForSignUpAt: currentEvent?.closedForSignUpAt || null,
      confirmationEmailId: currentEvent?.confirmationEmailId || -1,
      ticketTypes: currentEvent?.types || [{}],
      optionTypes: currentEvent?.options?.map((x) => ({ ...x, values: x.values!.join(',') })) || [],
    }),
    [currentEvent, globalGym]
  );

  const methods = useForm({
    resolver: yupResolver(newSchema),
    defaultValues,
  });

  const {
    watch,
    reset,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    dispatch(
      getEmailTemplates({
        pageNumber: 0,
        pageSize: 2 ** 31 - 1,
      })
    );
    dispatch(
      getGyms({
        pageNumber: 0,
        pageSize: 2 ** 31 - 1,
      })
    );
  }, [dispatch]);

  const values = watch();

  const typeArray = useFieldArray({
    control,
    name: 'ticketTypes',
  });

  const optionArray = useFieldArray({
    control,
    name: 'optionTypes',
  });

  useEffect(() => {
    if (currentEvent) {
      reset(defaultValues);
    }
    if (settings) {
      reset(defaultValues);
    }
    if (globalGym) {
      reset(defaultValues);
    }
  }, [currentEvent, defaultValues, reset, settings, globalGym]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      // Split options so that it works for the API
      data.optionTypes?.forEach((x) => {
        x.values = x.values?.split(',');
      });

      // Check if is edit mode
      if (currentEvent) {
        await EventService.edit({
          id: currentEvent!.id!,
          body: { id: currentEvent!.id!, ...data } as any,
        });
        await EventService.setBackgroundImage({ id: currentEvent!.id!, files: data.images ?? [] });
      } else {
        const newEvent = await EventService.create({ body: { ...data } as any });
        await EventService.setBackgroundImage({ id: newEvent.id!, files: data.images ?? [] });
      }

      reset();
      enqueueSnackbar(currentEvent ? t('Update success!') : t('Create success!'));
      router.back();
    } catch (error) {
      dispatch(setError(error));
    }
  });

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const files = values.images || [];

      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      setValue('images', [...files, ...newFiles], { shouldValidate: true });
    },
    [setValue, values.images]
  );

  const handleRemoveFile = useCallback(
    (inputFile: File | string) => {
      const deleted = currentEvent?.images?.filter((file) => file.url === inputFile) ?? [];
      for (let i = 0; i < deleted.length; i += 1) {
        const image = deleted[i];
        if (image) {
          EventService.removeImage({ eventId: currentEvent!.id!, imageId: image?.id! });
        }
      }

      const filtered = values.images?.filter((file) => file !== inputFile) ?? [];
      setValue('images', filtered);
    },
    [setValue, currentEvent, values.images]
  );

  const handleRemoveAllFiles = useCallback(() => {
    const deleted = currentEvent?.images ?? [];
    for (let i = 0; i < deleted.length; i += 1) {
      const image = deleted[i];
      if (image) {
        EventService.removeImage({ eventId: currentEvent!.id!, imageId: image?.id! });
      }
    }
    setValue('images', []);
  }, [setValue, currentEvent]);

  const handleRemove = (index: number) => {
    typeArray.remove(index);
  };

  const handleAdd = () => {
    typeArray.append({
      name: '',
      numberOfAvailableTickets: '',
      priceAmount: '',
    });
  };

  const handleOptionRemove = (index: number) => {
    optionArray.remove(index);
  };

  const handleOptionAdd = () => {
    optionArray.append({
      name: '',
      numberOfAvailableTickets: '',
      priceAmount: '',
    });
  };

  const renderDetails = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            {t('Details')}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {t('Title, short description, image...')}
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title={t('Details')} />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <Stack spacing={1.5}>
              <Typography variant="subtitle2">{t('Title')}</Typography>
              <RHFTextField name="title" placeholder={t('Ex: Adventure Seekers Expedition...')} />
            </Stack>

            {!currentEvent && (
              <Stack spacing={1.5}>
                <Typography variant="subtitle2">{t('Available in gyms')}</Typography>
                <RHFMultiSelect
                  name="availableInGyms"
                  options={gyms.map((e) => ({ value: `${e.id}`, label: e.name! }))}
                  checkbox
                />
              </Stack>
            )}

            <Stack spacing={1.5}>
              <Typography variant="subtitle2">{t('Confirmation email')}</Typography>
              <RHFSelect native name="confirmationEmailId">
                <option value="-1" disabled>
                  {t('Please select a template')}
                </option>
                {emailTemplates
                  .filter((e) => e.type === EmailTemplateType.TicketConfirmation)
                  .map((c) => (
                    <option key={c.name} value={c.id}>
                      {c.name}
                    </option>
                  ))}
              </RHFSelect>
            </Stack>

            <Stack spacing={1.5}>
              <Typography variant="subtitle2">{t('Short description')}</Typography>
              <RHFTextField name="shortDescription" />
            </Stack>

            <Stack spacing={1.5}>
              <Typography variant="subtitle2">{t('Description')}</Typography>
              <RHFEditor simple name="description" />
            </Stack>

            <Stack spacing={1.5}>
              <Typography variant="subtitle2">{t('Images')}</Typography>
              <RHFUpload
                multiple
                thumbnail
                name="images"
                maxSize={3145728}
                onDrop={handleDrop}
                onRemove={handleRemoveFile}
                onRemoveAll={handleRemoveAllFiles}
              />
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {t('Please use af format of 4:3. Recommended size 2048 × 1536 pixels.')}
              </Typography>
            </Stack>

            <Stack spacing={1.5}>
              <Typography variant="subtitle2">{t('Account number for bookkeeping')}</Typography>
              <RHFTextField name="accountNumber" />
            </Stack>
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderTime = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            {t('Date and time')}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {t('Sign up, held on...')}
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title={t('Date and time')} />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <Stack spacing={1.5}>
              <Typography variant="subtitle2">{t('Held on')}</Typography>
              <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                <Controller
                  name="begins"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <DateTimePicker
                      {...field}
                      value={field.value}
                      ampm={false}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!error,
                          helperText: error?.message,
                        },
                      }}
                    />
                  )}
                />
                <Controller
                  name="ends"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <DateTimePicker
                      {...field}
                      value={field.value}
                      ampm={false}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!error,
                          helperText: error?.message,
                        },
                      }}
                    />
                  )}
                />
              </Stack>
            </Stack>

            <Stack spacing={1.5}>
              <Typography variant="subtitle2">{t('Sign up period')}</Typography>
              <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                <Controller
                  name="openForSignUpAt"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <DateTimePicker
                      {...field}
                      value={field.value}
                      ampm={false}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!error,
                          helperText: error?.message,
                        },
                      }}
                    />
                  )}
                />
                <Controller
                  name="closedForSignUpAt"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <DateTimePicker
                      {...field}
                      value={field.value}
                      ampm={false}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!error,
                          helperText: error?.message,
                        },
                      }}
                    />
                  )}
                />
              </Stack>
            </Stack>
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderTypes = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            {t('Ticket types')}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {t('Price, number of tickets ...')}
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title={t('Ticket types')} />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <Stack divider={<Divider flexItem sx={{ borderStyle: 'dashed' }} />} spacing={3}>
              {values.ticketTypes?.map((item, index) => (
                <Stack key={item.id} alignItems="flex-end" spacing={1.5}>
                  <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ width: 1 }}>
                    <RHFTextField
                      size="small"
                      name={`ticketTypes[${index}].name`}
                      label={t('Name')}
                      InputLabelProps={{ shrink: true }}
                    />

                    <RHFTextField
                      size="small"
                      name={`ticketTypes[${index}].numberOfAvailableTickets`}
                      label={t('Number of available tickets')}
                      InputLabelProps={{ shrink: true }}
                    />

                    <RHFTextField
                      size="small"
                      name={`ticketTypes[${index}].priceAmount`}
                      label={t('Price')}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Box component="span" sx={{ color: 'text.disabled' }}>
                              {fCurrencySymbol(settings.defaultCurrency)}
                            </Box>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Stack>

                  <Button
                    size="small"
                    color="error"
                    startIcon={<Iconify icon="solar:trash-bin-trash-bold" />}
                    onClick={() => handleRemove(index)}
                  >
                    {t('Remove')}
                  </Button>
                </Stack>
              ))}
            </Stack>

            <Divider sx={{ my: 0, borderStyle: 'dashed' }} />

            <Stack
              spacing={3}
              direction={{ xs: 'column', md: 'row' }}
              alignItems={{ xs: 'flex-end', md: 'center' }}
            >
              <Button
                size="small"
                color="primary"
                startIcon={<Iconify icon="mingcute:add-line" />}
                onClick={handleAdd}
                sx={{ flexShrink: 0 }}
              >
                {t('Add type')}
              </Button>
            </Stack>
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderDemands = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            {t('Sign up demands')}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {t('T-shirt size, food...')}
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title={t('Sign up demands')} />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <Stack divider={<Divider flexItem sx={{ borderStyle: 'dashed' }} />} spacing={3}>
              {values.optionTypes?.map((item, index) => (
                <Stack key={item.id} alignItems="flex-end" spacing={1.5}>
                  <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ width: 1 }}>
                    <RHFTextField
                      size="small"
                      name={`optionTypes[${index}].name`}
                      label={t('Name')}
                      InputLabelProps={{ shrink: true }}
                    />

                    <RHFSelect
                      fullWidth
                      name={`optionTypes[${index}].type`}
                      size="small"
                      label={t('Type')}
                      SelectProps={{ native: true }}
                    >
                      <option key={OptionTypeTypes.Checkbox} value={OptionTypeTypes.Checkbox}>
                        {t(OptionTypeTypes.Checkbox)}
                      </option>
                      <option key={OptionTypeTypes.Radio} value={OptionTypeTypes.Radio}>
                        {t(OptionTypeTypes.Radio)}
                      </option>
                      <option key={OptionTypeTypes.Select} value={OptionTypeTypes.Select}>
                        {t(OptionTypeTypes.Select)}
                      </option>
                      <option key={OptionTypeTypes.Text} value={OptionTypeTypes.Text}>
                        {t(OptionTypeTypes.Text)}
                      </option>
                    </RHFSelect>

                    <RHFTextField
                      size="small"
                      name={`optionTypes[${index}].values`}
                      label={t('Values')}
                      InputLabelProps={{ shrink: true }}
                      helperText={
                        item.type === OptionTypeTypes.Select || item.type === OptionTypeTypes.Radio
                          ? t('Provide a comma (,) separated list')
                          : ''
                      }
                    />
                  </Stack>

                  <Button
                    size="small"
                    color="error"
                    startIcon={<Iconify icon="solar:trash-bin-trash-bold" />}
                    onClick={() => handleOptionRemove(index)}
                  >
                    {t('Remove')}
                  </Button>
                </Stack>
              ))}
            </Stack>

            <Divider sx={{ my: 0, borderStyle: 'dashed' }} />

            <Stack
              spacing={3}
              direction={{ xs: 'column', md: 'row' }}
              alignItems={{ xs: 'flex-end', md: 'center' }}
            >
              <Button
                size="small"
                color="primary"
                startIcon={<Iconify icon="mingcute:add-line" />}
                onClick={handleOptionAdd}
                sx={{ flexShrink: 0 }}
              >
                {t('Add option')}
              </Button>
            </Stack>
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderActions = (
    <>
      {mdUp && <Grid md={4} />}
      <Grid xs={12} md={8} sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ flexGrow: 1, pl: 3 }} />

        <LoadingButton
          type="submit"
          variant="contained"
          size="large"
          loading={isSubmitting}
          sx={{ ml: 2 }}
        >
          {!currentEvent ? t('Create') : t('Save Changes')}
        </LoadingButton>
      </Grid>
    </>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        {renderDetails}

        {renderTime}

        {renderTypes}

        {renderDemands}

        {renderActions}
      </Grid>
    </FormProvider>
  );
}
