import 'src/utils/highlight';
import ReactQuill from 'react-quill';
// @mui
import { alpha } from '@mui/material/styles';
//
import { EditorProps } from './types';
import { StyledEditor, StyledEditorToolbar } from './styles';
import { formats } from './toolbar';
import { useLocales } from '../../locales';
import ImageUploader from '../../utils/quill-image-uploader';
import { ImageService } from '../../api';

// ----------------------------------------------------------------------

ReactQuill.Quill.debug('warn');
ReactQuill.Quill.register('modules/imageUploader', ImageUploader);

// <-- Define modules outside function component -->
const modules = {
  toolbar: {
    container: [
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ direction: 'rtl' }, { align: [] }],
      ['link', 'image', 'video'],
      ['clean'],
    ],
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true,
  },
  syntax: true,
  clipboard: {
    matchVisual: false,
  },
  imageUploader: {
    upload: (file: Blob) =>
      new Promise((resolve, reject) => {
        if (file) {
          ImageService.create({
            files: [file],
          })
            .then((resp) => resolve(resp.url))
            .catch(reject);
        } else {
          reject(Error('No file selected'));
        }
      }),
  },
};

export default function Editor({
  id = 'minimal-quill',
  error,
  simple = false,
  helperText,
  sx,
  ...other
}: EditorProps) {
  const { t } = useLocales();

  return (
    <>
      <StyledEditor
        sx={{
          ...(error && {
            border: (theme) => `solid 1px ${theme.palette.error.main}`,
            '& .ql-editor': {
              bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
            },
          }),
          ...sx,
        }}
      >
        <StyledEditorToolbar id={id}>
          <ReactQuill
            modules={modules}
            formats={formats}
            placeholder={t('Write something awesome...')}
            {...other}
          />
        </StyledEditorToolbar>
      </StyledEditor>

      {helperText && helperText}
    </>
  );
}
