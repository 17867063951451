import { init as initApm } from '@elastic/apm-rum';

type Props = {
  children: React.ReactNode;
};

export default function Apm({ children }: Props) {
  if (process.env.REACT_APP_APM_ENVIRONMENT === 'Production') {
    initApm({
      serviceName: 'BookingBoard_Web_Dashboard',

      serverUrl:
        'https://ee597b4ffa3b4c6f92db406b6f9b5479.apm.westeurope.azure.elastic-cloud.com:443',

      serviceVersion: '',

      environment: 'Production',
    });
  }

  return <>{children}</>;
}
